import React from 'react';
import { useSelector } from 'react-redux';
import { Page, Document, StyleSheet, Text } from '@react-pdf/renderer';

import PDFTableIngresosFyC from './PDFTableIngresosFyC'

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Helvetica',
        fontFamily: 'Times-Roman',
        paddingTop: 20,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        flexDirection: 'column',
    },
    header: {
        fontSize: 12,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
    },
});



const PDFContainerIngresosFyC = ({pdfData, pdfDataConfig}) => {
    console.log("ESTA ES LA CONFIGURACION ",pdfDataConfig)
    return <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.header} fixed>LISTADO MOVIMIENTOS CTA. CTE. DESDE:</Text>
                <PDFTableIngresosFyC data={pdfData} />
            </Page>
        </Document>
};

export default PDFContainerIngresosFyC