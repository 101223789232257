import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//PDF
import PDFViewerArqueoCaja from '../../componentes/Pdf/pdfArqueoDeCaja/PDFViewerArqueoCaja';

//Api
import { TransferirCajaCtaCteApi } from '../../api/TransferirCajaCtaCteApi'

//formularios
import FormTransferirCajaCtaCte from './FormTransferirCajaCtaCte'
import FormImprimirArqueoDeCaja from './FormImprimirArqueoDeCaja'

// components
import Table from '../../componentes/CustomTable'
import ModalDialog from '../../componentes/Modals/ModalDialog'
import Modal from '../../componentes/Modals/Modal'
import LayoutTest from '../../Layout/LayoutTest'

//Actions
import { transferirCajaCtaCte } from '../../Redux/actions/TransferirCajaCtaCte/TransferirCajaCtaCteActions'
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'
import { setDataPdfAction } from '../../Redux/actions/PdfActions/PdfActions';

//hooks 
import useCargarTabla from '../../hooks/useCargarTabla'

const TransferirCajaCtaCte = () => {
    const dispatch = useDispatch()
    const [transferirCajaCtacte, setTransferirCajaCtacte] = useState(false)
    const [modalArqueoCaja, setModalArqueoCaja] = useState(false)
    const [modalPruebaPdf, setModalPruebaPdf] = useState(false)

    const { detallesData } = useSelector((state) => state.GlobalState)
    const { pdfData, pdfDataConfig } = useSelector(state => state.PdfState)

    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new TransferirCajaCtaCteApi().getCajas())

    /* const { sendData:transferirCajaCtaCte } = useEnviarDatos({
        action: () => new TransferirCajaCtaCteApi().transferirCajaCtaCte,
        textSucces : 'Archivo Generado',
        recargarTabla,
        funcSuccess:() => setModalGenerarArchivo(!modalGenerarArchivo)
      }) */

    const columns = [
        { title: "Descripcion", width: '25%' },
        { title: "Fecha Bajada", name: "fechabajada", width: '25%' },
        { title: "Fecha Limite", name: "fechalimite", width: '25%' },
    ]
    const actions = [
        {
            icon: "dobleFlecha",
            action: (data) => {
                dispatch(selectDataAction(data))
                setTransferirCajaCtacte(!transferirCajaCtacte)
            },
            tooltip: "Tranferir Caja A CtaCte"
        },
        {
            icon: 'imprimir',
            action: () => setModalArqueoCaja(!modalArqueoCaja),
            tooltip: "Imprimir Caja "
        },
    ]

    const buttons = [
        {
            icon: 'null',
            action: () => { },
            label: ' ',
            display: 'none'
        }
    ]

    return <LayoutTest
        buttons={buttons}
    >

        <ModalDialog
            hiddenButton={true}
            title="Transferir Caja a CtaCte"
            active={transferirCajaCtacte}
            accionAcpetar={() => transferirCajaCtaCte(detallesData)}
            labelButtonSubmit="Si, Transferir caja"
            closeModal={() => setTransferirCajaCtacte(!transferirCajaCtacte)}
        >
            <FormTransferirCajaCtaCte
                cerrarModal={() => setTransferirCajaCtacte(!transferirCajaCtacte)}
                recargarTabla={recargarTabla} />
        </ModalDialog>

        {/* PRUEBA MODAL PDF */}

        <Modal
            width='100vw'
            height='100vh'
            fondo='rgba(0, 0, 0, .4)'
            colorRelleno={'none'}
            closeModal={() => {
                setModalPruebaPdf(!modalPruebaPdf)
                dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
            }}
            active={modalPruebaPdf}
        >
            <PDFViewerArqueoCaja
                pdfData={pdfData}
                pdfDataConfig={pdfDataConfig}
                height={window.screen.height * 0.9}
                width={window.screen.width * 0.95} />
        </Modal>

        {/* MODAL IMPRIMIR ARQUEO DE CAJA  */}
        <ModalDialog
            hiddenButton={true}
            title="Imprimir Arqueo de Caja"
            active={modalArqueoCaja}
            closeModal={() => setModalArqueoCaja(!modalArqueoCaja)}
        >
            <FormImprimirArqueoDeCaja
                openSuccesModal={() => setModalPruebaPdf(!modalPruebaPdf)}
                cerrarModal={() => setModalArqueoCaja(!modalArqueoCaja)} />
        </ModalDialog>

        <Table
            tableData={dataTable}
            columns={columns}
            actions={actions}
            rowChek={false}
        />

    </LayoutTest>
}

export default TransferirCajaCtaCte