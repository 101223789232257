import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeaderIngresosFyC from './PDFTableHeaderIngresosFyC'
import PDFTableRowIngresosFyC from './PDFTableRowIngresosFyC'
import PDFTableFooterIngresosFyC from './PDFTableFooterIngresosFyC'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTableIngresosFyC = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeaderIngresosFyC />
    <PDFTableRowIngresosFyC items={data} />
    <PDFTableFooterIngresosFyC items={data} />
  </View>
);

export default PDFTableIngresosFyC