import React, { useState } from 'react';
import { Grid, Typography, Button as MuiButton, Card, CardContent, InputAdornment } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ProcesarPagos } from '../../api/ProcesarPagos';
import ModalDialog from '../../componentes/Modals/ModalDialog'
import { useSelector } from 'react-redux'
import useEnviarDatos from '../../hooks/useEnviarDatos'
import FormContainer from '../Forms/FormContainer';
import { useForm } from '../../hooks/useForm'
import { getAllModosPagos } from '../../Redux/actions/caja/CajasActionCreator';
import Controls from '../Forms/Controls'

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            height: '100%',
            minHeight: 'max-content',
            minHeight: 'max-content',
            [theme.breakpoints.down("md")]: {
                height: '92vh'
            }
        },
        button: {
            margin: theme.spacing(0.5),
            [theme.breakpoints.down("lg")]: {
                minWidth: 40,
                padding: theme.spacing(1.5, 3),
                margin: theme.spacing(0.8),
                fontSize: 14,
                borderRadius: theme.spacing(1),
            },
            height: theme.spacing(10),
            background: '#f50057',
            color: '#FFF',
            '&:hover': {
                background: '#f36c9c',
            },
        },
        input: {
            width: '100%',
            fontSize: '1.2rem',
            '& input[name="monto"]': {
                textAlign: 'right',
            },
        },
    }));

const procesarPagos = new ProcesarPagos();

const PagoACuenta = () => {

    const initialFValues = {
        fecha: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        monto: '',
        modopago: '',
        concepto: ''
    }

    const classes = useStyles();

    const { dataCollapseTable } = useSelector(state => state.GlobalState)

    const [modalIngresarDinero, setModalIngresarDinero] = useState(false)

    const [formasDePago, setFormasDePago] = useState({
        descripcion: ''
    });

    const handleObtenerFormasDePago = (valores) => {
        console.log("ESTOS SON LOS VALORES ", valores);
        if (Object.keys(valores).length > 0) {
            setFormasDePago(prevState => ({
                ...prevState,
                descripcion: valores.descripcion || '' // Actualiza solo la clave 'descripcion'
            }));
        }
    };
    


    const {
        values,
        handleChangeForm,
        resetField,
    } = useForm({ ...initialFValues, datosAlumno: dataCollapseTable });

    const {
        sendData: ingresarDinero,
        loading: loadingIngresarDinero,
    } = useEnviarDatos({
        action: () => procesarPagos.ingresarDinero({...values, concepto:'PAGO A CUENTA-'+values.concepto}),
        textSucces: 'Pago a Cuenta Exitoso',
        textError: 'No se pudo realizar el pago a cuenta',
        funcSuccess: () => setModalIngresarDinero(!modalIngresarDinero)
    })



    console.log(formasDePago,'<<<<----formasDePago')

    return (
        <>
            <ModalDialog
                closeModal={() => setModalIngresarDinero(!modalIngresarDinero)}
                active={modalIngresarDinero}
                accionAcpetar={() => ingresarDinero()}
                loading={loadingIngresarDinero}
                title={'¿Está seguro que desea realizar el siguiente pago a cuenta?'}
            >
                <Card>
                    <CardContent>
                        <Typography variant="h6">Detalles del movimiento</Typography>
                        {/* Representación visual de la cuenta para llegar al Monto Total */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', border: '1px solid #ccc', padding: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Nombre del alumno:</span>
                                <span>{dataCollapseTable.nombre}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Nombre de cuenta:</span>
                                <span>{dataCollapseTable.cuenta}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Fecha del movimiento:</span>
                                <span>{new Date().toLocaleDateString()}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Concepto del movimiento:</span>
                                <span>{`PAGO A CUENTA-`+values.concepto}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Modo de Pago:</span>
                                <span>{formasDePago.descripcion}</span>
                            </div>
                            <hr style={{ borderTop: '1px solid #ccc', margin: '8px 0' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <span>Monto:</span>
                                <span>${values?.monto}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </ModalDialog>
            <Grid container className={classes.container}>
                <FormContainer
                    onSubmit={() => setModalIngresarDinero(!modalIngresarDinero)}
                    width="70vw"
                    LabelButtonSubmit="Ingresar Dinero"
                    buttonDisabled={values.monto && values.monto > 0 ? false : true}
                    resetForm={resetField}
                    title="Ingresar dinero">
                    <Grid style={{ padding: '10px', height: '100% !important' }} container spacing={3}>
                        <Grid item xs={12}>
                            <Controls.AutocompleteSelect
                                promSelectList={getAllModosPagos()}
                                name="modopago"
                                label="Forma de pago"
                                filtro='descripcion'
                                valorSalida="id"
                                valueautocomplete={values.modopago}
                                onChange={handleChangeForm}
                                obtenerValores={{ obtenerValores: handleObtenerFormasDePago, valores: ['descripcion'] }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controls.DateField
                                title="Fecha"
                                name="fecha"
                                value={values.fecha}
                                onChange={handleChangeForm}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <Controls.Input
                                className={classes.input}
                                variant="outlined"
                                label='Concepto de Pago'
                                name='concepto'
                                value={values.concepto}
                                onChange={handleChangeForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                className={classes.input}
                                variant="outlined"
                                label='Ingresar monto'
                                name='monto'
                                type='number'
                                value={values.monto}
                                onChange={handleChangeForm}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </FormContainer>
            </Grid>
        </>
    );
}

export default PagoACuenta;
