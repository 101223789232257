import AxiosIntance from '../axios/axiosInstace';

export class InformesApi {
     BaseUrl = 'informes'
     
    getAllDataInformes(){
        return AxiosIntance.get('informes')
    }

    ingresoFacultadCarrera(body){
        return AxiosIntance.post('informes/ingresoFacultadCarrera', body)
    }

    ingresoFacultadCarreraDetalles(body){
        return AxiosIntance.post('informes/ingresoFacultadCarreraDetalles',body)
    }

    resumenDeSaldo(body){
        return AxiosIntance.post('informes/resumenDeSaldo',body)
    }

    alumnosBecadosActivos(filtro){
        return AxiosIntance.post('informes/alumnosBecadosActivos', filtro)
    }

    alumnosActivos(carreras){
        return AxiosIntance.post(`informes/alumnosActivos/`, carreras)
    }

    alumnosDadosDeBaja() {
        return AxiosIntance.get(`informes/alumnosDadosDeBaja`)
    }

    alumnosDadosDeBajaConFiltros(filter,searchText) {
        return AxiosIntance.get(`informes/alumnosDadosDeBaja/search/${filter}/${searchText}`)
    }

    alumnosDadosDeBaja(){
        return AxiosIntance.get('informes/alumnosDadosDeBaja')
    }



}