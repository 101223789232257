import AxiosIntance from '../axios/axiosInstace';
export class UsuarioApi {

    login(user){
        return AxiosIntance.post('auth/login', user)
    }

    getAllUsuarios(){
        return AxiosIntance.get('usuario/getAllusuarios')
    }
}