
import { ActionTypes } from '../actions/barridas/BarridasActionCreator'

const InitialState = {
    listBarridas:[],
}

const barridasReducer = (state = InitialState, action ) => {
    switch (action.type) {

        case ActionTypes.UPDATE_FILE_BARRIDA: {
            const index = state.listBarridas.findIndex(barr => barr.id === action.idBarrida)
            const updateBarrida = [...state.listBarridas]
            updateBarrida[index] = {...updateBarrida[index],[action.tipoArchivo]:action.nombreArchivo} 
            console.log("BARRIDA ACTUALIZADA", updateBarrida[index])
            return {
                ...state,
                listBarridas:updateBarrida   
            }
        }

        case ActionTypes.ADD_BARRIDAS: {
            let newArray = [action.barrida, ...state.listBarridas]
            return {
                ...state,
                listBarridas: [...newArray]
            }
        }

        case ActionTypes.GET_ALL_BARRIDAS: {
            return {
                ...state,
                listBarridas: action.listBarridas
            }
        }

        case ActionTypes.UPDATE_BARRIDAS: {
           
            // Se busca el indice para actualizar la barrida
            const index = state.listBarridas.findIndex(barr => barr.id === action.idBarrida)
            const updateBarrida = [...state.listBarridas]
            updateBarrida[index] = {...action.newBarrida, enviado: action.newBarrida.enviado ? 1 : 0} 
            
            return {
                ...state,
                listBarridas: updateBarrida
            }
        }

        default: return state;
    }
}
export default barridasReducer