import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    alumno: {
        width: '35%',
    },

    detalle: {
        width: '35%',
    },

    codigo: {
        width: '10%',
    },

    debeHaber: {
        width: '10%',
        textAlign: 'right'
    },

    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    }
});


const PDFTableRow = ({ items }) => {
    let rows = []

    items.forEach((item, index) => {

        item.forEach((fila, indx) => {
            
            if (indx == 0) {
                rows.push(
                    <View style={{ ...styles.row, ...styles.negrita }} >
                        <Text style={{ ...styles.codigo, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.alumno, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.detalle, ...styles.text }}>{fila.detalle}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{""}</Text>
                    </View>,
                )
            }

            if (item.length - 1 == indx) {
                rows.push(
                    //agrego fila que contiene los subtotales de los grupos
                    <View style={{ ...styles.row, ...styles.negrita }} >
                        <Text style={{ ...styles.codigo, ...styles.text }}>{"Cant.: " + fila['Cantidad']}</Text>
                        <Text style={{ ...styles.alumno, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.detalle, ...styles.text }}>{"Sub Total:"}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{(fila["subTotalDebe"]).toFixed(1)}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{(fila["subTotalHaber"]).toFixed(1)}</Text>
                    </View>,
                    //agrego fila en balnco 
                    <View style={{ ...styles.row, borderBottomColor: borderColor, borderBottomWidth: 1 }}>
                        <Text style={{ ...styles.codigo, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.alumno, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalle, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{" "}</Text>
                    </View>
                )
            } else {
                rows.push(
                    <View style={styles.row}>
                        <Text style={{ ...styles.codigo, ...styles.text }}>{fila.codigo}</Text>
                        <Text style={{ ...styles.alumno, ...styles.text }}>{fila.nombre}</Text>
                        <Text style={{ ...styles.detalle, ...styles.text }}>{fila.concepto}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{fila["debe"]}</Text>
                        <Text style={{ ...styles.debeHaber, ...styles.text }}>{fila["haber"]}</Text>

                    </View>)
            }
        })

    }
    )

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRow