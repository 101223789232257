import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeaderCtaCte from './PDFTableHeaderCtaCte'
import PDFTableRowCtaCte from './PDFTableRowCtaCte'
import PDFTableFooterCtaCte from './PDFTableFooterCtaCte'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTableCtaCte = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeaderCtaCte />
    <PDFTableRowCtaCte items={data} />
     <PDFTableFooterCtaCte items={data[data.length-1]} />
  </View>
);

export default PDFTableCtaCte