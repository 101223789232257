import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Controls from './Controls';
import useSelect from '../../hooks/useSelect';
import { useState, useEffect } from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        selectProps: {
            height: '100px'
        },
        marginNone: {
            margin: 'none'
        }
    }));

const AutocompleteSelect = (props) => {
    const classes = useStyles();
    const { filtro, promSelectList, onChange, name, valueautocomplete, label, valorSalida = null, multiple = false, required, onKeyPress, disablePortal=true, obtenerValores } = props;

    const { opciones=[] } = useSelect(promSelectList);
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (!multiple && valueautocomplete) {
            const selectedOption = opciones.find(option => option[valorSalida] === valueautocomplete);
            if (selectedOption) {
                setValue(selectedOption);
                handleObtenerValores(selectedOption);
            }
        } else if (multiple && valueautocomplete) {
            const selectedValues = opciones.filter(option => valueautocomplete.includes(option[valorSalida]));
            setValue(selectedValues);
            handleObtenerValores(selectedValues);
        }
    }, [opciones, valueautocomplete, multiple, valorSalida]);

    const onChangeAutocomplete = (event, newValue) => {
        setValue(newValue);
        if (multiple) {
            onChange({ target: { value: newValue.map(val => val[valorSalida]), name } });
            handleObtenerValores(newValue);
        } else {
            onChange({ target: { value: newValue ? newValue[valorSalida] : '', name } });
            handleObtenerValores(newValue);
        }
    };

    const handleObtenerValores = (selectedOption) => {
        if (obtenerValores && typeof obtenerValores.obtenerValores === 'function') {
            const selectedKeys = obtenerValores.valores || [];
            const selectedValues = {};
    
            selectedKeys.forEach(key => {
                // Verifica si la opci�n seleccionada contiene la clave antes de intentar acceder a ella
                if (selectedOption && key in selectedOption) {
                    selectedValues[key] = selectedOption[key];
                }
            });
    
            obtenerValores.obtenerValores(selectedValues);
        }
    };

    return (
        <Autocomplete
            {...props}
            multiple={multiple}
            name="autocomplete"
            value={value}
            getOptionSelected={(option, value) => value ? value[valorSalida] === option[valorSalida] : true}
            getOptionLabel={(option) => option[filtro] || ""}
            MenuProps={{
                disableScrollLock: false,
                classes: { paper: classes.selectProps }
            }}
            onChange={onChangeAutocomplete}
            inputVariant="outlined"
            disablePortal={disablePortal}
            placeholder={label}
            id="combo-box-demo"
            options={opciones}
            renderInput={(params) => (
                <Controls.Input
                    onKeyPress={onKeyPress ? onKeyPress : (e) => { e.key === 'Enter' && e.preventDefault(); }}
                    {...params}
                    label={label}
                    className={classes.marginNone}
                    size="small"
                    placeholder={label}
                    variant="outlined"
                />
            )}
        />
    );
};

export default AutocompleteSelect;
