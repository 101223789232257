import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeaderSaldoDetalles from './PDFTableHeaderSaldoDetalles'
import PDFTableRowIngresosFyC from './PDFTableRowSaldoDetalles'
import PDFTableFooterSaldoDetalles from './PDFTableFooterSaldoDetalles'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTableSaldoDetalles = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeaderSaldoDetalles />
    <PDFTableRowIngresosFyC items={data} />
    <PDFTableFooterSaldoDetalles items={data} />
  </View>
);

export default PDFTableSaldoDetalles