import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import Icons from '../Icons';

const propTypes = {
    endIcon: PropTypes.string,
    startIcon: PropTypes.string,
    label: PropTypes.string,
    hover: PropTypes.bool,
    onClick: PropTypes.func,
    typeButton: PropTypes.string,
    centerIcon: PropTypes.string
}

const ButtonIcon = ({
    centerIcon = 'null',
    startIcon = "null",
    endIcon = "null",
    label, typeButton = "default",
    hover = false,
    onClick,
    textAlign = "",
    width = 'auto',
    display,
    ...other }) => {

    const useStyles = makeStyles(() =>
        createStyles({

            default: {
                color: "#3e3e3c",
                textTransform: 'capitalize',
                background: '#FFF',
                height: 40,
                boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
                '&:hover': {
                    background: '#f9bc06'
                },
                display:`${ display ? display + '!important': ''}`
            },
            hover: {
                '&:hover': {
                    background: '#d9d5e8'
                },
            },
            iconButtonLabel: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-between'
              },
        }))

    const classes = useStyles()
    return (
        <Button
         classes={{label: classes.iconButtonLabel}}
            variant="contained"
            style={{ width: `${width}`, display: 'flex', justifyContent: `${textAlign === 'right' ? 'flex-end' : textAlign === 'left' ? 'flex-start' : 'center'}` }}
            onClick={onClick}
            className={`${hover && classes.hover} ${classes[typeButton]}`}
            endIcon={<Icons type={endIcon} />}
        >
            {!other.children && (label ? `${label[0].toUpperCase() + label.slice(1)}` : <Icons type={centerIcon} />)}
            {other.children && other.children}
        </Button>
    )
}

ButtonIcon.propTypes = propTypes

export default ButtonIcon