import AxiosIntance from '../axios/axiosInstace';

export class PlanesPagoApi {
     BaseUrl = 'planesPago'

     getTiposPlanesPago(){
        return AxiosIntance.get(`planesPago/tipos`)
    }

    getPlanesPagosByIdCtaPersona(idCtaPersona){
        return AxiosIntance.get(`planesPago/${idCtaPersona}`)
    }

    getDetallesPlanesPago(idPlan){
        return AxiosIntance.get(`planesPago/detalles/${idPlan}`)
    }

    crearNuevoPlan(nuevoPlan){
        return AxiosIntance.post(`planesPago`,nuevoPlan)
    }

    eliminarPlanPago(idPlan){
        return AxiosIntance.delete(`planespago/eliminar/${idPlan}`)
    }
}