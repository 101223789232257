import { makeStyles, createStyles, Typography, Divider, Grid } from "@material-ui/core";
import Controls from "./Controls";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import useIsMounted from '../../hooks/useIsMounted';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from "react-router-dom";

import moment from "moment";

const FormContainer = ({ sizeTitle = "h5",
    height = '100%',
    accionCancelar = () => { },
    loading = false,
    width = '100%',
    labelButtonCancelar = "Cancelar",
    resetFormDisabled='',
    buttonDisabled = '',
    children,
    title,
    LabelButtonSubmit = "nuevo",
    resetForm = () => { },
    hidenButton = false,
    alignContent = "",
    onSubmit ,
    link = "",
    target = "",

    ...other }
) => {
    useIsMounted()
    const useStyles = makeStyles((theme) =>

        createStyles({
            root: {
                width: width,
                background: "#FFF",
                height: '100%',

                '& .MuiFormControl-root': {
                    width: '100%',
                    height: '100%'
                },
            },
            fieldsContainer: {
                height: "80%",
                overflowY: 'scroll',
                overflowX: 'hidden',
                padding: '10px !important'
            },
            formTitle: {
                fontWeight: 300,
            },
            formTitleContainer: {
                height: 'auto',
                margin: 10
            },
            buttons: {
                height: 'auto',
                alignItems: 'center'
            },

        }))

    moment.locale("es")

    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider libInstance={moment} locale='es' utils={MomentUtils}>
            <form
                onSubmit={(e)=>{
                    e.preventDefault()
                    onSubmit(e)
                }}
                className={classes.root}
                autoComplete="off" >
                <Grid style={{ height: height }} container spacing={2} >
                    {
                        title && <Grid item className={classes.formTitleContainer} xs={12}>
                            <Typography className={classes.formTitle} variant={sizeTitle}>{title}</Typography>
                        </Grid>
                    }
                    {
                        loading && <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    }

                    <Grid item className={classes.fieldsContainer} xs={12}>
                        <Grid container spacing={3}>
                            {children}
                        </Grid>
                    </Grid>
                    {
                        hidenButton
                            ? null
                            : <>
                                <Grid xs={12} item>
                                    <Divider />
                                </Grid>
                                <Grid spacing={1} container className={classes.buttons} xs={12} item>
                                    <Grid item xs={3}>
                                        <Controls.Button
                                            onClick={accionCancelar}
                                            variant="primary"
                                            text={labelButtonCancelar} />
                                    </Grid>
                                    <Grid item container justify="flex-end" direction='row' xs={9}>
                                       {!resetFormDisabled && <Controls.Button
                                            disabled={buttonDisabled}
                                            text="Limpiar Formulario"
                                            variant={buttonDisabled ? 'disabled' : "secondary"}
                                            onClick={resetForm} />}

                                        {link
                                            ? <Link to={link} target={target}>
                                                <Controls.Button
                                                    disabled={buttonDisabled}
                                                    onClick={onSubmit}
                                                    type="button"
                                                    variant={buttonDisabled ? 'disabled' : "primary"}
                                                    text={LabelButtonSubmit} />
                                            </Link>
                                            : <Grid>
                                                <Controls.Button
                                                    disabled={buttonDisabled}
                                                    type="submit"
                                                    variant={buttonDisabled ? 'disabled' : "primary"}
                                                    text={LabelButtonSubmit} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Grid>
            </form>
        </MuiPickersUtilsProvider>
    )
}

export default FormContainer