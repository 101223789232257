import AxiosIntance from '../axios/axiosInstace';

export class ProcesarPagos {
     BaseUrl = 'procesarPagos'

     obtenerDebitosNoPagados(idCuentaPersona) {
        return AxiosIntance.get(`procesarPagos/obtenerDebitosNoPagados/${idCuentaPersona}`)
    }

    pagarDebito(debito){
        return AxiosIntance.post(`procesarPagos/pagarDebito`,debito)
    }

    ingresarDinero(monto){
        return AxiosIntance.post(`procesarPagos/ingresarDinero`,monto)
    }
}