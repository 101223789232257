import { ActionTypes } from '../actions/PdfActions/PdfActions'

const InitialState = {
    pdfData:[],
    pdfDataConfig:{}
}

const pdfReducer  = (state = InitialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_DATA_PDF :{
            console.log("ACCION EM EL REDUCER ",action  )
            return {
                ...state,
                pdfData: action.pdfData,
                pdfDataConfig:action.pdfDataConfig
            }
        }

        default: return state;
    }
}
export default pdfReducer