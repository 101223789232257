import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const PDFTableBlankSpace = ({ rowsCount, height=7, borderColor='', fontSize=9.5 }) => {

    const styles = StyleSheet.create({
        fullwidth: {
            color:'#FFF',
            width: '100%',
            height:height,
            fontSize:fontSize,
            borderTopColor: borderColor,
            borderTopWidth: borderColor ? 1 : 0,
        }
    });

    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map((x, i) =>
        <Text key={`BR${i}`} style={styles.fullwidth}>-</Text>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableBlankSpace