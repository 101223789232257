import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        fontFamily: 'Times-Roman',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        textAlign: 'center',
        fontStyle: 'light',
        flexGrow: 1,
    },

    textHeader:{
        fontSize:10,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    concepto: {
        width: '32%',
    },
    nombre: {
        width: '20%',
    },
    detalles: {
        width: '7%',
    },
    cod:{
        width: '4%',
    },
    recibo:{
        width: '5%',
    },
    otros:{
        width:'4%',
    },
  });

  const PDFTableHeader = () => (
    <View style={styles.container} fixed>
        <Text style={{...styles.cod, ...styles.textHeader}}>COD</Text>
        <Text style={{...styles.nombre, ...styles.textHeader}}>NOMBRE</Text>
        <Text style={{...styles.concepto, ...styles.textHeader}}>CONCEPTO</Text>
        <Text style={{...styles.recibo, ...styles.textHeader}}>REC.</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>ARAN.</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>BONIF.</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>INT.</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>BIBLIO.</Text>
        <Text style={{...styles.otros, ...styles.textHeader}}>OTROS.</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>TOTAL.</Text>
    </View>
  );
  
  export default PDFTableHeader