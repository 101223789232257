import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Tabs, Tab, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { decodeToken } from '../../src/utils/authToken'

const useStyles = makeStyles((theme) =>
  createStyles({
    rootTab: {
      textTransform: 'none',
      minWidth: 'max-content',
      minHeight: 35,
      padding: '0px 10px',
      fontSize: '0.9rem',
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },

    tabSelect: {
      color: '#f9bc06',
      fontWeight: 600,
    },
    tab: {
      color: '#90a5c0',
      fontWeight: 400,
      border: 'solid 1px transparent',
    },
    tabs: {
      minHeight: 45,
      background: '#FFF',
    },
    indicator: {
      background: '#f9bc06'
    },
    pagination: {
      margin: '0px 20px',
      color: '#8cbaff',
      fontWeight: 400,
    }
  }))
export default function AppBar() {

  const tabs = [
    { label: "Cuentas", value: "/auth/cuentas" },
    { label: "Personas", value: "/auth/personas" },
    { label: "Aranceles", value: "/auth/aranceles" },
    { label: "Barridas", value: "/auth/barridas" },
    { label: 'Alumos Sao', value: "/auth/alumnos_sao" },
     { label: 'Informes', value: '/auth/informes' },
    { label: 'Cajas', value: "/auth/transferirCajaCtaCte" },
  ]


  const tabsUsuariosCaja = [
    { label: "Personas", value: "/auth/personas" },
    { label: 'Alumos Sao', value: "/auth/alumnos_sao" },
    { label: "Barridas", value: "/auth/barridas" },
    { label: "Movimientos de Caja", value: "/auth/caja" },
    { label: 'Cajas', value: "/auth/transferirCajaCtaCte" }
  ]

  const [currentTag, setCurrenTag] = useState('')

  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      <Grid container item xs={12} justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTag || window.location.pathname}
          className={classes.tabs}
          onChange={(e, currTag) => setCurrenTag(currTag)}
          classes={{
            indicator: classes.indicator
          }}>
          {(decodeToken('idCaja') ? tabsUsuariosCaja : tabs).map(tab => {
            return <Tab
              disableFocusRipple={true}
              disableRipple={true}
              className={`${classes.rootTab} ${window.location.pathname === tab.value ? classes.tabSelect : classes.tab}`}
              value={`${tab.value}`}
              label={tab.label}
              component={Link}
              to={`${tab.value}`}
            />
          })}
        </Tabs>
      </Grid>
    </Grid>

  )
}
