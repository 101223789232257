import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ButtonIcon from '../Buttons/ButtonIcon';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'max-content',
      display: 'inline-block',
    },
    accion: {
      padding: '0px 5px',
    },
    button: {
      display: 'inline-block',
      margin: 2,
      padding: 5,
      minHeight: 0,
      minWidth: 0,
    },
  })
);

const ActionMenu = ({ data = {}, options = [], onClick = () => {} }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root} onClick={onClick}>
      <IconButton
        className={classes.button}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        style={{ fontSize: '15px' }}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map(
          (option) =>
            (option.vermenu === undefined || option.vermenu) && ( // Mostrar opci�n si option.vermenu es true o si no est� definido
              <li className={classes.accion} key={option.title} onClick={handleClose}>
                <ButtonIcon
                  onClick={() => {
                    option.action(data);
                  }}
                  textAlign="left"
                  endIcon={option.icon}
                  children={option.element ? option.element : null}
                  width="100%"
                  label={option.title}
                />
              </li>
            )
        )}
      </Menu>
    </div>
  );
};

export default ActionMenu;
