import { Router, Route, Switch } from "react-router-dom";
import history from "./helpers/history";
import { useMediaQuery } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { decodeToken } from "./utils/authToken";

//Components
import AuthRoute from "./componentes/AuthRoute";

//Page
import LoginPage from './pages/LoginPage/LoginPage'
import Personas from './pages/Personas/Personas';
import Informes from './pages/Informes/Informes';
import Cuentas from './pages/Cuentas/Cuentas';
import Aranceles from './pages/Aranceles/Aranceles';
import Barridas from './pages/Barrida/Barridas'
import AlumnosSao from "./pages/AlumnosSao/AlumnosSao";
import TransferirCajaCtaCte from "./pages/TransferirCajaCtaCte/TransferirCajaCtaCte";
import Caja from './pages/Caja/Caja'
import { useTheme } from '@mui/material/styles';


function App() {

  const theme = useTheme();

  const minxs = useMediaQuery('(min-width:500px)')
  const minsm = useMediaQuery('(min-width:600px)')
  const minmd = useMediaQuery('(min-width:960px)')
  const minlg = useMediaQuery('(min-width:1280px)')
  const minxl = useMediaQuery('(min-width:1920px)')

  const xs = (minxs && !minsm)
  const sm = (minsm && !minmd)
  const md = (minmd && !minlg)
  const lg = (minlg && !minxl)
  const xl = minxl

  const outerTheme = createMuiTheme({
    spacing: (xs || sm) ? 3.20 : md ? 3.5 : lg ? 3.5 : 8,
    typography: {
      fontSize: (xs || sm) ? 11 : md ?  11 : lg ? 12 : 13,
    },
    palette: {
      primary: {
        main: "#3e3e3c"
      },
    },
  })


  return (
    <ThemeProvider theme={outerTheme}>
      <Router history={history}>
        <Route exact path="/" component={LoginPage} />


        {/*
              <AuthRoute exact path="/auth/informes">
                <Informes />
              </AuthRoute>
          */}

        {decodeToken('idCaja') ?
          //Rutas habilitadas para usuarios de caja 

          <Switch>
            <AuthRoute exact path="/auth/transferirCajaCtaCte">
              <TransferirCajaCtaCte />
            </AuthRoute>
            <AuthRoute exact path="/auth/alumnos_sao">
              <AlumnosSao />
            </AuthRoute>
            <AuthRoute exact path="/auth/personas">
              <Personas />
            </AuthRoute>
            <AuthRoute exact path="/auth/caja">
              <Caja />
            </AuthRoute>
            <AuthRoute exact path="/auth/barridas">
              <Barridas />
            </AuthRoute>
            <AuthRoute path="*">
              <Personas />
            </AuthRoute>
          </Switch>

          :
          <Switch>
            {
              //Rutas habilitadas para usuarios de aranceles y administradores 

            }
            <AuthRoute exact path="/auth/transferirCajaCtaCte">
              <TransferirCajaCtaCte />
            </AuthRoute>
            <AuthRoute exact path="/auth/alumnos_sao">
              <AlumnosSao />
            </AuthRoute>
            <AuthRoute exact path="/auth/aranceles">
              <Aranceles />
            </AuthRoute>
            <AuthRoute exact path="/auth/barridas">
              <Barridas />
            </AuthRoute>
            <AuthRoute exact path="/auth/personas">
              <Personas />
            </AuthRoute>
            <AuthRoute exact path="/auth/caja">
              <Caja />
            </AuthRoute>
            <AuthRoute exact path="/auth/cuentas">
              <Cuentas />
            </AuthRoute>
            <AuthRoute exact path="/auth/informes">
              <Informes />
            </AuthRoute>
            <AuthRoute exact path="*">
              <Cuentas />
            </AuthRoute>
          </Switch>
        }

      </Router>
    </ThemeProvider>
  );
}

export default App;
