import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import PDFTableBlankSpace from './PDFTableBlankSpace';
import { formatearNumero, datosByPtoVenta } from './funciones';
const { NumerosALetras } = require('numero-a-letras')

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 30
    },

    row: {
        flexDirection: 'row',
        borderColor: '#909598',
        width: '100%',
        alignItems: 'center',
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center',
        lineHeight: 0.8
    },
    text: {
        fontSize: 10,
    },

    filaEnBlanco: {
        height: 7,
        width: '100%',
        fontSize: 9.5,
        /*     borderWidth: 1,
            borderColor: '#000', */
    },

    itemDesc: {
        height: 12,
        width: '25%',
        textAlign: 'left',
    },
    itemValor: {
        height: 12,
        textAlign: 'right',
        width: '12%'
    },
});

const DatosUcu = ({ data, copia = false }) => {

    console.log("Ver DATA desde el pdf ", data)
    const { lugar,
        direccion,
        cp,
        provincia,
        telefono,
        fax,
        caracteristica } = datosByPtoVenta(data.ptoVenta)


    return (
        <View style={{ ...styles.tableContainer, marginTop: copia ? 60 : 30 }}>

            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '50%',
                    borderTopWidth: 3,
                    borderLeftWidth: 3,
                    borderRightWidth: 0.5,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>
                <View style={{
                    width: '50%',
                    borderTopWidth: 3,
                    borderLeftWidth: 0.5,
                    borderRightWidth: 3,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>

                {/* ---------------------FILA--------------------- */}
            </View>

            <View style={{ ...styles.row, display: 'flex' }}>
                <View style={{
                    width: '45%',
                    borderLeftWidth: 3,
                    borderRightWidth: 0.5,
                    borderColor: '#000',
                    padding: 5,
                    height: 60,

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}>ASOCIACION EDUCACIONISTA</Text>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}>LA FRATERNIDAD</Text>
                    <Text style={{ fontSize: 10, width: '100%', paddingBottom: 5 }}>Universidad de Concepcion del Uruguay</Text>
                </View>
                <View style={{
                    width: '10%',
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 60,
                }}>
                    <Text style={{ fontSize: 35, width: '100%', textAlign: 'center', alignItems: 'center', display: 'flex', height: '75%' }}>C</Text>
                    <Text style={{ fontSize: 9, width: '100%', textAlign: 'center', alignItems: 'flex-end', height: '25%' }}>{data.idTipoFactura == 4 ? 'COD 011' : 'COD 015'}</Text>

                </View>
                <View style={{
                    width: '45%',
                    borderLeftWidth: 0.5,
                    lineHeight: 1,
                    borderRightWidth: 3,
                    borderColor: '#000',
                    height: 60,

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingLeft: 10, paddingBottom: 15, textAlign: 'center' }}>N° {(data.ptoVenta).toString().padStart(5, '0')} - {(data.nroAfip ? data.nroAfip : 0).toString().padStart(6, '0')}</Text>
                    <View style={{ ...styles.row, display: 'flex' }}>
                        <Text style={{ fontSize: 9, width: '30%', paddingBottom: 5, paddingLeft: 10 }}>Lugar y Fecha:</Text>
                        <Text style={{ fontSize: 9, width: '70%', paddingBottom: 5 }}>{lugar}{data.fecha.slice(0, 10).split('-').reverse().join('/')}</Text>
                    </View>
                </View>
            </View>

            {/* ---------------------FILA--------------------- */}

            <View style={{ ...styles.row, display: 'flex' }}>
                <View style={{
                    width: '50%',
                    borderLeftWidth: 3,
                    borderBottomWidth: 0.5,
                    lineHeight: 1,
                    borderRightWidth: 0.5,
                    borderColor: '#000',
                    padding: 5,
                    height: 50,

                }}>
                    <Text style={{ fontSize: 9, width: '100%', paddingBottom: 3 }}>{direccion.toUpperCase()} - TEL. ({caracteristica}) {telefono} - TELEFAX ({caracteristica}) {fax}</Text>
                    <Text style={{ fontSize: 9, width: '100%', paddingBottom: 3 }}>C.P {cp} - {lugar.toUpperCase()} - {provincia}</Text>
                    <Text style={{ fontSize: 9, width: '100%', paddingBottom: 3 }}>I.V.A.: EXENTO - GANANCIAS: EXENTO - INGR: BRUTOS: EXENTO</Text>

                </View>

                <View style={{
                    paddingTop: 5,
                    borderBottomWidth: 0.5,
                    width: '50%',
                    borderLeftWidth: 0.5,
                    borderRightWidth: 3,
                    borderColor: '#000',
                    height: 50,

                }}>
                    <Text style={{ fontSize: 9, width: '100%', paddingBottom: 5, paddingLeft: 10 }}>C.U.I.T. N°: 30-53091769-6 - INGR. BRUTOS: 30-53091769-6</Text>
                    <Text style={{ fontSize: 9, width: '100%', paddingBottom: 5, paddingLeft: 10 }}>FECHA INICIO ACTIV.: 20-05-1971</Text>
                </View>
            </View>


            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '50%',
                    borderLeftWidth: 3,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>
                <View style={{
                    width: '50%',
                    borderRightWidth: 3,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>
                {/* ---------------------FILA--------------------- */}
            </View>

            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '50%',
                    borderLeftWidth: 3,
                    borderBottomWidth: 0.5,
                    borderColor: '#000',
                    height: 60,


                }}>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '15%', paddingBottom: 10, paddingTop: 10 }}>Sr/es:</Text>
                        <Text style={{ fontSize: 10, width: '85%', paddingBottom: 10, paddingTop: 10 }}>{data.nombre}</Text>
                    </View>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '35%', paddingBottom: 10 }}>Condicion de venta:</Text>
                        <Text style={{ fontSize: 10, width: '65%', paddingBottom: 10 }}>{data.modopago}</Text>
                    </View>
                </View>
                <View style={{
                    width: '50%',
                    borderRightWidth: 3,
                    borderBottomWidth: 0.5,
                    borderColor: '#000',
                    height: 60,

                }}>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '25%', paddingBottom: 10, paddingTop: 10 }}>CUIT/DNI:</Text>
                        <Text style={{ fontSize: 10, width: '75%', paddingBottom: 10, paddingTop: 10 }}>{data.ndoc!='0' ? data.ndoc : data.cuit}</Text>
                        {console.log("ESTE ES EL DOCUMENTO ", data.ndoc!='0' ? data.ndoc : data.cuit)}
                    </View>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '20%', paddingBottom: 10 }}>Cuenta:</Text>
                        <Text style={{ fontSize: 10, width: '80%', paddingBottom: 10 }}>{data.carrera}</Text>
                    </View>
                </View>
                {/* ---------------------FILA--------------------- */}

            </View>
            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '50%',
                    borderLeftWidth: 3,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>
                <View style={{
                    width: '50%',
                    borderRightWidth: 3,
                    borderColor: '#000',

                }}>
                    <Text style={{ fontSize: 15, width: '100%', paddingBottom: 5 }}></Text>
                </View>
                {/* ---------------------FILA--------------------- */}
            </View>
            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '100%',
                    height: 100,
                    borderRightWidth: 3,
                    borderLeftWidth: 3,
                    borderColor: '#000',
                    borderBottomWidth: 3,

                }}>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '35%', paddingBottom: 25, paddingTop: 20 }}>Recibimos la suma de Pesos:</Text>
                        <Text style={{ fontSize: 10, width: '65%', paddingBottom: 25, paddingTop: 20 }}>{NumerosALetras(data.monto).replace('Pesos', 'con').replace('M.N.', '').replace('Un Peso', 'Uno con')}</Text>
                    </View>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, width: '35%' }}>Para acreditar en su cuenta, en concepto de:</Text>
                        <Text style={{ fontSize: 10, width: '65%' }}>{data.descripcion}</Text>
                    </View>
                </View>
                {/* ---------------------FILA--------------------- */}
            </View>

            <View style={{
                ...styles.row, display: 'flex'
            }}>
                {/* ------------------------- FILA ------------------------- */}
                <View style={{
                    width: '100%',
                    borderLeftWidth: 3,
                    height: 40,
                    borderRightWidth: 3,
                    borderColor: '#000',
                    borderBottomWidth: 3,

                }}>
                    <View style={{ ...styles.row, display: 'flex', paddingLeft: 5 }}>
                        <View style={{ width: '60%', paddingLeft: 5, display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center', borderRightWidth: 3 }}>
                            <Text style={{ fontSize: 14, paddingBottom: 8 }}>TOTAL</Text>
                        </View>
                        <View style={{ width: '40%', paddingLeft: 5, display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                            <Text style={{ fontSizeView: 14, paddingBottom: 8 }}>$ {formatearNumero(data.monto)}</Text>
                        </View>
                    </View>
                </View>
                {/* ---------------------FILA--------------------- */}
            </View>

            {data.CAE
                ? <View style={{ ...styles.row, display: 'flex' }}>
                    <View style={{
                        width: '100%',
                        height: 100,
                        borderColor: '#000',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                        <Text style={{ fontSize: 12, paddingBottom: 10 }}>
                            CAE N°: {data.CAE ? data.CAE : '-----'}
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                            Fecha de Vto. de CAE: {data.CAE ? data.fechavtocae : '-----'}
                        </Text>
                    </View>
                </View>
                : null}
        </View>

    )
};

export default DatosUcu