import { useEffect, useState } from "react";

const useCargarDatos = (promise) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const cargarDatos = async () => {
        if (typeof promise === 'object' && typeof promise.then === 'function') {
            setLoading(true)
            promise
                .then(resp => {
                    setData(resp)
                    setLoading(false)
                })
                .catch(err => console.log("ERROR SET DATA HOOK CARGAR DATOS", err))
        }else {
            setData([])
        }
    }
    useEffect(() => {
        cargarDatos()
    }, [])

    return {
        dataTable: data,
        loading

    }
}

export default useCargarDatos