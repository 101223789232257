import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell, CellAction } from '../../componentes/Cell';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@mui/material/Tooltip';

import Icons from '../../componentes/Icons';

import ModalDialog from '../../componentes/Modals/ModalDialog';

import IconButton from "@material-ui/core/IconButton";
import LayoutTest from '../../Layout/LayoutTest';
import { useDispatch, useSelector } from 'react-redux'

//api
import { InformesApi } from '../../api/InformesApi';
import { PersonaApi } from '../../api/PersonaApi';


//hooks
import useCargarTabla from '../../hooks/useCargarTabla';
import useEnviarDatos from '../../hooks/useEnviarDatos'

//filtro 
import FiltroAlumnosActivos from './FiltroAlumnosActivos'


const width = '90vw'

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650,
            borderRadius: 5,
            height: 'max-content',
        },
        tableContainer: {
            padding: 10,
            maxHeight: '78vh',
            borderRadius: 5,
            overflowY: 'hidden',
            [theme.breakpoints.down("md")]: {
                maxHeight: '70vh'
            },
            display: 'flex',
            alignItems: 'start',
            overflowY: 'scroll',

        },
        container: {
            height: '100%',
            minHeight: 'max-content',
            width: width,
            [theme.breakpoints.down("md")]: {
                height: '92vh'
            },

        },
        rowHeader: {
            background: '#fff',
            width: "100%",
            height: 'max-content'
        },
        cellHeader: {
            color: '#3e3e3c',
            fontWeight:600
        },
        containerHeader: {
            height: '15%',
            [theme.breakpoints.down("md")]: {
                maxHeight: '10%'
            },
            display: 'grid',
            placeItems: 'center',
            width: '100%'
        },

        header: {
            padding: '10px',
            height: '60%',
            width: '100%',
            borderRadius: 5,
        },
        paginationTable: {
            color: '#6E6893',
            height: 35,
        },
        footer: {
            height: 35
        },
    }));

const AlumnosBecadosActivos = () => {

    const classes = useStyles();
    const dispatch = useDispatch()

    const [modalPasarAlumnoNoActivo, setModalPasarAlumnoNoActivo] = useState(false)
    const [prevNota, setPrevNota] = useState({})
    const [dataEdit, setDataEdit] = useState({ ...prevNota })
    const [filtro, setFiltro] = useState({
        idcuenta:'',
        idbeneficio:''
    })
    
    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new InformesApi().alumnosBecadosActivos(filtro))

    const handleChangeFilter = ({ target }) => {
        const { name, value } = target 
        setFiltro({
          ...filtro,
          [name]: value
        })
      }
    
    const cargarPrev = (data) => {
        setDataEdit({
            ...dataEdit,
            ...data
        })
    }

    useEffect(()=>{
        recargarTabla()
    },[filtro.idcuenta, filtro.idbeneficio])

    const columns = [
        { title: "ID", name: 'idPersona', width: '10%' },
        { title: "Nombre", name: 'nombre', width: '30%' },
        { title: "codigo Carrera", name: 'codcar', width: '10%' },
        { title: "Nombre Cuenta", name: 'descripcion', width: '30%' },
        { title: "Activo", name: 'activo', width: '10%', type: 'boolean' },
        { title: "Beneficio", name: 'beneficio', width: '10%' }
    ]

    const actions = [
        {
            icon: "remove",
            action: (data) => {
                cargarPrev(data);
                setModalPasarAlumnoNoActivo(!modalPasarAlumnoNoActivo)
            },
            tooltip: "Pasar alumno a NO Activo"
        },
    ]

    const { sendData: PasarAlumnoNoActivo } = useEnviarDatos({
        action: new PersonaApi().pasarAlumnosNoActivo,
        dataSubmit: dataEdit,
        textSucces: 'Listo',
        recargarTabla,
        funcSuccess: () => setModalPasarAlumnoNoActivo(!modalPasarAlumnoNoActivo)
    })

    return (<LayoutTest
        filter={
        <FiltroAlumnosActivos
            recargarTabla={recargarTabla}
            handleChangeFilter={handleChangeFilter}
            filtro={filtro}
        />}
        header={false}
        appBar={false}
        customHeader={
            <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Typography className={classes.tile} variant="h4">{`Alumnos Becados Activos`}</Typography>
                </Grid>
            </>
        }
    >
        <Grid item xs={12} container alignItems="flex-start" className={classes.tableContainer}>
            <Grid item xs={12}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead className={classes.rowHeader}>
                        <TableRow>
                            {
                                columns.length && columns.map((col, i) =>

                                    <TableCell
                                        className={classes.cellHeader}
                                        align={col.align}
                                        variant="head"
                                        width={col.width}
                                        key={i}>
                                        {col.title}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.length
                            ? dataTable.map((data, index) =>
                                <TableRow key={index}>
                                    {
                                        columns.map(key => {
                                            return key.title === " " ? null :
                                                <Cell
                                                    align={key.align}
                                                    size={'medium'}
                                                    allRowData={data}
                                                    columna={key.title}
                                                    openModal={() => key.openModal && (() => { })}
                                                    boolean={key.boolean}
                                                    isArray={key.isArray}
                                                    width={key.width}
                                                    action={key.action} >
                                                    {
                                                        key.type === "boolean"
                                                            ? <Icons type={key.name
                                                                ? !!data[key.name] || !!data[key.name.toLowerCase()]
                                                                : !!data[key.title.toLowerCase()]} />
                                                            : key.name
                                                                ? data[key.name] || data[key.name.toLowerCase()]
                                                                : data[key.title.toLowerCase()]
                                                    }
                                                </Cell>
                                        })}

                                    <CellAction align='right'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                            {actions.map((accion, index) =>
                                                <Tooltip title={accion.tooltip ? accion.tooltip : ''} followCursor>
                                                    <IconButton
                                                        key={index}
                                                        onClick={accion.action && (() => accion.action(data))}
                                                    >
                                                        <Icons type={accion.icon} />
                                                    </IconButton>
                                                </Tooltip>

                                            )
                                            }
                                        </div>
                                    </CellAction>
                                </TableRow>)
                            : <TableRow style={{ height: 54.5 }}>
                                <TableCell colSpan={20}>
                                    <Typography variant="h6">No se encontraron Alumnos</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>

        </Grid>

        <ModalDialog
            title={"Pasar alumno a NO ACTIVO"}
            labelButtonSubmit="Aceptar"
            accionAcpetar={PasarAlumnoNoActivo}
            active={modalPasarAlumnoNoActivo}
            closeModal={() => setModalPasarAlumnoNoActivo(!modalPasarAlumnoNoActivo)}
        >
            <Typography className={classes.tile} variant="h5">¿Esta seguro que desea pasar el alumno a NO ACTIVO?</Typography>
        </ModalDialog>
    </LayoutTest>
    );
}

export default AlumnosBecadosActivos
