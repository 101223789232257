import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux'
// Import Componentes
import Controls from '../../componentes/Forms/Controls'

// Custom hooks
import { useForm } from '../../hooks/useForm'
import FormContainer from '../../componentes/Forms/FormContainer';
import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator';
import { alumnosActivos } from '../../Redux/actions/PdfActions/PdfActions'

const initialFValues = {
    cuentas: []
}

const FormInformesAlumnosActivos = ({ cerrarModal, abrirPdf=()=>{} }) => {
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    const dispatch = useDispatch()

    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Generar Informe "
            accionCancelar={cerrarModal}
            onSubmit={() => {
                dispatch(alumnosActivos(values))
                abrirPdf()
                cerrarModal()
            }}>
            <Grid item xs={12}>
                <Controls.AutocompleteSelect
                    multiple={true}
                    disablePortal={false}
                    promSelectList={getCuentasListSelect()}
                    valueautocomplete={values.cuentas}
                    filtro={'descripcion'}
                    name="cuentas"
                    valorSalida="id"
                    label="Cuentas"
                    onChange={handleChangeForm}
                />
            </Grid>

        </FormContainer>
    )
}
export default FormInformesAlumnosActivos