import React from 'react';
import { Page, Document, StyleSheet, View, pdf, BlobProvider } from '@react-pdf/renderer';

import PDFTable from './PDFTable'
import DatosUcu from './DatosUcu';

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Helvetica',
        fontFamily: 'Times-Roman',
        paddingTop: 10,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'column',
    },
});

const PDFContainer = ({ pdfData, pdfDataConfig }) => {

    return <Document>
        {pdfData.length ? pdfData.map((data, index) =>
            <Page size="A4" style={styles.page} key={`PG${index}`}>
                <DatosUcu data={data} key={`OR${index}`} />
                {/*                 <PDFTable data={data} key={`OR${index}`} />
                <PDFTable data={data} key={`CP${index}`} copia={true} />  */}
            </Page>
        ) : null}
    </Document>
};

export default PDFContainer