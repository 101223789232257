import axios from "axios"
import { getStoredAuthToken, redirigir } from "../utils/authToken"

const cancelToken = axios.CancelToken.source();

const AxiosIntance = axios.create(
    {
        baseURL: 'https://backendaranceles.ucu.edu.ar/api/v1', //https://backendaranceles.ucu.edu.ar/api/v1 //http://localhost:3002/api/v1
        cancelToken: cancelToken.token
    })
AxiosIntance.interceptors.request.use(
    (config) => {
        redirigir()
        const access_token  = getStoredAuthToken() || ''
        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`
        }

        return config

    },
    (error) => {
        return Promise.reject(error);
    }
)


export default AxiosIntance