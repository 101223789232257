
import Login from './Login'

const LoginPage= () => {
  return (
   <div className="login" style={{display:"grid", placeItems:"center", height:'100vh', width:'100%'}}>
      <Login/>
   </div>
   )
 }

export default LoginPage