import { BeneficiosApi } from '../../api/BneficiosApi'

export const ActionTypes = {
    GET_ALL_BENEFICIOS:"GET_ALL_BENEFICIOS"
}

export const getAllBeneficios = () => {
    return new BeneficiosApi()
        .getBeneficios()
        .then(resp=>resp.data)
        .catch(err=>console.log("ERROR GET BENEFICIOS API ", err))
}