import React, { Fragment } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import PDFContainerSaldoDetalles from './PDFContainerSaldoDetalles'

const SaldoDetalles = ({pdfData,pdfDataConfig,height, width}) => {
    return (
        <Fragment>
           <PDFViewer width={width} height={height} className="app" >
                <PDFContainerSaldoDetalles pdfData={pdfData} pdfDataConfig={pdfDataConfig} />
            </PDFViewer>
        </Fragment>
    );
}

export default SaldoDetalles;