import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
//Components
import { Divider, Grid, Typography } from '@material-ui/core';
import ButtonIcon from '../componentes/Buttons/ButtonIcon';
import Header from '../componentes/Header'
import AppBar from './AppBar';
import Badge from '@mui/material/Badge';


const useStyles = makeStyles(() =>
    createStyles({

        layoutContainer: {
            background: '#fff9e0',
            minHeight: '100vh'
        },

        tableContainer: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%'
        },

        tableSize: {
            width: '95%',
            height: 'max-content'
        },

        searchBar: {
            height: '80px',
        },
        table: {
            borderRadius: 5,
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
            marginBottom: '20px'
        },
        modalTable: {
            height: '100%'
        },
        header: {
            height: 'max-content'
        },
        marginTableContainer: {
            padding: '2.5% 0'
        },
        title: {
            background: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        customHeader: {
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
            height: 'max-content',
            margin: '10px 0px 10px 0px',
            padding: '10px',
            borderRadius: '5px'
        }
    }),
);

const LayoutTest = ({ children, buttons = [], searchBar = null, filter = null, appBar = true, header = true, title = "", customHeader = false, customFooter = false }) => {

    const classes = useStyles()

    return (
        <Grid container className={classes.layoutContainer}>
            <Grid className={classes.header} item xs={12} >
                {header && <Header title="Sistema Aranceles" />}
            </Grid>
            <Grid
                container item
                xs={12}
                className={`
                    ${classes.tableContainer} 
                    ${!(buttons.length || searchBar) ? classes.marginTableContainer : ''}
                `} >
                <Grid item className={classes.tableSize}>
                    {customHeader
                        ? <Grid container alignItems="center" xs={12} className={`${classes.customHeader} `} >
                            {customHeader}
                        </Grid>
                        : null}
                    {(buttons.length || searchBar || filter)
                        ? < Grid container alignItems="center" xs={12} className={classes.searchBar}>
                            <Grid container item xs={12} align="center">
                                {/* BOTONES  */}
                                <Grid container item sm={filter ? 4 : 8} xs={5}>
                                    {
                                        buttons.length
                                            ? buttons.map(btn => {
                                                return <Grid item style={{ marginRight: '15px' }} >
                                                    <Badge badgeContent={btn.notification ? btn.notification() : null} color={'warning'}>
                                                        <ButtonIcon
                                                            label={btn.label}
                                                            endIcon={btn.icon}
                                                            onClick={btn.action}
                                                            display={btn.display ? btn.display : ''}
                                                        />
                                                    </Badge>
                                                </Grid>

                                            })
                                            : null
                                    }

                                </Grid>
                                {
                                    filter && <Grid container alignItems="center" sm={4} item xs={4}>
                                        <Grid item>
                                            {filter}
                                        </Grid>
                                    </Grid>
                                }
                                {/* SEARCHBAR  */}
                                <Grid item xs={3} sm={4} container alignItems="center" justifyContent="flex-end">
                                    {searchBar}
                                </Grid>
                            </Grid>

                        </Grid>
                        : null
                    }
                    <Grid item xs={12} className={classes.table}>
                        {appBar ? <AppBar /> : null}
                        {children}
                    </Grid>
                    {customFooter
                        ? <Grid container alignItems="center" xs={12} className={`${classes.customHeader} `}>
                            {customFooter}
                        </Grid>
                        : null}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default LayoutTest