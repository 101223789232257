import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeaderSaldo from './PDFTableHeaderSaldo'
import PDFTableRowSaldo from './PDFTableRowSaldo'
import PDFTableFooterSaldo from './PDFTableFooterSaldo'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTableSaldo = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeaderSaldo />
    <PDFTableRowSaldo items={data} />
    <PDFTableFooterSaldo items={data} />
  </View>
);

export default PDFTableSaldo