import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'

//Api
import { AlumnosSaoApi } from '../../api/AlumnosSao'

//hooks 
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'

// components
import ModalDialog from '../../componentes/Modals/ModalDialog'
import Table from '../../componentes/CustomTable'
import FormAlumnoSao from './FormAlumnoSao'
import Modal from '../../componentes/Modals/Modal'
import SearchBar from '../../componentes/SearchBar/SearchBar'
import LayoutTest from '../../Layout/LayoutTest'


//Actions
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

const AlumnosSao = () => {
  const dispatch = useDispatch()

  //estados para abrir modales
  const [modalVincularAlumnoSao, setModalVincularAlumnoSao] = useState(false)
  const [modalNuevaPersona, setModalNuevaPersona] = useState(false)

  const { dataTable, loading } = useCargarTabla(()=>new AlumnosSaoApi().getNuevosAlumnosSao())
  const {
    filterTable,
    searchText,
    setSearchText,
    setFilter } = useSearchTable((filter, searchText) => new AlumnosSaoApi()
      .getAlumnosSaoWithFilter(filter, searchText))

  const filtrosSearchBar = [
    { key: "nombre", label: "Nombre" },
    { key: "nrodoc", label: "N° Doc" },
]

const buttons = [
  {
    icon:'null',
    action:()=>{},
    label:'',
    display:'none'
  }
]

  const columns = [
    { title: "ID Persona", name: 'idpersona', width: '10%' },
    { title: "Apellido", width: '10%' },
    { title: "Nombre", width: '10%' },
    { title: 'Nro Doc', width: '10%', name: 'nrodoc' },
    {
      title: 'carreras', isArray: true, openModal: true, action: data => {
        dispatch(selectDataAction({
          ...data.datarow,
          carreras: data.datarow.carreras.split(',')[data.indexSelect],
          idalumno: data.datarow.idalumno.split(',')[data.indexSelect]
        }))
        setModalVincularAlumnoSao(!modalVincularAlumnoSao)
      }
    }
  ]
  const actions = [{
    icon:'null',
    action:()=>{}
  }]

  return <LayoutTest
    searchBar={
      <SearchBar
        filtros={filtrosSearchBar}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
      />
    }
    buttons={buttons}
  >
    {
      modalNuevaPersona &&
      <ModalDialog
        title="Crear Nueva Persona"
        active={modalNuevaPersona}
        accionAcpetar={() => alert("Se registro nueva persona")}
        labelButtonSubmit="Si, Registrar Persona"
        closeModal={() => setModalNuevaPersona(!modalNuevaPersona)}
      >
        <Typography style={{ fontWeight: 300 }} variant="h5">{`¿Esta Seguro que desea registrar una nueva persona?`}</Typography>
      </ModalDialog>
    }
    {
      modalVincularAlumnoSao &&
      <Modal
        width={'50vw'}
        closeModal={() => setModalVincularAlumnoSao(!modalVincularAlumnoSao)}
        active={modalVincularAlumnoSao}
      >
        <FormAlumnoSao />
      </Modal>
    }
    <Table
      filterTable={filterTable.length}
      tableData={filterTable.length ? filterTable : dataTable}
      columns={columns}
      actions={actions}
      rowChek={false}
    />
  </LayoutTest>
}

export default AlumnosSao