import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell } from '../../componentes/Cell';

const margin = 10

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 'max-content',
            borderRadius: 10,
            border: 'solid 1px lightGray',
            overflow:'scroll',
            height: '100%',
            margin: margin
        },
        container: {
            height: '30vh',
            marginTop: '2vh',
            minHeight: 'max-content',
            [theme.breakpoints.down("md")]: {
                height: '25 vh'
            }
        },
        containerHeader: {
            height: 'max-content',
            marginLeft: margin
        },
        footer: {
            marginLeft: margin
        }
    }));
const CuentasVinculadas = ({ cuentasVinculadas, idCuenta }) => {

    const classes = useStyles();

    const columns = [
        { title: "id", name: "idCuenta", width: '5%' },
        { title: "Cod. Fac.", name: "codfac", width: '5%' },
        { title: "Cod. Car.",  name:'codcar', width: '5%'},
        { title: "Cuenta", name:'descripcion',  width: '30%' }
    ]

    return (
        <Grid container className={classes.container} >
            {cuentasVinculadas && cuentasVinculadas.length 
                ? <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Typography variant="h5">Cuentas Vinculadas</Typography>
                </Grid>
                <Grid item xs={12} container className={classes.table} >
                    <Grid item xs={12}>
                        <Table >
                            <TableHead >
                                <TableRow>
                                    {
                                        columns.length && columns.map((col, i) =>

                                            <Cell
                                                align={col.align}
                                                variant="head"
                                                width={col.width}
                                                key={i}>
                                                {col.title}
                                            </Cell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    cuentasVinculadas && cuentasVinculadas.length
                                        ? cuentasVinculadas.map((data, index) =>
                                            <TableRow key={index}>
                                                {
                                                    columns.map((key, index) => <Cell
                                                        width={key.width}
                                                        key={index}
                                                    >
                                                        {key.name
                                                            ? data[key.name] || data[key.name.toLowerCase()]
                                                            : data[key.title.toLowerCase()]}
                                                    </Cell>)
                                                }

                                            </TableRow>
                                        )
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            </>
            : <Typography style={{height:"100%", width:"100%", display:"grid", justifyContent:"center"}} variant="h4">{`Esta Persona no tiene cuentas vincualadas`}</Typography>}
        </Grid>
    );
}

export default CuentasVinculadas