import React, { Fragment } from 'react';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer'
import PDFContainer from './PDFContainer'

const PdfRecibosBarrida = ({ pdfData, pdfDataConfig, height, width }) => {

    console.log("a ver que talco", pdfData)
    const doc = <PDFContainer pdfData={pdfData} pdfDataConfig={pdfDataConfig} />

    return (

        <Fragment>
         {
                pdfData && pdfData.length
                    ? <PDFViewer width={width} height={height} className="app" >
                        <PDFContainer pdfData={pdfData} pdfDataConfig={pdfDataConfig} />
                    </PDFViewer>
                    : null
         }
        </Fragment>
    );
}

export default PdfRecibosBarrida;