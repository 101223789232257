import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        fontFamily: 'Times-Roman',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        textAlign: 'center',
        fontStyle: 'light',
        flexGrow: 1,
    },

    textHeader:{
        fontSize:10,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    codigo: {
        width: '10%',
    },
    alumno: {
        width: '35%',
    },
    detalles: {
        width: '35%',
    },
    debeHaber:{
        width: '10%',
    },
  });

  const PDFTableHeader = () => (
    <View style={styles.container} fixed>
        <Text style={{...styles.codigo, ...styles.textHeader}}>CODIGO</Text>
        <Text style={{...styles.alumno, ...styles.textHeader}}>ALUMNO</Text>
        <Text style={{...styles.detalles, ...styles.textHeader}}>DETALLE</Text>
        <Text style={{...styles.debeHaber, ...styles.textHeader}}>DEBE.</Text>
        <Text style={{...styles.debeHaber, ...styles.textHeader}}>HABER.</Text>
    </View>
  );
  
  export default PDFTableHeader