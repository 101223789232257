import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Typography } from '@material-ui/core'

//Api
import { ArancelesApi } from '../../api/ArancelesApi'

//components
import Table from '../../componentes/CustomTable'
import FormAranceles from './FormAranceles'
import Modal from '../../componentes/Modals/Modal'
import ModalDialog from '../../componentes/Modals/ModalDialog'
import SearchBar from '../../componentes/SearchBar/SearchBar'
import LayoutTest from '../../Layout/LayoutTest'

//Actions
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'
import { openModalEdit, openModalRegister } from '../../Redux/actions/modales/ActionCreatorModals';

//hooks 
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'
import useEnviarDatos from '../../hooks/useEnviarDatos';

const Aranceles = () => {

  const { listAranceles } = useSelector((state) => state.ArancelesState)
  const { modalRegister } = useSelector((state) => state.ModalState)
  const { detallesData } = useSelector(state => state.GlobalState)

  

  const [modalPasaMesSiguiente, setModalPasarMesSiguiente] = useState(false)
  const [modalGenerarDebitos, setModalGenerarDebitos] = useState(false)


  const { dataTable, loading, recargarTabla } = useCargarTabla(() => new ArancelesApi().getAllAranceles())
  const {
    filterTable,
    searchText,
    setSearchText,
    setFilter } = useSearchTable((filter, searchText) => new ArancelesApi()
      .getArancelesWithFilter(filter, searchText))

  const { sendData: pasarActualesAlMesSiguiente, loading:loadingActualizarAranceles } = useEnviarDatos({
    action: () => new ArancelesApi().pasarActualesAlMesSiguiente(),
    textSucces: 'Aranceles Actualizados',
    recargarTabla,
    funcSuccess: () => setModalPasarMesSiguiente(!modalPasaMesSiguiente)
  })

  const {sendData:generarDebito,loading:loadingGenerarDebito} = useEnviarDatos({
    action: () => new ArancelesApi().generarDebitos(detallesData.id),
    textSucces: 'Debito Generado',
    recargarTabla,
    funcSuccess: () => setModalGenerarDebitos(!modalGenerarDebitos)
  }) 

  const columns = [
    { title: 'CodFac' },
    { title: 'CodCar' },
    { title: 'Nombre de Cuenta',width:'15%' },
    { title: 'Descripcion' },
    { title: 'Mes' },
    { title: 'Anio' },
    { title: 'Monto' },
    { title: 'Bonificacion' },
    { title: 'Recargo' },
    { title: 'Biblioteca' },
    { title: 'Debita', type: 'boolean' },
    { title: 'debitado', type: 'boolean' },

  ]
  const dispatch = useDispatch()

  const filtrosSearchBar = [
    {
      key: "DESCRIPCION",
      label: "Nombre de Cuenta"
    },
  ]

  const buttons = [
    {
      icon: 'nuevo',
      action: () => dispatch(openModalRegister()),
      label: 'Nuevo Arancel'
    },
    /*     {
          icon: 'imprimir',
          action: () => alert('imprimir'),
          label: 'Imprimir'
        }, */
    {
      icon: 'dobleFlecha',
      action: () => setModalPasarMesSiguiente(!modalPasaMesSiguiente),
      label: 'Pasar Actuales al mes Siguiente'

    }
  ]

  const actions = [
    {
      icon: "editar",
      action: (data) => {
        dispatch(selectDataAction(data))
        dispatch(openModalEdit())
      },
      tooltip:"Editar Aranceles "
    },
    {
      icon:'ctacte',
      action: (data) => {
        dispatch(selectDataAction(data))
        setModalGenerarDebitos(!modalGenerarDebitos)
      },
      tooltip:"Generar Debito"
    }
  ]
  let listaSelect = listAranceles.map(arancels => arancels["NOMBRE DE CUENTA"])

  return <LayoutTest

    searchBar={
      <SearchBar
        filtros={filtrosSearchBar}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
      />
    }
    buttons={buttons}
  >

    <ModalDialog
      title="Pasar Actuales al mes Siguiente"
      loading={loadingActualizarAranceles}
      active={modalPasaMesSiguiente}
      accionAcpetar={() => pasarActualesAlMesSiguiente()}
      labelButtonSubmit="Si, Pasar Actuales al mes Siguiente"
      closeModal={() => setModalPasarMesSiguiente(!modalPasaMesSiguiente)}
    >
      <Typography variant="h6">{`¿Esta seguro que desea pasar los aranceles actuales al mes siguiente ?`}</Typography>

    </ModalDialog>


    <ModalDialog
      title="Generar Debito"
      loading={loadingGenerarDebito}
      active={modalGenerarDebitos}
      accionAcpetar={() => generarDebito()}
      labelButtonSubmit="Si, Generar Debito"
      closeModal={() => setModalGenerarDebitos(!modalGenerarDebitos)}
    >
      <Typography variant="h6">{`¿Esta seguro que que desea debitar el Arancel para la cuenta: ${detallesData['nombre de cuenta']}?`}</Typography>

    </ModalDialog>

    <Modal
      width={'50vw'}
      closeModal={() => dispatch(openModalRegister())}
      active={modalRegister}

    >
      <FormAranceles 
        recargarTabla={recargarTabla} 
        selectList={listaSelect} 
        accionCancelar={() => dispatch(openModalRegister())}
        />
    </Modal>

    <Table
      filterTable={filterTable.length}
      tableData={filterTable.length ? filterTable : dataTable}
      columns={columns}
      actions={actions}
      rowChek={false}
    />
  </LayoutTest>
}

export default Aranceles