import { AlumnosSaoApi } from '../../../api/AlumnosSao';
import { setTable } from "../globalActions/ActionCreatorGlobal";

export const ActionTypes = {
    ADD_ALUMNOS_SAO: "ADD_ALUMNOS_SAO",
    GET_ALL_ALUMNOS_SAO: 'GET_ALL_ALUMNOS_SAO',
    GET_ALUMNOS_SAO_DETAIL: 'GET_ALUMNOS_SAO_DETAIL',
    SELECT_ALUMNOS_SAO: 'SELECT_ALUMNOS_SAO',
    UPDATE_ALUMNOS_SAO: 'UPDATE_ALUMNOS_SAO',
}

export const getNuevosAlumnosSaoList = (listAlumnosSao) => {
    return {
        type: ActionTypes.GET_ALL_ALUMNOS_SAO,
        listAlumnosSao
    }
}

export const getAlumnoSaoDetails = (AlumnosSaoDetail) => {
    return {
        type: ActionTypes.GET_ALUMNOS_SAO_DETAIL,
        AlumnosSaoDetail
    }
}

export const selectAlumno = (selectAlumnosSao) => {
    return {
        type: ActionTypes.SELECT_ALUMNOS_SAO,
        selectAlumnosSao
    }
}

export const updateCuentas = (AlumnosSaoId, AlumnosSao, index) => {
    return {
        type: ActionTypes.UPDATE_ALUMNOS_SAO,
        AlumnosSaoId,
        AlumnosSao,
        index
    }
}

export const getAllNuevosAlunos = () => {
    return (dispatch) => {

        return new AlumnosSaoApi()
            .getNuevosAlumnosSao()
            .then(resp => {
                dispatch(getNuevosAlumnosSaoList(resp.data))
                dispatch(setTable(resp.data))
            })
            .catch(err => console.log(err))
    }
}


export const buscarCuentasPorDniSao = (dniAlumnosSao) => {
    return new AlumnosSaoApi()
        .buscarCuentasPorDniSao(dniAlumnosSao)
        .then(resp=>resp.data)
        .catch(err=> console.log('ERROR BUSCAR CUENTAS POR DNI ALUMNO SAO', err))
} 

export const buscarPersonaByDniAlumnoSao = (dniAlumnosSao) => {
    return new AlumnosSaoApi()
        .buscarPersonaByDniAlumnoSao(dniAlumnosSao)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR BUSCAR PERSONAS DNI ALUMNOS SAO ", err))
}

