
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
// Import Componentes
import Controls from '../Forms/Controls'
// Custom hooks
import { useForm } from '../../hooks/useForm'
import { Button } from '@material-ui/core';

const FormSubirArchivoBanco = ({ onSubmit, cancelAction = () => { } }) => {
    const initialValue = {
        archivoBanco: ''
    }
    const {
        values,
        handleChangeForm,
    } = useForm(initialValue)
    
    return (
        <form
            style={{ paddingTop: 10 }}
            onSubmit={(e) => onSubmit(e, values.archivoBanco)}>
            <Grid container alignContent="center" spacing={2}>
                <Grid item spacing={2} container xs={12}>
                    <Grid item xs={4} >
                        <label>
                            <Button variant="contained" color="secondary" component="span">
                                Cargar Archivo
                            </Button>
                            <input
                                name="archivoBanco"
                                onChange={handleChangeForm}
                                type="file"
                                style={{ display: 'none' }} />
                        </label>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            size={'small'}
                            variant="outlined"
                            fullWidth
                            value={values.archivoBanco}
                            label={"Archivo del banco"}
                        />
                    </Grid>
                </Grid>
                <Grid item justifyContent="flex-end" container spacing={2} xs={12}>
                    <Grid item >
                        <Controls.Button onClick={cancelAction} variant="secondary" text="Cancelar" />
                    </Grid>
                    <Grid item >
                        <Controls.Button variant="primary" type="submit" text="Enviar Archivo" />
                    </Grid>
                </Grid>
            </Grid>
        </form >

    )
}

export default FormSubirArchivoBanco