import React from 'react';
import { useSelector } from 'react-redux';
import { Page, Document, StyleSheet, Text } from '@react-pdf/renderer';

import PDFTable from './PDFTable'

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Helvetica',
        fontFamily: 'Times-Roman',
        paddingTop: 20,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        flexDirection: 'column',
    },
    header: {
        fontSize: 12,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
    },
});



const PDFContainer = ({pdfData, pdfDataConfig}) => {
    console.log("ESTA ES LA CONFIGURACION ",pdfDataConfig)
    return <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <Text style={styles.header} fixed>PLANILLA DE CAJA DIA : {pdfDataConfig}</Text>
                <PDFTable data={pdfData} />
            </Page>
        </Document>
};

export default PDFContainer