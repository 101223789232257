import { Typography } from '@material-ui/core';

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { decodeToken } from '../../utils/authToken';

//actions
import { logout } from '../../Redux/actions/users/userActionCreator'

//Icons
import Icons from '../Icons'

//img UCU_header
const UCUimg = require('../../assets/ucu_header.png');

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            height: 50,
            padding: "0% 3%",
            background: '#FFF',
            color: '#3e3e3c',
            display: "flex",
            alignItems: "center",
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
        },
        headerTitle: {
            flex: 1,
            flexGrow: 1
        },
        containerImg: {
            width: '170px',
            display: 'flex',
            alignItems: 'center'
        },
        img: {
            width: "auto",
            height: 35
        },
        headerUser: {
            display: 'flex',
            width: 'auto',
            justifyContent: 'space-around',
            alignItems: 'center',
        },

        icon: {
            display: 'grid',
            placeItems: 'center',
            cursor: 'pointer'
        },
        userName:{
            margin:'0px 15px'
        }
    })
);

const Header = ({ title }) => {
    const classes = useStyles()

    return (
        <div className={classes.appBar}>
            <div className={classes.containerImg}>
                <img className={classes.img} src={UCUimg.default} />
            </div>
            <Typography className={classes.headerTitle} variant="h5" noWrap>
                {title}
            </Typography>
            <div className={classes.headerUser}>
                <Icons type="user" />
                <Typography variant="subtitle1" className={classes.userName}>
                    {decodeToken('user')}
                </Typography>
                <div className={classes.icon} onClick={() => logout()}>
                    <Icons type="logout" />
                </div>
            </div>
        </div>
    )
}

export default Header