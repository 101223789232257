import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// components
import Table from '../../componentes/CustomTable'
import LayoutTest from '../../Layout/LayoutTest'
import ModalDialog from '../../componentes/Modals/ModalDialog'
import Modal from '../../componentes/Modals/Modal'

//FORMULARIOS
import FormInformesIngresosCyF from './FormInformesIngresosCyF'
import FormInformesAlumnosActivos from './FormInformesAlumnosActivos'
import FormInformesSaldo from './FormInformesSaldo'

//Actions
import { setDataPdfAction } from '../../Redux/actions/PdfActions/PdfActions';

//PDFs
import PDFIngresosFyCDetalles from '../../componentes/Pdf/PdfInformes/informeIngresosFacConDetalles/PDFIngresosFyCDetalles'
import PDFIngresosFyC from '../../componentes/Pdf/PdfInformes/informeIngresosFacSindDetalles/PDFIngresosFyC'
import PDFSResumenSaldo from '../../componentes/Pdf/PdfInformes/InformeSaldosSinDetalles/PDFSResumenSaldo'
import PDFAlumnosActivos from '../../componentes/Pdf/PdfInformes/InformesAlumnosActivos/PDFAlumnosActivos'
import PDFSaldoDetalles from '../../componentes/Pdf/PdfInformes/InformeSaldosConDetalles/PDFSaldoDetalles'

//tablas
import AlumnosBecadosActivos from './AlumnosBecadosActivos'
import AlumnosDadosDeBaja from './AlumnosDadosDeBaja'

const Informes = () => {

  const buttons = [
    {
      icon: 'null',
      action: () => { },
      label: ' ',
      display: 'none'
    }
  ]

  const dispatch = useDispatch()

  const [modalAlumnosActivos, setModalAlumnosActivos] = useState(false)
  const [modalIngresosFyC, setModalIngresosFyC] = useState(false)
  const [modalAlumnosBecActivos, setModalAlumnosBecActivos] = useState(false)
  const [modalAlumnosConFinCarrera, setModalAlumnosConFinCarrera] = useState(false)
  const [modalAlumnosBaja, setModalAlumnosBaja] = useState(false)

  const [modalSaldo, setModalSaldo] = useState(false)

  const [PDFIngresosFycDetalles, setPdfIngresosFycDetalles] = useState(false)
  const [PDFIngresosFyc, setPdfIngresosFyc] = useState(false)
  const [PDFSaldo, setPdfSaldo] = useState(false)
  const [PDFSaldoConDetalles, setPdfSaldoConDetalles] = useState(false)



  const [PDFModalAlumnosActivos, setPdfModalAlumnosActivos] = useState(false)

  const { pdfData, pdfDataConfig } = useSelector(state => state.PdfState)

  const links = [
    /*     {
          label: 'Alumnos con Fin de Carrera',
          action: () => { }
        }, */
    {
      label: 'Alumnos Becados Activos',
      action: () => setModalAlumnosBecActivos(!modalAlumnosBecActivos)
    },
    {
      label: 'Alumnos Activos',
      action: () => setModalAlumnosActivos(!modalAlumnosActivos)
    },
    {
      label: 'Alumnos Dados de Baja',
      action: () => setModalAlumnosBaja(!modalAlumnosBaja)
    },
    {
      label: 'Ingresos por facultad y carrera / detalle de movimientos ',
      action: () => setModalIngresosFyC(!modalIngresosFyC)
    },
    {
      label: 'Resumen de saldo / Detalle de saldo',
      action: () => setModalSaldo(!modalSaldo)
    },
  ]

  return <LayoutTest
    buttons={buttons}
  >
    <ModalDialog
      hiddenButton={true}
      title="Seleccione Cuentas para Buscar Alumnos Activos"
      active={modalAlumnosActivos}
      closeModal={() => setModalAlumnosActivos(!modalAlumnosActivos)}
      openSuccesModal={() => setPdfModalAlumnosActivos(!PDFModalAlumnosActivos)}
    >
      <FormInformesAlumnosActivos
        cerrarModal={() => setModalAlumnosActivos(!modalAlumnosActivos)}
        abrirPdf={() => setPdfModalAlumnosActivos(!PDFModalAlumnosActivos)}
      />
    </ModalDialog>

    {/* PDF RESUMEN DE SALDO */}

    <ModalDialog
      hiddenButton={true}
      title="Resumen de Saldo / Detalles de saldo"
      active={modalSaldo}
      closeModal={() => setModalSaldo(!modalSaldo)}
      openSuccesModal={() => { }}
    >
      <FormInformesSaldo
        closeModal={() => setModalSaldo(!modalSaldo)}
        openPdfResumenSaldo={() => {
          setModalSaldo(!modalSaldo)
          setPdfSaldo(!PDFSaldo)
        }}
        openPdfSaldoConDetalles={() => {
          setModalSaldo(!modalSaldo)
          setPdfSaldoConDetalles(!PDFSaldoConDetalles)

        }}
      />
    </ModalDialog>

    {/* PDF INGRESOS POR FAC. Y CARREARA  */}

    <ModalDialog
      hiddenButton={true}
      title="Ingresos por facultad y carrera"
      active={modalIngresosFyC}
      closeModal={() => setModalIngresosFyC(!modalIngresosFyC)}
    >
      <FormInformesIngresosCyF
        openPdfIngresosFyCDetalles={() => {
          setModalIngresosFyC(!modalIngresosFyC)
          setPdfIngresosFycDetalles(!PDFIngresosFycDetalles)
        }}
        openPdfIngresosFyC={() => {
          setModalIngresosFyC(!modalIngresosFyC)
          setPdfIngresosFyc(!PDFIngresosFyc)

        }}
        cerrarModal={() => setModalIngresosFyC(!modalIngresosFyC)} />

    </ModalDialog>
    {/* MODALES PDF  */}

    {/* PDF RESUMEN DE SALDO ****SIN**** DETALLES */}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setPdfSaldo(!PDFSaldo)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={PDFSaldo}>
      <PDFSResumenSaldo
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />

    </Modal>

    {/* PDF RESUMEN DE SALDO ****CON**** DETALLES */}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setPdfSaldoConDetalles(!PDFSaldoConDetalles)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={PDFSaldoConDetalles}
    >
      <PDFSaldoDetalles
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>

    {/* TABLA ALUMNOS DADOS DE BAJA */}

    <Modal
      width='90vw'
      height='100vh'
      closeModal={() => {
        setModalAlumnosBaja(!modalAlumnosBaja)
      }}
      active={modalAlumnosBaja}
    >
      <AlumnosDadosDeBaja />
    </Modal>


    {/* PDF INGRESOS POR FAC. Y CARREARA ***CON*** DETALLE DE MOV. */}
    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setPdfIngresosFycDetalles(!PDFIngresosFycDetalles)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={PDFIngresosFycDetalles}
    >
      <PDFIngresosFyCDetalles
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>

    {/* PDF INGRESOS POR FAC. Y CARREARA ***SIN*** DETALLES DE MOV. */}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setPdfIngresosFyc(!PDFIngresosFyc)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={PDFIngresosFyc}
    >

      <PDFIngresosFyC
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>

    {/* PDF ALUMNOS . */}

    {console.log("ESTOS SON LOS DATOS PARA GENERAR EL PDF", pdfData)}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setPdfModalAlumnosActivos(!PDFModalAlumnosActivos)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={PDFModalAlumnosActivos}
    >
      <PDFAlumnosActivos
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>

    {/* PDF TABLA ALUMNOS BECADOS ACTIVOS */}

    <Modal
      width='90vw'
      height='100vh'
      closeModal={() => {
        setModalAlumnosBecActivos(!modalAlumnosBecActivos)
      }}
      active={modalAlumnosBecActivos}
    >
      <AlumnosBecadosActivos />
    </Modal>

    <Table
      links={links}
      tableData={[]}
      columns={[]}
      rowChek={false}
    />
  </LayoutTest >
}

export default Informes