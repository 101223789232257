import { Grid } from '@material-ui/core';

// Import Componentes
import Controls from '../Forms/Controls'
import FormContainer from '../Forms/FormContainer';

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';

//acciones
import { getTiposPlanesPago,crearNuevoPlan } from '../../Redux/actions/planesPagoAction'

import { PlanesPagoApi } from '../../api/PlanesPagoApi';

const FormPlanesPagos = ({ width = '', idCtaPersona, recargarTabla=()=>{} }) => {

    const initialValue = {
        idcuentapersona:idCtaPersona,
        montototal: '',
        cantcuotas: '',
        idtipoplan: '',
        fechainicio: '',
    }
    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialValue, true);

    console.log("valores iniciales", initialValue, "valores del formulario", values)

    const { formSubmit } = useSubmit(new PlanesPagoApi().crearNuevoPlan,values,recargarTabla)


    // funcion para controlar la cantidad de cuotas que debe ser mayor que 1 y menor que 10

    const limiteCantCuotas = (e) => {
        let cant =  e.target.value
        let modCant = cant > 10 
            ? 10 
            : cant<1 
                ? 1 
                : cant  

        setValues({...values, 
            cantcuotas : modCant
        }) 
    }

    return (
        <FormContainer
            width={width}
            resetForm={resetForm}
            LabelButtonSubmit="Crear Nuevo Plan"
            alignContent="center"
            title="Crear Plan de pago/moratoria"
            ButtonSubmit="Crear plan"
            onSubmit={formSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Controls.Input
                        required
                        inputProps={{ style: { fontSize: 14 } }}
                        name="montototal"
                        value={values.montototal}
                        label="Monto Total"
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        required
                        type="number"
                        inputProps={{ style: { fontSize: 14 } }}
                        name="cantcuotas"
                        label="Cant. de Cuotas"
                        inputProps={{
                            min: "1", max: "10"
                          }}
                        onBlur={limiteCantCuotas}
                        value={values.cantcuotas}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.AutocompleteSelect
                        required
                        promSelectList={getTiposPlanesPago()}
                        name="idtipoplan"
                        label="Tipo de Plan"
                        valorSalida="id"
                        filtro='descripcion'
                        valueautocomplete={values.idtipoplan}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.DateField
                        required
                        name="fechainicio"
                        value={values.fechainicio}
                        onChange={handleChangeForm}
                        title="Fecha de Inicio"
                    />
                </Grid>
            </Grid>
        </FormContainer >

    )
}

export default FormPlanesPagos