import { Route, Redirect } from 'react-router-dom'
import { getStoredAuthToken, tokenIsExpired } from '../../utils/authToken'

import PropTypes from 'prop-types';

const propTypes  = {
    children: PropTypes.element,
    path: PropTypes.string,
    exact: PropTypes.bool
}
const AuthRoute = ( { children, exact, path, ...other }) => {

  if (!getStoredAuthToken()) return <Redirect to="/" />

  return (
    <Route
      exact={exact}
      path={path}
      {...other}
      >
      {!tokenIsExpired() ? [children] : <Redirect to="/" />}
    </Route>
  )
}

AuthRoute.propTypes = propTypes

export default AuthRoute