import { BarridasApi } from '../../../api/BarridasApi';
import { setTable } from '../globalActions/ActionCreatorGlobal';

export const ActionTypes = {
    GET_ALL_BARRIDAS: 'GET_ALL_BARRIDAS',
    ADD_BARRIDAS: 'ADD_BARRIDAS',
    GET_BARRIDAS_DETAIL: 'GET_BARRIDAS_DETAIL',
    SELECT_BARRIDAS: 'SELECT_BARRIDAS',
    UPDATE_BARRIDAS: 'UPDATE_BARRIDAS',
    UPDATE_FILE_BARRIDA: 'UPDATE_FILE_BARRIDA'
}

export const updateFileBarrida = (idBarrida, tipoArchivo, nombreArchivo) => {
    return {
        type: ActionTypes.UPDATE_FILE_BARRIDA,
        idBarrida,
        tipoArchivo,
        nombreArchivo
    }
}

export const getBarridasListAction = (listBarridas) => {
    return {
        type: ActionTypes.GET_ALL_BARRIDAS,
        listBarridas
    }
}

export const addBarridasAction = (barrida) => {
    return {
        type: ActionTypes.ADD_BARRIDAS,
        barrida
    }
}

export const getBarridasDetailsAction = (barridasDetails) => {
    return {
        type: ActionTypes.GET_BARRIDAS_DETAIL,
        barridasDetails
    }
}

export const updateBarridaAction = (idBarrida, newBarrida) => {
    return {
        type: ActionTypes.UPDATE_BARRIDAS,
        idBarrida,
        newBarrida
    }
}

export const cargarListaCuentasFalsas = (cuentas) => {
    return getBarridasListAction(cuentas)
}

export const getAllBarridas = () => {
    return new BarridasApi()
        .getAllBarridas()
        .then(resp => resp.data)
        .catch(err => console.log(err))
}

export const addBarrida = (cuenta) => {
    return new BarridasApi()
        .addBarridas(cuenta)
        .then(resp => getBarridasListAction(resp.data))
        .catch(err => console.log("ERROR BARRIDAS ", err))
}


export const getListaGruposBarrida = () => {
    return new BarridasApi()
        .getListaGruposBarrida()
        .then(resp => resp.data)
        .catch(err => console.log("ERROR LISTA GRUPO BARRIDA: ", err))
}

export const updateBarrida = (newBarrida) => {
    return new BarridasApi()
        .updateBarrida(newBarrida)
        .then(resp => updateBarridaAction(newBarrida.id, newBarrida))
        .catch(err => console.log("ERROR ACTUALIZAR BARRIDA ", err))
}


export const generarArchivoBanco = (idBarrida) => {
    return new BarridasApi()
        .createFileBank(idBarrida)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR BARRIDAS API - CREAR ARCHIVO BANCO ", err))
}

export const subirArchivoBanco = (idBarrida, archivoBanco) => {
    return new BarridasApi()
        .subirArchivo(idBarrida, archivoBanco)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR SUBIR ARCHIVO BANCO ", err))

}

export const getAcreditacionesBarrida = (idBarrida) => {
    return new BarridasApi()
        .getAcreditacionesBarrida(idBarrida)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR VER ACREDITACIONES BARRIDA", err))
}


export const getListadoDeRechazos = (idBarrida) => {
    return new BarridasApi()
        .getListadoDeRechazos(idBarrida)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR VER INFORME DE RECHAZOS ", err))

}

export const pagaronMesActualyNoAnterior = (idBarrida) => {
    return new BarridasApi()
        .pagaronMesActualyNoAnterior(idBarrida)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR PAGARON MES ACTUAL ", err))
}

export const pagaronMesAnterioryNoActual = (idBarrida) => {
    return new BarridasApi()
        .pagaronMesAnterioryNoActual(idBarrida)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR PAGARON MES ANTERIOR ", err))
}