import { PlanesPagoApi } from '../../api/PlanesPagoApi'

export const ActionTypes = {
    GET_ALL_PLANES_PAGO: "GET_ALL_PLANES_PAGO"
}

export const getTiposPlanesPago = () => {
    return new PlanesPagoApi()
        .getTiposPlanesPago()
        .then(resp => resp.data)
        .catch(err => console.log("ERROR GET TIPOS PLANES PAGO API ", err))
}

export const getPlanesPagosByIdCtaPersona = (idCuentaPersona) => {
    return new PlanesPagoApi()
        .getPlanesPagosByIdCtaPersona(idCuentaPersona)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR GET ALL PLANES PAGO API ", err))
}

export const getDetallesPlanesPago = (idPlan) => {
    return new PlanesPagoApi()
        .getDetallesPlanesPago(idPlan)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR GET ALL PLANES PAGO API ", err))
}

export const crearNuevoPlan = (nuevoPlan) => {
    return dispatch => {
        return new PlanesPagoApi()
            .crearNuevoPlan(nuevoPlan)
            .then(resp => console.log("RESPUESTA AL CREAR UN NUEVO PLAN", resp.data))
            .catch(err => console.log("ERROR AL CREAR UN NUEVO PLAN", err))
    }
}