import { CajaApi } from "../../../api/CajaApi"
import { InformesApi } from "../../../api/InformesApi"

import { setLoading } from "../globalActions/ActionCreatorGlobal"

const ActionTypes = {
    SET_DATA_PDF: "SET_DATA_PDF",
}

export const setDataPdfAction = ({ pdfData, pdfDataConfig }) => {
    return {
        type: ActionTypes.SET_DATA_PDF,
        pdfData,
        pdfDataConfig,
    }
}

export const setDataPdfArqueoCaja = (data, foo) => {
    return dispatch => {
        return new CajaApi()
            .getArqueoDeCaja(data)
            .then(resp => {
                dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: data.fecha }))
                //funcion que paso en el formulario para abrir el modal del pdf, 
                //una vez resuelta la promesa 
                foo()
            })
            .catch(err => console.log("ERROR AL OBTENER LOS DATOS PARA EL ARQUEO DE CAJA ", err))
    }
}


export const setDataPdfRecibosBarrida = (barrida, foo) => {
    console.log("DATOS DE LA BARRIDA ", barrida)

    return dispatch => {
        dispatch(setLoading())
        return new CajaApi()
            .movimientosCajaByIdBarrida(barrida.id)
            .then(resp => {
                dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: barrida }))
                //funcion que paso en el formulario para abrir el modal del pdf, 
                //una vez resuelta la promesa 
                dispatch(setLoading())
                foo()
            })
            .catch(err => console.log("ERROR AL OBTENER DATOS DE MOV. DE LA BARRIDA ", err))
    }
}

export const getMovCajaByID = (datosMov, foo) => {
    return dispatch => {
        dispatch(setLoading())
        console.log("DATOS DEL MOVIMIENTO EN LA ACCION ", datosMov)
        return (datosMov.idtipofactura != 4 
            ? new CajaApi()
            .getMovCajaByID(datosMov.id)
            : new CajaApi().getMovFacturaC(datosMov.id))
            .then(resp => {

                console.log("RESPUESTA ", resp)
                dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
                dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: {} }))
                dispatch(setLoading())
                foo()
            })
            .catch(err => console.log("ERROR AL IMPRIMIR MOVIEMINTO DE CAJA ", err))
    }
}

export const ingresoFacultadCarreraDetalles = (pdfDataSearch, foo) => {
    return dispatch => {
        console.log("ESTA ES LA DATA ", pdfDataSearch)
        dispatch(setLoading())
        return pdfDataSearch.detalleMov
            ? new InformesApi()
                .ingresoFacultadCarreraDetalles(pdfDataSearch)
                .then(resp => {
                    console.log("ESTA ES LA RESPUESTA ", resp)
                    dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
                    dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: pdfDataSearch }))
                    dispatch(setLoading())
                    foo()
                })
                .catch(err => console.log("ERROR AL IMPRIMIR INFORME ", err))
                .finally()
            : new InformesApi()
                .ingresoFacultadCarrera(pdfDataSearch)
                .then(resp => {
                    console.log("ESTA ES LA RESPUESTA ", resp)
                    dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
                    dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: pdfDataSearch }))
                    dispatch(setLoading())
                    foo()
                })
                .catch(err => console.log("ERROR AL IMPRIMIR INFORME ", err))
                .finally()
    }
}


export const resumenDeSaldo = (pdfDataSearch,foo)=> {
    return dispatch => {
        dispatch(setLoading())
        return new InformesApi()
            .resumenDeSaldo(pdfDataSearch)
            .then(resp=>{
                dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
                dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: pdfDataSearch }))
                dispatch(setLoading())
                foo()
            })
            .catch(err=>console.log('ERROR AL GENERAR INFORME',err))
    }
} 

export const alumnosActivos = (pdfDataSearch,foo=()=>{}) => {
    return dispatch => {
        dispatch(setLoading())
        return new InformesApi()
        .alumnosActivos(pdfDataSearch)
        .then(resp=> {
            console.log("ESTA ES LA RESPUESTA ", resp)
            dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
            dispatch(setDataPdfAction({ pdfData: resp.data, pdfDataConfig: {} }))
            dispatch(setLoading())
            foo()
        })
    }
} 


export { ActionTypes }