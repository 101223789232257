import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    carrera: {
        width: '50%',
    },
    cantidad: {
        width: '30%',
    },
    saldo: {
        width: '20%',
    },

    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    }
});


const PDFTableRowSaldo = ({ items }) => {
    let rows = []

    items.forEach((fila, indx) => {
        rows.push(
            <View style={styles.row}>
                <Text style={{ ...styles.carrera, ...styles.text }}>{fila.facultad}</Text>
                <Text style={{ ...styles.cantidad, ...styles.text }}>{fila.Cantidad}</Text>
                <Text style={{ ...styles.saldo, ...styles.text }}>{fila.saldo}</Text>
            </View>)
    })

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRowSaldo