import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell, CellAction } from '../../../componentes/Cell';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@mui/material/Tooltip';

import Icons from '../../../componentes/Icons';

import ModalDialog from '../../../componentes/Modals/ModalDialog';

import IconButton from "@material-ui/core/IconButton";
import LayoutTest from '../../../Layout/LayoutTest';
import { useDispatch, useSelector } from 'react-redux'

//formulario
import FormAnotaciones from './FormAnotaciones'

//api
import { Anotaciones_cpApi } from '../../../api/Anotaciones_cpApi';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';
import useEnviarDatos from '../../../hooks/useEnviarDatos'


const width = '90vw'

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650,
            borderRadius: 5,
            height: 'max-content',
        },
        tableContainer: {
            padding: 10,
            maxHeight: '78vh',
            borderRadius: 5,
            overflowY: 'hidden',
            [theme.breakpoints.down("md")]: {
                maxHeight: '70vh'
            },
            display: 'flex',
            alignItems: 'start',
            overflowY: 'scroll',

        },
        container: {
            height: '100%',
            minHeight: 'max-content',
            width: width,
            [theme.breakpoints.down("md")]: {
                height: '92vh'
            },

        },
        rowHeader: {
            background: '#fff',
            width: "100%",
            height: 'max-content'
        },
        cellHeader: {
            color: '#8cbaff',
            fontSize: '16px'
        },
        containerHeader: {
            height: '15%',
            [theme.breakpoints.down("md")]: {
                maxHeight: '10%'
            },
            display: 'grid',
            placeItems: 'center',
            width: '100%'
        },

        header: {
            padding: '10px',
            height: '60%',
            width: '100%',
            borderRadius: 5,
        },
        paginationTable: {
            color: '#6E6893',
            height: 35,
        },
        footer: {
            height: 35
        },
    }));

const AnotacionesCp = ({ idCuentaPersona, nombrePersona, cuenta }) => {

    const classes = useStyles();
    const dispatch = useDispatch()

    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new Anotaciones_cpApi().getAnotaciones(idCuentaPersona))

    const [modalEliminarNota, setModalEliminarNota] = useState(false)
    const [modalAgregarNota, setModalAgregarNota] = useState(false)
    const [prevNota, setPrevNota] = useState({})
    const [dataEdit, setDataEdit] = useState({ ...prevNota })

    const cargarPrev = (data) => {
        setDataEdit({
            ...dataEdit,
            ...data
        })
    }

    const columns = [
        { title: "ID", width: '10%' },
        { title: "Detalle", width: '90%' },
    ]

    const buttons = [
        {
            icon: 'agregar',
            action: () => {
                setModalAgregarNota(!modalAgregarNota)
                setDataEdit({})
            },
            label: 'Agregar Nota',
        },
    ]


    const actions = [
        {
            icon: "editar",
            action: (data) => {
                cargarPrev(data);
                setModalAgregarNota(!modalAgregarNota)
            },
            tooltip: "Editar Nota"
        },
        {
            icon: 'borrar',
            action: (data) =>{ 
                cargarPrev(data);
                setModalEliminarNota(!modalEliminarNota)},
            tooltip: "Borrar Nota"
        }
    ]

    const { sendData:eliminarNota } = useEnviarDatos({
        action: new Anotaciones_cpApi().borrarNota,
        textSucces: 'Nota Eliminada',
        recargarTabla,
        funcSuccess: () => setModalEliminarNota(!modalEliminarNota)
    })

    return (<LayoutTest
        buttons={buttons}
        header={false}
        appBar={false}
        customHeader={
            <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Typography className={classes.tile} variant="h5">{`${nombrePersona}`}</Typography>
                    <Typography className={classes.tile} variant="h5">{`Notas de la cuenta ${cuenta}`}</Typography>
                </Grid>
            </>
        }
    >
        <Grid item xs={12} container alignItems="flex-start" className={classes.tableContainer}>
            <Grid item xs={12}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead className={classes.rowHeader}>
                        <TableRow>
                            {
                                columns.length && columns.map((col, i) =>

                                    <TableCell
                                        className={classes.cellHeader}
                                        align={col.align}
                                        variant="head"
                                        width={col.width}
                                        key={i}>
                                        {col.title}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.length
                            ? dataTable.map((data, index) =>
                                <TableRow key={index}>
                                    {
                                        columns.map(key => {
                                            return key.title === " " ? null :
                                                <Cell
                                                    align={key.align}
                                                    size={'medium'}
                                                    allRowData={data}
                                                    columna={key.title}
                                                    openModal={() => key.openModal && (() => { })}
                                                    boolean={key.boolean}
                                                    isArray={key.isArray}
                                                    width={key.width}
                                                    action={key.action} >
                                                    {
                                                        key.type === "boolean"
                                                            ? <Icons type={key.name
                                                                ? !!data[key.name] || !!data[key.name.toLowerCase()]
                                                                : !!data[key.title.toLowerCase()]} />
                                                            : key.name
                                                                ? data[key.name] || data[key.name.toLowerCase()]
                                                                : data[key.title.toLowerCase()]
                                                    }
                                                </Cell>
                                        })}

                                    <CellAction align='right'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                            {actions.map((accion, index) =>
                                                <Tooltip title={accion.tooltip ? accion.tooltip : ''} followCursor>
                                                    <IconButton
                                                        key={index}
                                                        onClick={accion.action && (() => accion.action(data))}
                                                    >
                                                        <Icons type={accion.icon} />
                                                    </IconButton>
                                                </Tooltip>

                                            )
                                            }
                                        </div>
                                    </CellAction>
                                </TableRow>)
                            : <TableRow style={{ height: 54.5 }}>
                                <TableCell colSpan={20}>
                                    <Typography variant="h6">No se encontraron Notas</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>

        </Grid>
        <ModalDialog
            hiddenButton
            title={Object.entries(dataEdit).length ? "Actualizar Nota" : "Agregar Nota"}
            active={modalAgregarNota}
            closeModal={() => setModalAgregarNota(!modalAgregarNota)}
        >
            <FormAnotaciones
                accionCancelar={() => setModalAgregarNota(!modalAgregarNota)}
                data={idCuentaPersona}
                width={'100%'}
                dataEdit={dataEdit}
                recargarTabla={() => recargarTabla()}
                funcSuccess={() => setModalAgregarNota(!modalAgregarNota)}
            />
        </ModalDialog>

        <ModalDialog
            title={"Eliminar Nota "}
            labelButtonSubmit="Si, Eliminar Nota"
            accionAcpetar={() => eliminarNota(dataEdit.id)}
            active={modalEliminarNota}
            closeModal={() => setModalEliminarNota(!modalEliminarNota)}
        >
            <Typography className={classes.tile} variant="h5">Esta seguro que desea eliminar la nota ?</Typography>
        </ModalDialog>
    </LayoutTest>
    );
}

export default AnotacionesCp
