import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Import Componentes
import Controls from '../../componentes/Forms/Controls'

// Custom hooks
import { useForm } from '../../hooks/useForm'
import FormContainer from '../../componentes/Forms/FormContainer';

import { ingresoFacultadCarreraDetalles } from '../../Redux/actions/PdfActions/PdfActions';

const initialFValues = {
    fechaDesde: '',
    fechaHasta: '',
    concepto: '',
    codFac: '',
    codCar: '',
    detalleMov: false
}

const FormInformesIngresosCyF = ({
    openPdfIngresosFyC = () => {},
    openPdfIngresosFyCDetalles = () => {},
    cerrarModal = () => {} }
    ) => {

    const dispatch = useDispatch()
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    console.log("VALOR DEL FORMULARIO ", values)


    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Generar Informe"
            accionCancelar={cerrarModal}
            onSubmit={() => {
                dispatch(ingresoFacultadCarreraDetalles(values, () => { }))
                values.detalleMov 
                ? openPdfIngresosFyCDetalles()
                : openPdfIngresosFyC()
            
            }}>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechaDesde"
                    value={values.fechaDesde}
                    onChange={handleChangeForm}
                    title="Desde"
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechaHasta"
                    value={values.fechaHasta}
                    onChange={handleChangeForm}
                    title="Hasta"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Input
                    name="concepto"
                    value={values.concepto}
                    onChange={handleChangeForm}
                    label="Concepto"
                />
            </Grid>
            <Grid xs={6} item>
                <Controls.Input
                    name="codFac"
                    value={values.codFac}
                    onChange={handleChangeForm}
                    label="Codigo Facultad"
                />
            </Grid>
            <Grid xs={6} item>
                <Controls.Input
                    name="codCar"
                    value={values.codCar}
                    onChange={handleChangeForm}
                    label="Codigo Facultad"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Checkbox
                    name="detalleMov"
                    vale={values.detalleMov}
                    onChange={handleChangeForm}
                    label="Con detalles de Movimiento"
                />
            </Grid>
        </FormContainer>
    )
}
export default FormInformesIngresosCyF