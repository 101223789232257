import React from 'react';
import { useSelector } from 'react-redux';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import { agruparFilasPorId } from './funciones/index'
import DatosUcu from '../pdfRecibo/DatosUcu';

import PDFTable from './PDFTable'

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Helvetica',
        fontFamily: 'Times-Roman',
        paddingTop: 10,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'column',
    },
});



const PDFContainer = ({ pdfData, pdfDataConfig }) => {

    console.log("filas agrupadas ", pdfData)

    return <Document>
        {pdfData.length ? pdfData.map((data, index) =>
            <Page size="A4" style={styles.page} key={`PG${index}`}>
                {/* <PDFTable data={data} key={`OR${index}`} />
                <PDFTable data={data} key={`CP${index}`} copia={true} /> */}
                <DatosUcu data={data} key={`OR${index}`} />
            </Page>
        ) : null}
    </Document>
};

export default PDFContainer