export const agruparFilasPorId = (items) => {

    const objItems = items.reduce((acc, obj) => {
        console.log("OBJETO ",obj)
        if (!acc[obj.id]) {
            acc[obj.id] = {
                codigo: obj.codigo,
                nombre: obj.nombre,
                concepto: obj.descripcion,
                recibo: obj.recibo,
                ARANCEL: 0,
                'BONIFIC.': 0,
                INTERESES: 0,
                BIBLIOTEC: 0,
                MORATORIA: 0,
                total: 0
            }
        }
        acc[obj.id][obj.descri] = obj.valor
        // CAMBIO PARA QUE LA SUMATORIA NO SE HAGA SINO QUE SOLAMENTE OBTENGA EL VALOR DEL RECIBO
                if (!acc[obj.id].total) {
            acc[obj.id].total = obj.monto;
        }//acc[obj.id].total = acc[obj.id].total + obj.valor
        return acc
    }, {})

    const filas = Object.keys(objItems).map((item) => {
        return { ...objItems[item]}
    })

    return filas
}

export const agruparPorCodigo = (items) => {
    let obj = {}
    items.forEach(item => {
        if (!Array.isArray(obj[item.codigo])) {
            obj[item.codigo] = [item]
        } else {
            obj[item.codigo].push(item)
        }
    })
    let arrayAgrupadosPorCodigo = []
    Object.values(obj).forEach(item => {
        arrayAgrupadosPorCodigo.push([
            ...item, {
                subTotalArancel: item.reduce((acc, curr) => acc + curr["ARANCEL"], 0),
                subTotalBonificacion: item.reduce((acc, curr) => acc + curr["BONIFIC."], 0),
                subTotalIntereses: item.reduce((acc, curr) => acc + curr["INTERESES"], 0),
                subTotalBiblioteca: item.reduce((acc, curr) => acc + curr["BIBLIOTEC"], 0),
                subTotalMoratoria: item.reduce((acc, curr) => acc + curr["MORATORIA"], 0),
                subTotal: item.reduce((acc, curr) => acc + curr.total, 0)
            }
        ])
    })
    return arrayAgrupadosPorCodigo
}

export const calcularTotales = (items) => {
    return {
        totalAranceles: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalArancel"], 0),
        totalBonificacion: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBonificacion"], 0),
        totalIntereses: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalIntereses"], 0),
        totalBiblioteca: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBiblioteca"], 0),
        totalMoratoria: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalMoratoria"], 0),
        total: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotal"], 0)
    }
}
