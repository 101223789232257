
import { Grid } from '@material-ui/core';
import FormContainer from '../../componentes/Forms/FormContainer';
// Import Componentes
import Controls from '../../componentes/Forms/Controls'

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import useIsMounted from '../../hooks/useIsMounted';

import { BarridasApi } from '../../api/BarridasApi';
//Actions
import { getListaGruposBarrida } from '../../Redux/actions/barridas/BarridasActionCreator';
import "moment/locale/es"

const initialFValues = {
  descripcion: '',
  generado: '',
  recibido: '',
  nrobarrida: '',
  idGrupoBarrida: '',
  fechaBarrida: '',
  fechaRecibos: '',
  fechaAcreditacion: '',
  fechaEntrega: '',
  enviado: ''
}

const FormBarrida = ({ recargarTabla,accionCancelar }) => {
  const {
    values,
    setValues,
    handleChangeForm,
    resetForm,
    formEdit //false o true
  } = useForm(initialFValues);

  const { formSubmit, loading } = useSubmit(
    formEdit ? new BarridasApi().updateBarrida : new BarridasApi().addBarridas, 
    values, 
    recargarTabla,
    formEdit ? 'Barrida Actualizada' : 'Barrida Creada',
    )

    console.log("values ", values)

  return (
    <FormContainer
      accionCancelar={accionCancelar}
      width="45vw"
      LabelButtonSubmit={formEdit ? "Actualizar Barrida" : "Generar Barrida"}
      resetForm={resetForm}
      title={formEdit ? "Actualizar Barrida" : "Generar Barrida"}
      onSubmit={(e) => {
        formSubmit(e)
      }
      }>
      <Grid container item xs={12}>
        <Controls.Input
          name="descripcion"
          label="Descripcion"
          value={values.descripcion}
          onChange={handleChangeForm}
        />
      </Grid>

      <Grid container item xs={12}>
        <Controls.Input
          label="Archivo Generado"
          name="generado"
          value={values.generado}
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid container item xs={12}>
        <Controls.Input
          label="Archivo Recibido"
          name="recibido"
          value={values.recibido}
          onChange={handleChangeForm}
        />
      </Grid>

      <Grid container item xs={12}>
        <Controls.Input
          label="Nro. Barrida"
          name="nrobarrida"
          required={true}
          value={values.nrobarrida}
          onChange={handleChangeForm}
        />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Controls.AutocompleteSelect
            label="Grupo Barrida"
            name="idGrupoBarrida"
            required={true}
            valorSalida="id"
            filtro="descripcion"
            valueautocomplete={values.idGrupoBarrida}
            promSelectList={getListaGruposBarrida()}
            onChange={handleChangeForm}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={12}>
          <Controls.DateField
            name="fechaEntrega"
            value={values.fechaEntrega}
            onChange={handleChangeForm}
            title="Fecha Entrega"
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={12}>
          <Controls.DateField
            name="fechaBarrida"
            value={values.fechaBarrida}
            onChange={handleChangeForm}
            title="Fecha de Barrida"
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={12}>
          <Controls.DateField
            name="fechaAcreditacion"
            value={values.fechaAcreditacion}
            onChange={handleChangeForm}
            title="Fecha de Acreditacion"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={12}>
          <Controls.DateField
            name="fechaRecibos"
            value={values.fechaRecibos}
            onChange={handleChangeForm}
            title="Fecha de recibo"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Controls.Checkbox
          label="Enviado"
          name="enviado"
          value={values.enviado}
          onChange={handleChangeForm}
        />
      </Grid>
    </FormContainer>
  )
}
export default FormBarrida