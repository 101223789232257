import { useEffect, useState } from 'react'
import { IconButton, InputBase, Paper, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            background: "#FFF",
            borderRadius: 5,
            boxShadow: "none",
            maxWidth: "25%",
            minWidth: 300,
            height: 40,
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
        },
        input: {
            color: "#3e3e3c",
            marginLeft: theme.spacing(1),
            flex: 1,
            '& ::placeholder': {
                fontSize: 12
            },
            fontSize: 14
        },
        select: {
            color: "#3e3e3c",
            marginRight:'0px !important',
            padding: 5,
            fontSize: 12,
            "& :focus": {
                background: '#FFF'
            }
        },
        iconButton: {
            color: "#3e3e3c",
            padding: 5,
            border: "none"
        },
        divider: {
            height: 26,
            margin: 2,
        },
    }),
);


const SearchBar = ({ filtros, searchText = '', setSearchText, setFilter }) => {
    const classes = useStyles();
    const [filter, setFilterSearchBar] = useState(filtros[0].key)
    const [inputText, setInputText] = useState('')

    const handleChangeFilter = ({ target }) => {
        setFilterSearchBar(target.value)
        setFilter(target.value)
    }

    useEffect(() => {
        filtros.length && setFilter(filtros[0].key)
    }, [])

    return (

        <Paper className={classes.root}>
            <Select
                onChange={handleChangeFilter}
                disableUnderline
                value={filter}
                classes={{ root: classes.select }}
            >
                {
                    filtros.length && filtros.map((data, index) => {
                        return (
                            <MenuItem key={index} value={data.key}>{data.label}</MenuItem>
                        )
                    })
                }
            </Select>
            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
                onKeyDown={(e)=> {
                    if(e.keyCode==13)  setSearchText(inputText)
                } }
                className={classes.input}
                placeholder={`Buscar por ${filter.toUpperCase()}`}
                onChange={(e) => {
                    setInputText(e.target.value)
                    if(e.target.value=='') setSearchText('') 
                }}
                value={inputText}
            />
            <IconButton onClick={()=>setSearchText(inputText)} className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>

    )
}

export default SearchBar