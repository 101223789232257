import { useState, useEffect } from 'react'

const useSearchTable = (asyncSearchFunction) => {

    const [searchText, setSearchText] = useState('')
    const [filterTable, setFilterTable] = useState([])
    const [filter, setFilter] = useState('')


    const searchFunction = () => {
        console.log("SEARCH TEXT ", searchText)
        if (searchText.length > 1) {

            asyncSearchFunction(filter, searchText)
                .then(resp => {
                    setFilterTable(resp.data)
                })
                .catch(err => console.log("ERROR EN USE SEARCH BAR ", err))
        }else{
            setFilterTable([])
        }
    }

    useEffect(() => {
        searchFunction()

    }, [searchText, filter])

    return {
        filterTable,
        searchText,
        setSearchText,
        setFilter
    }

}

export default useSearchTable