import { PersonaApi } from '../../../api/PersonaApi';
//otras acciones

export const ActionTypes = {
    ADD_PERSONA: "ADD_PERSONA",
    GET_PERSONA_LIST: 'GET_PERSONA_LIST',
    GET_PERSONA_DETAILS: 'GET_PERSONA_DETAILS',
    SELECT_PERSONA: 'SELECT_PERSONA',
    SELECT_ALL_PERSONA: "SELECT_ALL_PERSONA",
    DELETE_PERSONA: 'DELETE_PERSONA',
    UPDATE_PERSONA: 'UPDATE_PERSONA',
    CUENTAS_BY_PERSONA: "CUENTAS_BY_PERSONA"
}


const addPersonAction = (person) => {
    return {
        type: ActionTypes.ADD_PERSONA,
        person
    }
}
export const getPersonList = (listPerson) => {
    return {
        type: ActionTypes.GET_PERSONA_LIST,
        listPerson
    }
}

export const getPersonDetails = (personDetails) => {
    return {
        type: ActionTypes.GET_PERSONA_DETAILS,
        personDetails
    }
}

export const selectPerson = (selectPerson) => {
    return {
        type: ActionTypes.SELECT_PERSONA,
        selectPerson
    }
}

export const selectAllPerson = () => {
    return {
        type: ActionTypes.SELECT_ALL_PERSONA,
    }
}

export const deletePerson = () => {
    return {
        type: ActionTypes.DELETE_PERSONA,
    }
}

export const updatePerson = (idPersona, newPersona, index) => {
    return {
        type: ActionTypes.UPDATE_PERSONA,
        idPersona,
        newPersona,
        index
    }
}

//accion para crear personas con datos del sao
export const nuevaPersonaBySao = (persona) => {
    return new PersonaApi()
        .nuevaPersonaBySao(persona)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR AL CREAR UNA PERSONA SAO", err))
}
//accion para crear personas 
export const nuevaPersona = (persona) => {
    return new PersonaApi()
        .nuevaPersona(persona)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR AL CREAR UNA PERSONA ", err))
}

export const getAllGeneros = () => {
    return new PersonaApi()
        .getAllGeneros()
        .then(resp => resp.data)
        .catch(err => console.log(err))
}

export const getCuentasByPersonaId = (idPersona) => {
    return new PersonaApi()
        .getCuentasByPersonaId(idPersona)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR CARGAR DATOS DE TABLA COLAPSABLE ", err))
}

export const getAnotaciones = (idCtaPersona) => {
    return new PersonaApi()
        .getAnotaciones(idCtaPersona)
        .then(resp => resp.data)
        .catch(err => console.log("ERROR CARGAR ANOTACIONES ", err))
}

