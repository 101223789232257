import React, { Fragment } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import PDFContainer from './PDFContainer'

const PDFIngresosFyCDetalles = ({pdfData,pdfDataConfig,height, width}) => {
    return (
        <Fragment>
           <PDFViewer width={width} height={height} className="app" >
                <PDFContainer pdfData={pdfData} pdfDataConfig={pdfDataConfig} />
            </PDFViewer>
        </Fragment>
    );
}

export default PDFIngresosFyCDetalles;