import history from '../helpers/history'

export const getStoredAuthToken = () => localStorage.getItem('access_token');

export const storeAuthToken = token => localStorage.setItem('access_token', token);

export const removeStoredAuthToken = () => localStorage.removeItem('access_token');

export const tokenIsExpired = () => {
  const token = getStoredAuthToken()
  if (!token) return true
  const { exp } = JSON.parse(atob(token.split('.')[1]))
  return !(exp > new Date().getTime()/ 1000)
}

export const decodeToken = (prop) => {
  const token = getStoredAuthToken()
  if(!token) return undefined

  const tokenDecode = JSON.parse(atob(token.split('.')[1]))

  return tokenDecode[prop] || ''

}

//redirigir al login si el token expiro

export const redirigir = () => {
  if(tokenIsExpired()) return history.push('/')
}  
