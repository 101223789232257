import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import {useSelector} from 'react-redux'

//acciones 
import { setDataPdfRecibosBarrida } from '../../Redux/actions/PdfActions/PdfActions';

import FormContainer from '../../componentes/Forms/FormContainer';

const FormImprimirRecibosBarrida = ({ openSuccesModal, cerrarModal, datosBarrida }) => {

    const {loading} = useSelector(state=>state.GlobalState)

    const dispatch = useDispatch()
    return (
        <FormContainer
            width="100%"
            resetForm={() => { }}
            loading={loading}
            buttonDisabled={loading}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Imprimir Recibos"
            title={''}
            accionCancelar={cerrarModal}
            onSubmit={() => {
                dispatch(setDataPdfRecibosBarrida(datosBarrida, ()=>{}))
                openSuccesModal()
            }}>
            <Grid xs={12} container item>
                <Typography variant="h6">
                    Imprimir Recibos de la barrida {datosBarrida.id} "{datosBarrida.descripcion} "
                </Typography>
            </Grid>
        </FormContainer>
    )
}
export default FormImprimirRecibosBarrida