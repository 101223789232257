import React from 'react';
import { Page, Document, StyleSheet, Text } from '@react-pdf/renderer';

import PDFTableSaldoDetalles from './PDFTableSaldoDetalles'

const styles = StyleSheet.create({
    page: {
        //fontFamily: 'Helvetica',
        fontFamily: 'Times-Roman',
        paddingTop: 20,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        flexDirection: 'column',
    },
    header: {
        fontSize: 12,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
    },
});



const PDFContainerSaldoDetalles = ({pdfData, pdfDataConfig}) => {
    console.log("ESTA ES LA CONFIGURACION ",pdfDataConfig)
    return <Document>
            <Page size="A4" style={styles.page}>
            <Text style={styles.header} fixed>UNIVERSIDAD DE CONCEPCION DEL URUGUAY </Text>
                <PDFTableSaldoDetalles data={pdfData} />
            </Page>
        </Document>
};

export default PDFContainerSaldoDetalles