import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// Import Componentes
import Controls from '../../componentes/Forms/Controls'

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import FormContainer from '../../componentes/Forms/FormContainer';
import { resumenDeSaldo } from '../../Redux/actions/PdfActions/PdfActions';
import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator';


const initialFValues = {
    fecha: '',
    montoMinimo: '',
    descartarPositivos: false,
    becados: false,
    cuentas: [],
    detalleSaldo: false
}

const FormInformesSaldo = ({
    closeModal,
    openPdfResumenSaldo = () => { },
    openPdfSaldoConDetalles = () => { }

}) => {

    const dispatch = useDispatch()

    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Generar Informe "
            accionCancelar={closeModal}
            onSubmit={() => {
                dispatch(resumenDeSaldo(values, () => { }))
                values.detalleSaldo
                    ? openPdfSaldoConDetalles()
                    : openPdfResumenSaldo()
            }}>
            <Grid item xs={12}>
                <Controls.AutocompleteSelect
                    multiple={true}
                    disablePortal={false}
                    promSelectList={getCuentasListSelect()}
                    valueautocomplete={values.cuentas}
                    filtro={'descripcion'}
                    name="cuentas"
                    valorSalida="id"
                    label="Seleccionar Cuentas "
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fecha"
                    value={values.fecha}
                    onChange={handleChangeForm}
                    title="Fecha"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Input
                    name="montoMinimo"
                    value={values.montoMinimo}
                    onChange={handleChangeForm}
                    label="Monto Minimo"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Checkbox
                    name="descartarPositivos"
                    vale={values.descartarPositivos}
                    onChange={handleChangeForm}
                    label="Descartar positivos"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Checkbox
                    name="becados"
                    vale={values.becados}
                    onChange={handleChangeForm}
                    label="Becados"
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Checkbox
                    name="detalleSaldo"
                    vale={values.detalleSaldo}
                    onChange={handleChangeForm}
                    label="Con detalles de saldo"
                />
            </Grid>
        </FormContainer>
    )
}
export default FormInformesSaldo