import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeader from './PDFTableHeader'
import PDFTableRow from './PDFTableRow'
import PDFTableBlankSpace from './PDFTableBlankSpace'
import PDFTableFooter from './PDFTableFooter'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTable = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeader />
    <PDFTableRow items={data} />
    <PDFTableFooter items={data} />
  </View>
);

export default PDFTable