import AxiosIntance from '../axios/axiosInstace';

export class ArancelesApi {
     BaseUrl = 'aranceles'

    getAllAranceles(){
        return AxiosIntance.get('aranceles')
    }
    addAranceles(cuenta){
        return AxiosIntance.post('aranceles',cuenta)
    }

    getArancelesWithFilter(filter, searchText) {
        return AxiosIntance.get(`aranceles/search/${filter}/${searchText}`)
    }

    pasarActualesAlMesSiguiente(){
        return AxiosIntance.get('aranceles/pasarAlMesSiguiente')
    }

    actualizarArancel(nuevoArancel){
        return AxiosIntance.post('aranceles/actualizarArancel',nuevoArancel)
    }

    generarDebitos(idArancel){
        return AxiosIntance.get(`aranceles/generarDebitos/${idArancel}`,)
    }

}