import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import Table from '../../componentes/CustomTable'
import CollapseTable from '../../componentes/CustomTable/CollapseTable';
import Modal from '../../componentes/Modals/Modal'
import PlanesPagos from '../../componentes/ModalTable/PlanesPagos/PlanesPagos';
import SearchBar from '../../componentes/SearchBar/SearchBar'
import LayoutTest from '../../Layout/LayoutTest'
import AnotacionesCp from '../../componentes/ModalTable/Anotaciones/AnotacionesCp'
import TablaCtaCte from './TablaCtaCte'
import PagoACuenta from '../../componentes/Recibos/PagoACuenta';

//Api 
import { PersonaApi } from '../../api/PersonaApi';

//formulario
import FormPersonas from './FormPersonas'
import ReciboGeneral from '../../componentes/Recibos/ReciboGeneral';
import ReciboX from '../../componentes/Recibos/ReciboX'
import FormEditCollapseTable from './FormEditCollapseTable'
import FormVincularCuenta from './FormVincularCuenta';

//acciones
import { getCuentasByPersonaId } from '../../Redux/actions/personas/ActionCreatorPersona'
import { setDataCollapseTable, selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'
import { openModalEdit, openModalRegister } from '../../Redux/actions/modales/ActionCreatorModals';
import { getCtacte } from '../../Redux/actions/ctacte/CtacteActionCreator';

//hooks 
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'


const personaApi = new PersonaApi()

const Personas = () => {
  const dispatch = useDispatch()

  //estados para controlar los modales
  const { modalRegister, defaultModal } = useSelector((state) => state.ModalState)
  const { detallesData, dataCollapseTable } = useSelector(state => state.GlobalState)

  const [modalVincularCuenta, setModalVincularCuenta] = useState(false)

  const [modalCtacte, setModalCtacte] = useState(false)
  const [modalRecibo, setModalRecibo] = useState(false)
  const [modalReciboX, setModalReciboX] = useState(false)

  const [modalPlanesPagos, setModalPlanesPagos] = useState(false)
  const [modalAnotaciones, setModalAnotaciones] = useState(false)
  const [modalEditCollapseTable, setModalEditCollapseTable] = useState(false)
  const [modalPagoACuenta, setModalPagoACuenta] = useState(false)

  //cargar datos para el recibo general 
  const [dataReciboGeneral, setDataReciboGeneral] = useState({})

  const asyncDataCollapsableTable = (data) => {
    return getCuentasByPersonaId(data)
  }

  const { dataTable, loading, recargarTabla } = useCargarTabla(() => personaApi.getAllPersonas())
  const {
    filterTable,
    searchText,
    setSearchText,
    setFilter } = useSearchTable((filter, searchText) => personaApi
      .getPersonasWithFilter(filter, searchText))

  const filtrosSearchBar = [
    {
      key: "NOMBRE",
      label: "Nombre"
    },
    {
      key: "NDOC",
      label: "Nro Documento"
    },
    {
      key: "email",
      label: "Email"
    },
    {
      key: "domicilio",
      label: "Domicilio"
    }
  ]

  //Menu de acciones para la tabla collapsable 
  // IMPORTANTE "idcuentapersona" es una prop del obj. de la fila de la tabla collapsable

  const actions = [
    {
      icon: "editar",
      action: (data) => {
        dispatch(selectDataAction(data))
        dispatch(openModalEdit())
      },
      tooltip: "Editar datos de la persona"
    },
    {
      icon: 'link',
      action: (data) => {
        setModalVincularCuenta(!modalVincularCuenta)
        dispatch(selectDataAction(data))
      },
      tooltip: "Vincular Cuenta"
    }
  ]

  const ActionsMenuCollapseTable = [
    {
      title: "Planes de pago",
      action: () => {
        setModalPlanesPagos(!modalPlanesPagos)
      },
      icon: "payment"
    },
    {
      title: "Registrar Mov. de caja",
      action: (dataRecibo) => {

        setDataReciboGeneral(dataRecibo)
        setModalRecibo(!modalRecibo)

      },
      icon: "recibo"
    },
    {
      title: "Registrar Mov. Arrendatario",
      action: (dataRecibo) => {
        setDataReciboGeneral(dataRecibo)
        setModalReciboX(!modalReciboX)
      },
      icon: "recibo",
      vermenu:!dataCollapseTable.autogestionactivo
    },
    {
      title: "Ver CtaCte",
      action: (data) => {
        setModalCtacte(!modalCtacte)
        dispatch(getCtacte(dataCollapseTable["idcuentapersona"]))
      },
      icon: "ctacte",
    },

    {
      title: "Anotaciones",
      action: () => setModalAnotaciones(!modalAnotaciones),
      icon: 'anotaciones'
    },
    {
      title: "Pago A Cuenta (ingreso 2024)",
      action: () => setModalPagoACuenta(!modalPagoACuenta),
      icon: 'recibo',
      vermenu: !!dataCollapseTable.autogestionactivo
    }
  ]

  const ActionsCollapseTable = [
    {
      icon: "editarCustom",
      action: (data) => {
        dispatch(setDataCollapseTable(data))
        setModalEditCollapseTable(!modalEditCollapseTable)
      },
      tooltip: "Edicion de libre deuda"
    }
  ]

  const columns = [
    { title: " ", width: 25 },
    { title: "ID" },
    { title: "Nombre" },
    { title: "Ndoc" },
    { title: "Telefono" },
    { title: "Email" },
    { title: "Domicilio" }
  ]

  const columnsCollapseTable = [
    { title: "Nombre de cuenta", width: '20%', name: "cuenta" },
    { title: "CodCar" },
    { title: "LibreDeuda" },
    { title: "Saldo" },
    { title: "Saldo a favor", name: 'saldoafavor' },
    { title: "Cant. Cuotas", name: 'CantCuotas' },
    { title: 'Autogestion', type: 'boolean', name: 'autogestionactivo' },
    { title: 'Fin', type: 'boolean' },
    { title: "Activo", type: 'boolean' },
    { title: "Baja", type: 'boolean' },
    { title: "Nota" },
  ]

console.log('que hay aca',dataCollapseTable)

  const buttons = [
    {
      icon: 'nuevo',
      action: () => dispatch(openModalRegister()),
      label: 'Nueva Persona'

    },
    /*     {
          icon:'imprimir',
          action:()=> alert('Imprimir'),
          label:'imprimir'
        } */
  ]

  return <LayoutTest
    searchBar={
      <SearchBar
        filtros={filtrosSearchBar}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
      />
    }
    buttons={buttons}
  >
    {
      //  const [modalAnotaciones, setModalAnotaciones] = useState(false)
      modalAnotaciones && <Modal
        width={'70vw'}
        closeModal={() => setModalAnotaciones(!modalAnotaciones)}
        active={modalAnotaciones}
      >
        <AnotacionesCp
          idCuentaPersona={dataCollapseTable["idcuentapersona"]}
          cuenta={dataCollapseTable["cuenta"]}
          nombrePersona={dataCollapseTable["nombre"]}
        />
      </Modal>
    }


    <Modal
      width={'70vw'}
      closeModal={() => setModalPagoACuenta(!modalPagoACuenta)}
      active={modalPagoACuenta}
    >
      <PagoACuenta/>
    </Modal>

    <Modal
      width={'50vw'}
      closeModal={() => setModalPlanesPagos(!modalPlanesPagos)}
      active={modalPlanesPagos}
    >
      <PlanesPagos
        detallesPersona={{ ...detallesData, ...dataCollapseTable }}
        idCuentaPersona={dataCollapseTable["idcuentapersona"]} />
    </Modal>

    <Modal
      closeModal={() => setModalEditCollapseTable(!modalEditCollapseTable)}
      active={modalEditCollapseTable}
    >
      <FormEditCollapseTable />
    </Modal>

    {/* MODAL CUENTA CORRIENTE */}

    <Modal
      width={'80vw'}
      closeModal={() => setModalCtacte(!modalCtacte)}
      active={modalCtacte}
    >
      <TablaCtaCte data={dataCollapseTable} />
    </Modal>


    {
      modalReciboX && <Modal
        closeModal={() => setModalReciboX(!modalReciboX)}
        active={modalReciboX}
      >
        <ReciboX data={dataReciboGeneral} />
      </Modal>
    }
    {
      modalRecibo && <Modal
        closeModal={() => setModalRecibo(!modalRecibo)}
        active={modalRecibo}
      >
        <ReciboGeneral cerrarModal={() => setModalRecibo(!modalRecibo)} data={dataReciboGeneral} />
      </Modal>
    }
    {
      modalRegister &&
      <Modal
        width={'50vw'}
        closeModal={() => dispatch(openModalRegister())}
        active={modalRegister}

      >
        <FormPersonas
          recargarTabla={recargarTabla}
          accionCancelar={() => dispatch(openModalRegister())} />
      </Modal>
    }

    <Modal
      width={'50vw'}
      closeModal={() => setModalVincularCuenta(!modalVincularCuenta)}
      active={modalVincularCuenta}>
      <FormVincularCuenta recargarTabla={recargarTabla} />
    </Modal>

    <Table
      ActionsCollapseTable={ActionsCollapseTable}
      ActionsMenuCollapseTable={ActionsMenuCollapseTable}
      paramSetDataCollapsableTable="id"
      asyncDataCollapsableTable={asyncDataCollapsableTable}
      columnsCollapseTable={columnsCollapseTable}
      collapseTable={<CollapseTable />}
      tableData={filterTable.length ? filterTable : dataTable}
      filterTable={filterTable.length}
      columns={columns}
      actions={actions}
      rowChek={false}
    />
  </LayoutTest>
}
export default Personas