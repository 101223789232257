import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

export const useForm = (initialFValues, nuevo=false) => {

  const [values, setValues] = useState(initialFValues);
  const { detallesData } = useSelector((state) => state.GlobalState)

  const cargarDatosEdit = () => {
    setValues({
      ...values,
      ...detallesData
    })
  }

  const handleChangeForm = ({ target }) => {
    const { name, value } = target

    setValues({
      ...values,
      [name]: value 
    })
  }

  useEffect(() => {
    if (Object.entries(detallesData).length && !nuevo) cargarDatosEdit()
  }, [Object.values(initialFValues)[0]])

  const resetForm = () => {
    setValues(initialFValues);
  }

  return {
    values,
    setValues,
    handleChangeForm,
    resetForm,
    formEdit: !!Object.entries(detallesData).length
  }
}
  