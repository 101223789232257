export const agruparFilasPorId = (items) => {
    let movId = ""
    let filas = []
    let fila = {
        nombre: '',
        detalle: '',
        carrera: '',
        fecha: '',
        monto: '',
        valor: '',
        descripcion: '',
        codalu: '',
        modopago: '',
    }

    items.forEach((item, index) => {
        if (!movId) {
            movId = item.id
            //primero agrego los valores generales 
            fila = {
                ...fila,
                nombre: item.nombre,
                detalle: item.detalle,
                carrera: item.carrera,
                fecha: item.fecha,
                monto: item.monto,
                valor: item.valor,
                descripcion: item.descripcion,
                codalu: item.codalu,
                modopago: item.modopago,
            }
        }
        if (movId) {
            if (movId == item.id) {
                console.log("ITEMS ", item)
                console.log("FILA ", fila)
                if (item.detalle == "BIBLIOTEC") {
                    fila[item.detalle] = 0
                    fila["ARANCEL"] = fila["ARANCEL"] + item.valor
                } else {
                    fila[item.detalle] = item.valor
                    //el ultimo item no entra en el else asi que lo pusheo al final 
                    index + 1 == items.length && filas.push(fila)
                }

            } else {

                filas.push(fila)
                // reseteo las variables de control y el total y comienzo de nuevo con el siguiente movimiento
                fila = {
                    nombre: '',
                    detalle: '',
                    carrera: '',
                    fecha: '',
                    monto: '',
                    valor: '',
                    descripcion: '',
                    codalu: '',
                    modopago: '',
                }
                movId = ""

                //inserto el primer detalle que seria el ARANCEL 
                fila[item.detalle] = item.valor
            }
        }
    })

    return filas
}



export const formatearNumero = (num) => {
    const numero = parseFloat((num).toFixed(2))
    const numeroFormateado = (numero).toLocaleString().split(',').length == 1
        ? (numero).toLocaleString() + ',00'
        : (numero).toLocaleString().split(',')[1].length == 1
            ? (numero).toLocaleString() + '0'
            : (numero).toLocaleString()
    return numeroFormateado
}

export const calcularTotales = (items) => {
    return {
        totalAranceles: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalArancel"], 0),
        totalBonificacion: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBonificacion"], 0),
        totalIntereses: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalIntereses"], 0),
        totalBiblioteca: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBiblioteca"], 0),
        totalMoratoria: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalMoratoria"], 0),
        total: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotal"], 0)
    }
}


export const datosByPtoVenta = (pto) => {
    let datos = {
        lugar: '',
        direccion: '',
        cp: '',
        provincia: '',
        telefono: '',
        fax: '',
        caracteristica: ''
    }
    switch (pto) {
        case '12': {
            datos = {
                lugar: 'Concepcion del Uruguay',
                direccion: '8 de Junio 522',
                cp: '3260',
                provincia: 'ENTRE RIOS',
                caracteristica: '03442',
                telefono: '425606',
                fax: '427721'
            }
            break
        }
        case '3':
        case '2':
            datos = {
                lugar: 'Gualeguaychu',
                direccion: 'Luis N. Palma 1125',
                cp: '2820',
                provincia: 'ENTRE RIOS',
                caracteristica: '03446',
                telefono: '426852',
                fax: '435312'
            }
            break

        case '15':
        case '6':
            datos = {
                lugar: 'Rosario',
                direccion: 'Sargento Cabral 548',
                cp: '2000',
                provincia: 'SANTA FE',
                caracteristica: '0341',
                telefono: '4484430',
                fax: '4484433'
            }
            break

        case '5': {
            datos = {
                lugar: 'Villaguay',
                direccion: 'Sant Martin 473 Planta Alta',
                cp: '3240',
                provincia: 'ENTRE RIOS',
                caracteristica: '3455',
                telefono: '423456',
                fax: '15642348'
            }
            break
        }
        case '5': {
            datos = {
                lugar: 'Parana',
                direccion: 'Gualeguaychú 230',
                cp: '3100',
                provincia: 'ENTRE RIOS',
                caracteristica: '0343',
                telefono: '4231547',
                fax: '---'
            }
            break
        }
        case '16': {
            datos = {
                lugar: 'Santa Fe',
                direccion: 'Tucumán 2721 (1º Piso)',
                cp: '2013',
                provincia: 'SANTA FE',
                caracteristica: '0342',
                telefono: '4554100',
                fax: '4558371'
            }
            break
        }
        case '13': {
            datos = {
                lugar: 'Concordia',
                direccion: 'Urquiza 132',
                cp: '3200',
                provincia: 'ENTRE RIOS',
                caracteristica: '---',
                telefono: '---',
                fax: '---'
            }
            break
        }
        case '14': {
            datos = {
                lugar: 'La Paz',
                direccion: '---',
                cp: '3190',
                provincia: 'ENTRE RIOS',
                caracteristica: '---',
                telefono: '---',
                fax: '---'
            }
            break
        }

    }
    return datos
}

