import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        fontFamily: 'Times-Roman',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        textAlign: 'center',
        fontStyle: 'light',
        flexGrow: 1,
    },

    textHeader:{
        fontSize:10,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    concepto: {
        width: '60%',
    },
    numeros:{
        width: '10%',
    },
  });

  const PDFTableHeaderSaldo = () => (
    <View style={styles.container} fixed>
        <Text style={{...styles.numeros, ...styles.textHeader}}>FECHA</Text>
        <Text style={{...styles.concepto, ...styles.textHeader}}>CONCEPTO</Text>
        <Text style={{...styles.numeros, ...styles.textHeader}}>DEBE</Text>
        <Text style={{...styles.numeros, ...styles.textHeader}}>HABER</Text>
        <Text style={{...styles.numeros, ...styles.textHeader}}>SALDO</Text>

    </View>
  );
  
  export default PDFTableHeaderSaldo