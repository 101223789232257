export const agruparFilasPorId = (items) => {
    let movId = ""
    let filas = []
    let fila = {
        nombre: '',
        detalle: '',
        carrera: '',
        fecha: '',
        monto: '',
        valor: '',
        descripcion: '',
        codalu: '',
        modopago: '',
    }

    items.forEach((item, index) => {
        if (!movId) {
            movId = item.id
            //primero agrego los valores generales 
            fila = {
                ...fila,
                nombre: item.nombre,
                detalle: item.detalle,
                carrera: item.carrera,
                fecha: item.fecha,
                monto: item.monto,
                valor: item.valor,
                descripcion: item.descripcion,
                codalu: item.codalu,
                modopago: item.modopago,
            }
        }
        if (movId) {
            if (movId == item.id) {
                if (item.detalle == "BIBLIOTEC") {
                    fila[item.detalle] = 0
                    fila["ARANCEL"] = fila["ARANCEL"] + item.valor
                } else {
                    fila[item.detalle] = item.valor
                    //el ultimo item no entra en el else asi que lo pusheo al final 
                    index + 1 == items.length && filas.push(fila)
                }

            } else {

                filas.push(fila)
                // reseteo las variables de control y el total y comienzo de nuevo con el siguiente movimiento
                fila = {
                    nombre: '',
                    detalle: '',
                    carrera: '',
                    fecha: '',
                    monto: '',
                    valor: '',
                    descripcion: '',
                    codalu: '',
                    modopago: '',
                }
                movId = ""

                //inserto el primer detalle que seria el ARANCEL 
                fila[item.detalle] = item.valor
            }
        }
    })

    return filas
}



export const formatearNumero = (num) => {
    const numero = parseFloat((num).toFixed(2))
    const numeroFormateado = (numero).toLocaleString().split(',').length == 1
        ? (numero).toLocaleString() + ',00'
        : (numero).toLocaleString().split(',')[1].length == 1
            ? (numero).toLocaleString() + '0'
            : (numero).toLocaleString()
    return numeroFormateado
}

export const calcularTotales = (items) => {
    return {
        totalAranceles: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalArancel"], 0),
        totalBonificacion: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBonificacion"], 0),
        totalIntereses: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalIntereses"], 0),
        totalBiblioteca: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalBiblioteca"], 0),
        totalMoratoria: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalMoratoria"], 0),
        total: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotal"], 0)
    }
}

