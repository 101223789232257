import React from 'react'
import { TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useIsMounted from '../../hooks/useIsMounted';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
        },
    }));

export default function Input(props) {
    useIsMounted()

    const classes = useStyles()

    const { multiline=false, row=1,name, label, value, error = null, onChange, type, InputLabelProps, verySmall = false, onBlur, size='small', onKeyPress=''} = props;
    return (
        <div className={classes.root}>
            <TextField
                InputLabelProps={{InputLabelProps}}
                type={type}
                variant="outlined"
                multiline={multiline}
                row={row}
                label={label}
                name={name}
                onKeyPress={!onKeyPress ? (e) => { e.key === 'Enter' && e.preventDefault(); }: onKeyPress}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                size={size}
                {...props}
                {...(error && { error: true, helperText: error })}
            />
        </div>
    )
}