import { useState, useCallback } from "react"
import { useSnackbar } from 'notistack';

const useSubmit = (
  action, 
  dataSubmit, 
  recargarTabla = () => { }, 
  textSucces = "Listo!", 
  textError = "Ocurrio un problema", 
  funcSuccess=()=>{}, 
  funcError=()=>{}) => {

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const formSubmit = useCallback((event) => {
    event.preventDefault()

    setLoading(true)

    action(dataSubmit)
      .then(resp => {
        setData(resp.data)
        recargarTabla()
        funcSuccess(resp.data)
        setLoading(false)
        enqueueSnackbar(textSucces, { variant: 'success' })
      })

      .catch(err => {
        funcError()
        enqueueSnackbar(err.response?.data?.error || textError, { variant: 'error' })
        setLoading(false)
        console.log("ERROR ENVIAR DATOS DEL FORMULARIO ", err.response)
      })

  }, [JSON.stringify(dataSubmit)])

  return {
    formSubmit,
    loading,
    data
  }
}
export default useSubmit