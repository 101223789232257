import { Grid } from '@material-ui/core';
import { Anotaciones_cpApi } from '../../../api/Anotaciones_cpApi';
import { useForm, Controller } from "react-hook-form";

// Import Componentes
import Controls from '../../Forms/Controls'
import FormContainer from '../../Forms/FormContainer';

// Custom hooks
import useEnviarDatos from '../../../hooks/useEnviarDatos'
import * as useFormCustom from '../../../hooks/useForm'


const FormAnotaciones = ({ width = '', data, recargarTabla, accionCancelar, funcSuccess, dataEdit }) => {

    const initialValue = {
        detalle: '',
        idCtaPersona: data
    }
    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useFormCustom.useForm({ ...initialValue, idNota: dataEdit.id, detalle: dataEdit.detalle });

    const { sendData } = useEnviarDatos({
        action: Object.entries(dataEdit).length ? new Anotaciones_cpApi().updateNotaCtaPersona : new Anotaciones_cpApi().nuevaNotaCtaPersona,
        textSucces: Object.entries(dataEdit).length ? 'Nota actualizada' : 'Nueva Nota Agregada',
        recargarTabla,
        funcSuccess: accionCancelar
    })

    const onSubmit = (data) => {
        console.log("ESTA ES LA DATA", data)
        return sendData(data)
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { ...values, idNota: dataEdit.id, detalle: dataEdit.detalle },
    });


    return (
        <FormContainer
            sizeTitle="h6"
            height="auto"
            width={width}
            accionCancelar={accionCancelar}
            resetForm={(resetForm)}
            LabelButtonSubmit={Object.entries(dataEdit).length ? "Actualizar Nota" : "Agregar Nota"}
            alignContent="center"
            ButtonSubmit="Agregar Mov Cta.cte."
            onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <Controller
                        name="detalle"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                multiline={true}
                                row={2}
                                inputProps={{ style: { fontSize: 14 } }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                label="Detalle"
                                name="detalle"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />

                </Grid>
            </Grid>
        </FormContainer >

    )
}

export default FormAnotaciones