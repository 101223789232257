import { useDispatch, useSelector } from 'react-redux'

//Api
import { CuentasApi } from '../../api/CuentasApi'

//Components
import Table from '../../componentes/CustomTable'
import FormCuentas from './FormCuentas'
import Modal from '../../componentes/Modals/Modal'
import SearchBar from '../../componentes/SearchBar/SearchBar'
import LayoutTest from '../../Layout/LayoutTest'

//Actions
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'
import { openModalEdit, openModalRegister } from '../../Redux/actions/modales/ActionCreatorModals';
import BuilPDF from '../../helpers/buildTablePdf'

//hooks 
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'

const Cuentas = () => {
    const dispatch = useDispatch()

    const { modalRegister, defaultModal } = useSelector((state) => state.ModalState)
    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new CuentasApi().getAllCuentas())
    const {
        filterTable,
        searchText,
        setSearchText,
        setFilter } = useSearchTable((filter, searchText) => new CuentasApi()
            .getCuentasWithFilter(filter, searchText))
    const actions = [
        {
            icon: "editar",
            action: (data) => {
                dispatch(selectDataAction(data))
                dispatch(openModalEdit())
            },
            tooltip: "Editar cuenta"
        }
    ]

    const filtrosSearchBar = [
        {
            key: "DESCRIPCION",
            label: "Descripcion"
        },
        {
            key: "CODCAR",
            label: "Cod. Carrera"
        },
        {
            key: "CODFAC",
            label: "Cod. Facultad"
        },
    ]

    const columnas = [
        { title: "ID", width: '8%' },
        { title: "Cod. Facultad", width: '8%', name: 'codfac' },
        { title: "Cod. Carrera", width: '8%', name: 'codcar' },
        { title: "Descripcion", width: "25%" },
        { name: "descripcion_tipocuenta", title: "Tipo Cuenta", width: '25%' },
        { title: "Grupo Barrida", width: '15%' },
        { title:'Activa', name:'activo', width: '10%', type: 'boolean'}
    ]

    const buttons = [
        {
            icon: 'nuevo',
            action: () => dispatch(openModalRegister()),
            label: 'Crear Cuenta'
        },
        /* {
            icon:'imprimir',
            action: ()=>BuilPDF(),
            label: 'Imprimir'
        } */
    ]

    return <LayoutTest
        searchBar={
            <SearchBar
                filtros={filtrosSearchBar}
                searchText={searchText}
                setSearchText={setSearchText}
                setFilter={setFilter}
            />
        }
        buttons={buttons}
    >
        <Modal
            width={'50vw'}
            closeModal={() => dispatch(openModalRegister())}
            active={modalRegister}
        >
            <FormCuentas 
                accionCancelar={() => dispatch(openModalRegister())} 
                recargarTabla={recargarTabla} 
                />
        </Modal>
        <Table
            filterTable={filterTable.length}
            tableData={filterTable.length ? filterTable : dataTable}
            columns={columnas}
            actions={actions}
            rowChek={false}
        />
    </LayoutTest>
}

export default Cuentas