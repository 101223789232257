import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from "@material-ui/core/styles";
import Controls from '../Forms/Controls';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done'
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types'

const propTypes = {
    children: PropTypes.element,
    check: PropTypes.bool,
    variant: PropTypes.oneOf(["body", "head", "footer"]),
    checkAll: PropTypes.bool,
    width: PropTypes.bool,
    isArray: PropTypes.bool,
    boolean: PropTypes.bool,
    align: PropTypes.oneOf(["left", "right", "inherit", "center", "justify"]),
    openModal: PropTypes.func,
    allRowData: PropTypes.object

}

//Style align center checkbox
const useStyles = makeStyles(() =>
    createStyles({
        body: {
            minHeight: '30px !important',
            color: '#3e3e3c',
            padding: '6px 15px',
        },
        editCell: {
            padding: 5
        }
    })
)

//styled and build new cell component
const StyledTableCell = withStyles((theme) => ({
    head: {
        color: "#3e3e3c",
        fontWeight:700 ,
        padding: 4,
        paddingLeft: theme.spacing(4),
        border: 'none',
        textTransform: 'none',

    },
    body: {
        fontWeight: 400,
        minHeight: '30px !important',
        color: '#3e3e3c',
        padding: '0px 15px',
        alignItems: 'center',
    },
}))(TableCell);

export const Cell = (props) => {
    const selectIconBoolean = (value) => {
        return value ? <DoneIcon /> : <CloseIcon />
    }
    const {
        width = 'max-content',
        children,
        variant,
        align,
        isArray = false,
        boolean = false,
        openModal = null,
        allRowData,
        action = null,
        size = 'medium',
        columna } = props
    return (
        <StyledTableCell
            size={size}
            {...props}
            width={width}
            align={align}
        >
            <Typography variant={variant == 'head' ?  variant : "body2"} >
                {
                    boolean
                        ? selectIconBoolean(boolean)
                        : isArray
                            ?
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {children.split(',').map((value, index) => {
                                    return (
                                        <Chip
                                            onClick={() => {
                                                (typeof action === 'function') && action({ datarow: allRowData, indexSelect: index })
                                            }}
                                            style={{ margin: '2px', borderRadius: 5, background: '#fef9e0', border: 'none' }}
                                            label={value}
                                            clickable
                                            variant="outlined"
                                            color="primary"
                                        />
                                    )
                                })}
                            </div>
                            : children
                }
            </Typography>
        </StyledTableCell>
    )
}

Cell.propTypes = propTypes

// Cell checkBox
export const CellCheckBox = ({ children, data,onClick, checkAll, check = false, variant = 'body' }) => {
    console.log("ESTA CHECQUEADO ",check)
    return (
        <StyledTableCell
            onClick={() => onClick(data)}
            title={checkAll ? 'Seleccionar Todos' : ''}
            variant={variant}>
           {children}
        </StyledTableCell>
    )
}

CellCheckBox.propTypes = propTypes

// IconCell 
export const CellAction = (props) => {
    const { children, align = "right" } = props
    const classes = useStyles()

    return (
        <TableCell
            align={align}
            className={classes.body}>
            <Typography variant="body2">{children}</Typography>
        </TableCell>
    )
}

export const CellEdit = ({ row, name, onChange, width, align, values, isEdit = false }) => {
    const classes = useStyles()
    return (
        <TableCell
            width={width}
            align={align}
            className={classes.editCell}
        >
            {isEdit ? (

                <Controls.Input
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    fullWidth
                    label={name}
                    value={values}
                    name={name}
                    onChange={onChange}
                />

            ) : (
                row[name.toLowerCase()]
            )}
        </TableCell>
    );
};