import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { agruparFilasPorId, agruparPorCodigo } from './funciones'

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    nombre: {
        width: '20%',
    },

    concepto: {
        width: '32%',
    },

    codigo: {
        width: '4%',
    },

    recibo: {
        width: '5%',
        textAlign: 'right'
    },

    detalles: {
        width: '7%',
        textAlign: 'right'
    },

    otros: {
        width: '4%',
        textAlign: 'right'
    },
    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    }
});


const PDFTableRow = ({ items }) => {
    let rows = []


    console.log("FILAS ")
    console.log("FILAS ", agruparFilasPorId(items))

    agruparPorCodigo(agruparFilasPorId(items)).forEach((item, index) => {
        
        item.forEach((fila, indx) => {

            if (item.length - 1 == indx) {
                rows.push(
                    //agrego fila que contiene los subtotales de los grupos
                    <View style={{ ...styles.row, ...styles.negrita }} >
                        <Text style={{ ...styles.codigo, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.concepto, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.recibo, ...styles.text }}>{""}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["subTotalArancel"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["subTotalBonificacion"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["subTotalIntereses"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["subTotalBiblioteca"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.otros, ...styles.text }}>{(fila["subTotalMoratoria"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["subTotal"]).toFixed(2)}</Text>
                    </View>,
                    //agrego fila en balnco 
                    <View style={{ ...styles.row, borderBottomColor: borderColor, borderBottomWidth: 1 }}>
                        <Text style={{ ...styles.codigo, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.concepto, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.recibo, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.otros, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{" "}</Text>
                    </View>
                )
            } else {
                rows.push(
                    <View style={styles.row}>
                        <Text style={{ ...styles.codigo, ...styles.text }}>{fila.codigo}</Text>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{fila.nombre}</Text>
                        <Text style={{ ...styles.concepto, ...styles.text }}>{fila.concepto}</Text>
                        <Text style={{ ...styles.recibo, ...styles.text }}>{fila.recibo}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["ARANCEL"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["BONIFIC."]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["INTERESES"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila["BIBLIOTEC"]).toFixed(2)}</Text>
                        <Text style={{ ...styles.otros, ...styles.text }}>{(fila["MORATORIA"].toFixed(2))}</Text>
                        <Text style={{ ...styles.detalles, ...styles.text }}>{(fila?.total)?.toFixed(2)}</Text>
                    </View>)
            }
        })

    }
    )

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRow
