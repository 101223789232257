import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import FormContainer from '../../componentes/Forms/FormContainer';
import Controls from '../../componentes/Forms/Controls'
import useSubmit from '../../hooks/useSubmit';
import { useForm } from '../../hooks/useForm'



const initialFValues = {
    email: '',
    editEmail: false
}

const FormEnviarReciboMail = ({ onSubmit, cerrarModal, datosMov, loading }) => {

    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm
    } = useForm(initialFValues);

    const dispatch = useDispatch()
    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            loading={loading}
            buttonDisabled={loading}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Enviar Recibo"
            resetFormDisabled
            title={''}
            accionCancelar={cerrarModal}
            onSubmit={() => onSubmit({ ...datosMov, email: values.email })}>

            <Grid xs={12} container item>
                <Typography variant="h5">
                    El recibo se enviara al siguiente e-mail:
                </Typography>
            </Grid>

            <Grid xs={12} container item>
                <Controls.Input
                    label="Email"
                    name="email"
                    type='email'
                    required
                    disabled={!values.editEmail}
                    value={values.email}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid >
                <Controls.Checkbox
                    label="Editar Email"
                    name="editEmail"
                    value={values.editEmail}
                    onChange={handleChangeForm}
                />
            </Grid>
        </FormContainer>
    )
}
export default FormEnviarReciboMail