import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react'

// Import Componentes
import Controls from '../../componentes/Forms/Controls'

//acciones 
import { setDataPdfArqueoCaja } from '../../Redux/actions/PdfActions/PdfActions';

// Custom hooks
import { useForm } from '../../hooks/useForm';
import FormContainer from '../../componentes/Forms/FormContainer';


const initialFValues = {
    fecha: ''
}

const FormImprimirArqueoDeCaja = ({ cerrarModal,openSuccesModal }) => {
    const dispatch = useDispatch()
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);


    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Imprimir Caja"
            title={''}
            accionCancelar={cerrarModal}
            onSubmit={() => {
                dispatch(setDataPdfArqueoCaja(values,openSuccesModal))
                cerrarModal()
            }}>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fecha"
                    value={values.fecha}
                    onChange={handleChangeForm}
                    title="Fecha"
                />
            </Grid>
        </FormContainer>
    )
}
export default FormImprimirArqueoDeCaja