import {
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import history from '../../helpers/history';
import { makeStyles, createStyles } from '@material-ui/core/styles';
//action
import { UsuarioApi } from '../../api/UsuarioApi'; 
import Controls from '../../componentes/Forms/Controls';
//custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit'
import { storeAuthToken, decodeToken } from '../../utils/authToken';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      marginTop: 10,
      textAlign: 'center',
    },
    submit: {
      background: "#EBC522",
      color: "#3e3e3c",
      '&:hover': {
        backgroundColor: '#f9bc06',
      },
      padding: 8,
      marginTop: 20
    },
    container: {
      boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)', 
      background: "#FFF",
      padding: 30,
      borderRadius: 10,
    },
    title: {
      color: "#3e3e3c",
      fontWeight: 400,
      textAlign:'center',
      width:'100%'
    },

  }));

const Login = () => {
  const classes = useStyles();

  const { values,
    handleChangeForm } = useForm({
      usuario: '',
      clave: ''
    })
                                                            //funcion recargarTabla
  const { formSubmit } = useSubmit( new UsuarioApi().login, values, ()=>{} ,"Bienvenido", "Usuario invalido", 
  (data)=>{
    storeAuthToken(data.access_token)
    // Si es un usuario de caja lo redirijo a la pagina de personas, sino, a la pagina de cuentas
    return decodeToken('idCaja') ? history.push('/auth/personas') : history.push('/auth/cuentas') 
  })

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <form
        className={classes.form}
        onSubmit={formSubmit}
      >
        <Grid container spacing={5} >
          <Typography className={classes.title} variant="h4">
            UCU-Aranceles
          </Typography>
          <Grid item xs={12}>
            <Controls.Input
              fullWidth
              label="Nombre de Usuario"
              onChange={handleChangeForm}
              name="usuario"
              onKeyPress={()=>{}}
              required
              placeholder="Nombre de Usuario"
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              label="Contraseña"
              onChange={handleChangeForm}
              required
              type="password"
              placeholder="Contraseña"
              fullWidth
              onKeyPress={()=>{}}
              name="clave"
            />
          </Grid>
          <Controls.Button
            type="submit"
            text="Ingresar"
            fullWidth
            className={classes.submit}
          //onClick={()=>history.push('/table')} 
          />
        </Grid>
      </form>
    </Container>
  )
}

export default Login