import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'

//PDF 
import PdfRecibosBarrida from '../../componentes/Pdf/pdfRecibosBarrida/PdfRecibosBarrida'

//Api
import { BarridasApi } from '../../api/BarridasApi'

// components
import ModalDialog from '../../componentes/Modals/ModalDialog'
import Table from '../../componentes/CustomTable'
import ActionMenu from '../../componentes/Menu/ActionMenu'
import Modal from '../../componentes/Modals/Modal'
import SearchBar from '../../componentes/SearchBar/SearchBar'
import LayoutTest from '../../Layout/LayoutTest'

//Formularios
import FormSubirArchivoBanco from '../../componentes/Formularios/FormSubirArchivoBanco'
import FormBarrida from './FormBarrida'
import FormImprimirRecibosBarrida from './FormImprimirRecibosBarrida'

//Tablas
import AcreditacionesBarrida from '../../componentes/ModalTable/Barrida/AcreditacionesBarrida'
import InformeRechazosBarrida from '../../componentes/ModalTable/Barrida/InformeRechazosBarrida'
import PagaronMesActualyNoAnterior from '../../componentes/ModalTable/Barrida/PagaronMesActualyNoAnterior'
import PagaronMesAnterioryNoActual from '../../componentes/ModalTable/Barrida/PagaronMesAnterioryNoActual'

//Actions
import { openModalEdit, openModalRegister } from '../../Redux/actions/modales/ActionCreatorModals';
import { setDataPdfAction } from '../../Redux/actions/PdfActions/PdfActions';

//hooks 
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'
import useIsMounted from '../../hooks/useIsMounted'
import useEnviarDatos from '../../hooks/useEnviarDatos';


import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

const Barridas = () => {
  useIsMounted()

  const dispatch = useDispatch()

  const [modalSubirArchivo, setModalSubirArchivo] = useState(false)
  const [modalGenerarArchivo, setModalGenerarArchivo] = useState(false)
  const [modalAcreditacionesBarrida, setModalAcreditacionesBarrida] = useState(false)
  const [modalInformeRechazos, setModalInformeRchazos] = useState(false)
  const [modalPagaronMesActual, setModalPagaronMesActual] = useState(false)
  const [modalPagaronMesAnterior, setModalPagaronMesAnterior] = useState(false)
  const [modalImprimirRecibos, setModalImprimirRecibos] = useState(false)
  const [modalPdfRecibosBarrida, setModalPdfRecibosBarrida] = useState(false)

  //funcion para abrir modal 
  const { modalRegister } = useSelector((state) => state.ModalState)
  const { detallesData } = useSelector((state) => state.GlobalState)
  const { pdfData, pdfDataConfig } = useSelector(state => state.PdfState)

  const { dataTable, loading, recargarTabla } = useCargarTabla(() => new BarridasApi().getAllBarridas())
  const {
    filterTable,
    searchText,
    setSearchText,
    setFilter } = useSearchTable((filter, searchText) => new BarridasApi()
      .getListadoBarridasWithFilter(filter, searchText))

  const columns = [
    { title: "ID" },
    { title: "Descripcion" },
    { title: "Barrida", name: "nrobarrida" },
    { title: "Grupo" },
    { title: "Generado", },
    { title: "Enviado", type: 'boolean' },
    { title: "Recibido", type:"boolean"},
    { title: "Entregado", name: "fechaEntrega" },
    { title: "FBarrida", name: "fechaBarrida" },
    { title: "Acreditacion", name: "fechaAcreditacion" },
    { title: "Recibos", name: "fechaRecibos" }
  ]


  // definicion de funciones  para enviar datos en los modales de dialogos 
  //GENERAR ARCHIVO PARA ENVIAR AL BANCO
  const { sendData: generarArchivoBanco } = useEnviarDatos({
    action: () => new BarridasApi().createFileBank(detallesData["id"]),
    textSucces: 'Archivo Generado',
    recargarTabla,
    funcSuccess: () => setModalGenerarArchivo(!modalGenerarArchivo)
  })


  //SUBIR ARCHIVO QUE SE RESIBIO DEL BANCO 

  const { sendData: subirArchivoDelBanco } = useEnviarDatos({
    action: (archivoBanco) => new BarridasApi().subirArchivo(detallesData["id"], archivoBanco),
    textSucces: 'Archivo Cargado',
    recargarTabla,
    funcSuccess: () => setModalSubirArchivo(!modalSubirArchivo)
  })

  //accion para ver lo que se manda del formulario
  const enviarArchivo = (e, archivo) => {
    e.preventDefault()
    const archivoBanco = new FormData(e.currentTarget)
    archivoBanco.append('archivoBanco', archivo)
    subirArchivoDelBanco(archivoBanco)
  }
  //SUBIR ARCHIVO 

  const optionsMenuAction = [

    {
      title: "Ver Acreditaciones",
      icon: "view",
      action: () => setModalAcreditacionesBarrida(!modalAcreditacionesBarrida)
    },
    {
      title: "Generar Archivo Banco",
      action: (obj) => {
        setModalGenerarArchivo(!modalGenerarArchivo)
      },
      icon: "bank"
    },
    {
      title: "Subir Archivo",
      action: () => setModalSubirArchivo(!modalSubirArchivo),
      icon: "file",
    },
    {
      title: "Informe de rechazos",
      action: () => setModalInformeRchazos(!modalInformeRechazos),
      icon: "view"
    },
    {
      title: "Imprimir recibos",
      action: () => setModalImprimirRecibos(!modalImprimirRecibos),
      icon: "imprimir"
    },
    {
      title: "Pagaron mes Anterior y No Actual",
      action: () => setModalPagaronMesAnterior(!modalPagaronMesAnterior),
      icon: "view"
    },
    {
      title: "Pagaron mes Actual y No Anterior",
      action: () => setModalPagaronMesActual(!modalPagaronMesActual),
      icon: "view"
    }
  ]

  const filtrosSearchBar = [
    {
      key: "DESCRIPCION",
      label: "Descripcion"
    },
    {
      key: 'FID',
      label: 'ID'
    }
  ]

  const buttons = [
    {
      icon: 'nuevo',
      action: () => dispatch(openModalRegister()),
      label: 'Nueva Barrida'

    }
  ]

  const actions = [
    {
      icon: "editar",
      action: (data) => {
        dispatch(selectDataAction(data))
        dispatch(openModalEdit())
      },
      tooltip:"Editar Barrida"
    }
  ]

  return (<LayoutTest
    searchBar={
      <SearchBar
        filtros={filtrosSearchBar}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
      />
    }
    buttons={buttons}
  >

    {/* MODALES */}

    <ModalDialog
      title="Generar Archivo Barrida"
      active={modalGenerarArchivo}
      accionAcpetar={() => generarArchivoBanco()}
      labelButtonSubmit="Si, Generar Archivo"
      closeModal={() => setModalGenerarArchivo(!modalGenerarArchivo)}
    >
      <Typography variant="h6">{`¿Esta Seguro que desea Generar el arvhivo para ${detallesData['descripcion']}?`}</Typography>
    </ModalDialog>


    <ModalDialog
      width={'45vw'}
      labelButton="Subir Archivo"
      closeModal={() => setModalSubirArchivo(!modalSubirArchivo)}
      active={modalSubirArchivo}
      hiddenButton={true}
      title="Subir Archivo del Banco"
    >
      <FormSubirArchivoBanco
        cancelAction={() => setModalSubirArchivo(!modalSubirArchivo)}
        onSubmit={enviarArchivo} />
    </ModalDialog>

    {/* MODAL IMPRIMIR RECIBOS DE BARRIDA */}
    <ModalDialog
      closeModal={() => setModalImprimirRecibos(!modalImprimirRecibos)}
      active={modalImprimirRecibos}
      hiddenButton={true}
      title="Imprimir Recibos"
    >
      <FormImprimirRecibosBarrida
        openSuccesModal={() => {
          setModalPdfRecibosBarrida(!modalPdfRecibosBarrida);
          setModalImprimirRecibos(!modalImprimirRecibos) /* se cierra el modal anterior y se abre el pdf una vez esten cargados los datos */
        }
        }
        datosBarrida={detallesData}
        cerrarModal={() => setModalImprimirRecibos(!modalImprimirRecibos)}
      />
    </ModalDialog>

    {/* MODAL PDF */}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setModalPdfRecibosBarrida(!modalPdfRecibosBarrida)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={modalPdfRecibosBarrida}
    >
      {console.log("PDF DATA ", pdfData)}
      <PdfRecibosBarrida
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>


    <Modal
      width='50vw'
      closeModal={() => setModalPagaronMesActual(!modalPagaronMesActual)}
      active={modalPagaronMesActual}
    >
      <PagaronMesActualyNoAnterior
        idBarrida={detallesData["id"]} />
    </Modal>



    <Modal
      width='50vw'
      closeModal={() => setModalPagaronMesAnterior(!modalPagaronMesAnterior)}
      active={modalPagaronMesAnterior}
    >
      <PagaronMesAnterioryNoActual
        idBarrida={detallesData["id"]} />
    </Modal>



    <Modal
      width='50vw'
      closeModal={() => setModalInformeRchazos(!modalInformeRechazos)}
      active={modalInformeRechazos}
    >
      <InformeRechazosBarrida
        idBarrida={detallesData["id"]}
      />
    </Modal>


    <Modal
      width={'50vw'}
      closeModal={() => dispatch(openModalRegister())}
      active={modalRegister}
    >
      <FormBarrida
        accionCancelar={() => dispatch(openModalRegister())}
        recargarTabla={recargarTabla}
      />
    </Modal>

    <Modal
      width={'50vw'}
      closeModal={() => setModalAcreditacionesBarrida(!modalAcreditacionesBarrida)}
      active={modalAcreditacionesBarrida}
    >
      <AcreditacionesBarrida
        idBarrida={detallesData["id"]}
      />
    </Modal>

    <Table
      filterTable={filterTable.length}
      RowMenu={(data) => <ActionMenu onClick={() => dispatch(selectDataAction(data))} options={optionsMenuAction} />}
      columns={columns}
      actions={actions}
      rowChek={false}
      tableData={filterTable.length ? filterTable : dataTable}
    />
  </LayoutTest>)
}

export default Barridas