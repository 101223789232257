
import { useState, useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Table from '@material-ui/core/Table';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Icons from '../Icons';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Typography } from '@material-ui/core';

//actions
import { openModal } from '../../Redux/actions/modales/ActionCreatorModals';
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

//custom hooks
import { usePagination } from '../../hooks/usePagination';

//component
import RowHeader from './RowHeader';
import TablePaginationActions from './TablePaginationActions'
import { Cell, CellAction, CellCheckBox } from '../Cell'
import CollapseTable from './CollapseTable';

// estilos css-in-js

const CustomTable = (props) => {
    const {
        ActionsCollapseTable = [],
        ActionsMenuCollapseTable = [],
        columnsCollapseTable = [],
        paramSetDataCollapsableTable,
        asyncDataCollapsableTable = () => { },
        tableData,
        columns,
        collapseTable = null,
        actions,
        RowMenu = null,
        pagination = true,
        modalTable = false,
        links = [],
        filterTable = false,
        rowChek = {},
        selectRow = () => { },
        setSelectPage = () => { }
    } = props

    const useStyles = makeStyles(() =>
        createStyles({
            table: {
                minWidth: 480,
                width: "100% !important",
                overflowX: 'scroll',
                height: '100%'
            },
            paginationTable: {
                color: '#3e3e3c',
                height: 35,
            },
            footer: {
                height: 35
            },
            root: {
                '& > *': {
                    borderBottom: 'unset',
                    borderBottom: '1px solid rgb(241 241 241)'
                },
            },
            cellCheck: {
                cursor: 'pointer'
            },

            collapseTable: {
                overflow: 'hidden',
                background: '#FFF'
            },

            borderOpen: {
                borderLeft: 'solid 5px #f9bc06',
                background: '#fff0b9'
            },

            borderClose: {
                borderLeft: 'solid 5px transparent ',
            },
            tableBody: {
                width: '90%',
                overflowY: 'scroll !important',
                height: modalTable ? '60vh !important' : '100%'
            },
            tableCellLinks: {
                transition: 'all cubic-bezier(0.215, 0.610, 0.355, 1) .4s',
                cursor: 'pointer',
                color: '#3e3e3c',
                '&:hover': {
                    background: '#fff0b9',
                }
            },

            button: {
                display: 'inline-block',
                margin:2,
                padding:5,
                minHeight: 0,
                minWidth: 0,
              }
        }));
    const dispatch = useDispatch()
    const classes = useStyles();

    //estados de modales 
    const [open, setOpen] = useState({ open: '' });

    const setOpenRow = (value) => {
        setOpen({
            ...open,
            [value]: !open[value]
        })
    }

    const theme = useTheme();
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

    // usePagination devuelve: 
    // page: devuelve la pagina actual,
    // rowperPage: devuelve las filas por pagina,
    // handleChangePage: fun. para cambiar pagina,
    const rowsPerPage = matchesXL ? 14 : matchesLG ? 12 : matchesMD ? 10 : matchesSM ? 8 : 7

    const { page, handleChangePage } = usePagination()

    useEffect(()=>{
        setSelectPage(page)
    },[page])

    // emptyRows se usa para rellenar el espacio faltante 
    // de la tabla para mantener el tamaño correspondiente 
    // a filas por pagina 
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (tableData.length ? tableData.length : links.length) - (filterTable ? 0 : page) * rowsPerPage);

    // funcion que corta el array dependiendo las filas por paginas
    // y selecciona la lista correspondiente

    const ActualPage = (listData) => {
        return (listData).slice((filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage,
            (filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
    }

    return (
        <>
            <Table className={classes.table} aria-label="tabla">
                <RowHeader columns={columns} >
                    {
                        columns.map((col, i) => <Cell variant="head" key={i}>{col.title}</Cell>)
                    }
                </RowHeader>
                <TableBody className={classes.tableBody}>
                    {tableData.length
                        ? (rowsPerPage > 0
                            ? ActualPage(tableData)
                            : tableData
                        ).map((data, i) => {
                            return <>
                                <TableRow className={`${classes.root} ${open[data.id] ? classes.borderOpen : classes.borderClose}`}>
                                    {collapseTable && <Cell width={'25'}>
                                        <IconButton aria-label="expand row" size="small" onClick={
                                            () => {
                                                setOpenRow(data.id);
                                                dispatch(selectDataAction(data))
                                            }}>
                                            {open[data.id] ? <KeyboardArrowUpIcon /> : <Tooltip title="Ver Cuentas vinculadas"><KeyboardArrowDownIcon /></Tooltip>}
                                        </IconButton>
                                    </Cell>}

                                    {columns.map((key, j) => {
                                        return key.title === " " ? null :

                                            (key.type === "check"
                                                ? <CellCheckBox
                                                    data={data}
                                                    onClick={selectRow}
                                                >
                                                    <div className={classes.cellCheck}>

                                                        {
                                                            rowChek[data.id]
                                                                ? <CheckBoxIcon />
                                                                : <CheckBoxOutlineBlankIcon />
                                                        }
                                                    </div>
                                                </CellCheckBox>
                                                : <Cell
                                                    size={'medium'}
                                                    allRowData={data}
                                                    columna={key.title}
                                                    openModal={() => key.openModal && dispatch(openModal())}
                                                    boolean={key.boolean}
                                                    isArray={key.isArray}
                                                    width={key.width}
                                                    action={key.action}>
                                                    {
                                                        key.type === "boolean"
                                                            ? <Icons type={key.name
                                                                ? !!data[key.name] || !!data[key.name.toLowerCase()]
                                                                : !!data[key.title.toLowerCase()]} />
                                                            : key.name
                                                                ? data[key.name] || data[key.name.toLowerCase()]
                                                                : data[key.title.toLowerCase()]
                                                    }
                                                </Cell>)
                                    })
                                    }
                                    <CellAction align='right'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                            {RowMenu && RowMenu(data)}
                                            {actions.map((accion, index) =>
                                                <Tooltip title={accion.tooltip ? accion.tooltip : ''} followCursor>
                                                    <IconButton
                                                        className={classes.button}
                                                        key={index}
                                                        onClick={accion.action && (() => accion.action(data))}
                                                    >
                                                        <Icons type={accion.icon} />
                                                    </IconButton>
                                                </Tooltip>

                                            )
                                            }
                                        </div>
                                    </CellAction>
                                </TableRow>
                                {/* Tabla colapsable  */}
                                {collapseTable ? <TableRow className={`${open[i] ? classes.borderOpen : classes.borderClose}`}>
                                    <TableCell style={{ padding: 0, borderTop: "none" }} colSpan={12}>
                                        <Collapse in={open[data.id]} timeout="auto" unmountOnExit>
                                            <CollapseTable
                                                actions={ActionsCollapseTable}
                                                menuAction={ActionsMenuCollapseTable}
                                                columns={columnsCollapseTable}
                                                asyncGetData={asyncDataCollapsableTable(data[paramSetDataCollapsableTable])}
                                                dataRow={data}
                                            />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                                    : null}
                            </>
                        })
                        : null
                    }
                    {!tableData.length && !links.length
                        ? <TableCell style={{ height: 57, textAlign: 'center' }} colSpan={15} >
                            <LinearProgress color="warning" />
                        </TableCell>
                        : links.length
                            ? links.map(link => {
                                return <TableRow>
                                    <TableCell
                                        className={classes.tableCellLinks}
                                        style={{ height: 50 }}
                                        colSpan={12}
                                        onClick={() => link.action()} >
                                        <Typography variant="h6" style={{ fontWeight: '300' }}>
                                            {link.label}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            })
                            : null
                    }
                    {(emptyRows > 0) ? (
                        <TableRow style={{ height: 57 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
                            <TableCell colSpan={15} />
                        </TableRow>
                    ) : null}
                </TableBody>
                <TableFooter className={classes.footer} >
                    {
                        pagination
                            ? <TableRow>
                                <TablePagination
                                    className={classes.paginationTable}
                                    align="right"
                                    count={tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={filterTable && filterTable < rowsPerPage ? 0 : page}
                                    rowsPerPageOptions={[]}
                                    onChangePage={handleChangePage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                            : null
                    }
                </TableFooter>
            </Table>
        </>
    );
}

export default CustomTable