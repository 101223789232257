import Controls from '../../componentes/Forms/Controls'
import { Grid, IconButton } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles(() =>
    createStyles({

        button: {
            color: "#6E6893",
            padding: 5,
            border: "none",
        },
        container: {
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
            borderRadius: '5px',
        }

    }))

const Filtro = ({ handleChangeFilter, params, recargarTabla }) => {
    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider libInstance={moment} locale='es' utils={MomentUtils}>
            <Grid container spacing={1} className={classes.container}>
                <Grid container spacing={2} item xs={11}>

                    <Grid item xs={6}>
                        <Controls.DateField
                            name="fechaDesde"
                            value={params.fechaDesde}
                            onChange={handleChangeFilter}
                            title="Desde"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controls.DateField
                            name="fechaHasta"
                            value={params.fechaHasta}
                            onChange={handleChangeFilter}
                            title="Hasta"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Grid item xs={12}>
                        <IconButton
                            onClick={recargarTabla}
                            className={classes.button}>
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default Filtro