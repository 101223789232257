import { Grid } from '@material-ui/core';
// Import Componentes
import Controls from '../../componentes/Forms/Controls'

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import FormContainer from '../../componentes/Forms/FormContainer';
import { TransferirCajaCtaCteApi } from '../../api/TransferirCajaCtaCteApi';
import { CtacteApi } from '../../api/CtacteApi';



const initialFValues = {
    fechabajada:'',
    fechalimite:''
}

const FormTransferirCajaCtaCte = ({recargarTabla , cerrarModal}) => {
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    const { formSubmit, loading } = useSubmit(
        new TransferirCajaCtaCteApi().transferirCajaCtaCte, 
        values,
        recargarTabla,
        'Caja Transferida', 
        cerrarModal)

    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Transferir Caja"
            loading={loading}
            buttonDisabled={loading}
            title={loading && 'Transfiriendo Caja...'}
            accionCancelar={cerrarModal}
            onSubmit={formSubmit}>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechabajada"
                    value={values.fechabajada}
                    onChange={handleChangeForm}
                    title="Fecha de bajada"
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechalimite"
                    value={values.fechalimite}
                    onChange={handleChangeForm}
                    title="Fecha Limite"
                />
            </Grid>
        </FormContainer>
    )
}
export default FormTransferirCajaCtaCte