import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import {useSelector} from 'react-redux'

//acciones 
import { getMovCajaByID } from '../../Redux/actions/PdfActions/PdfActions';

import FormContainer from '../../componentes/Forms/FormContainer';

const FormImprimirMovCaja = ({ openSuccesModal, cerrarModal, datosMov }) => {

    const {loading} = useSelector(state=>state.GlobalState)

    console.log("ESTOS SON LOS DATOS DEL MOVIMIENTO ", datosMov)

    const dispatch = useDispatch()
    return (
        <FormContainer
            width="100%"
            resetForm={() => { }}
            loading={loading}
            buttonDisabled={loading}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Imprimir Recibo"
            title={''}
            accionCancelar={cerrarModal}
            onSubmit={() => {
                dispatch(getMovCajaByID(datosMov, ()=>{}))
                openSuccesModal()
            }}>
            <Grid xs={12} container item>
                <Typography variant="h6">
                    Imprimir Recibo para: {datosMov.nombrePersona} <br/>"{datosMov.descripcion} " 
                </Typography>
            </Grid>
        </FormContainer>
    )
}
export default FormImprimirMovCaja