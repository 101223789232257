import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PDFTableHeaderAlumnosActivos from './PDFTableHeaderAlumnosActivos'
import PDFTableRowAlumnosActivos from './PDFTableRowAlumnosActivos'
import PDFTableFooterAlumnosActivos from './PDFTableFooterAlumnosActivos'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});


const PDFTableAlumnosActivos = ({ data }) => (
  <View style={styles.tableContainer}>
    <PDFTableHeaderAlumnosActivos />
    <PDFTableRowAlumnosActivos items={data} />
     {/* <PDFTableFooterAlumnosActivos items={data[data.length-1]} /> */}
  </View>
);

export default PDFTableAlumnosActivos