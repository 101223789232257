import { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';

import SearchBar from '../../componentes/SearchBar/SearchBar'

import Table from '../../componentes/CustomTable'

import IconButton from "@material-ui/core/IconButton";
import LayoutTest from '../../Layout/LayoutTest';

//hooks
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'


//api
import { InformesApi } from '../../api/InformesApi';


const width = '90vw'

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650,
            borderRadius: 5,
            height: 'max-content',
        },
        tableContainer: {
            padding: 10,
            maxHeight: '78vh',
            borderRadius: 5,
            overflowY: 'hidden',
            [theme.breakpoints.down("md")]: {
                maxHeight: '70vh'
            },
            display: 'flex',
            alignItems: 'start',
            overflowY: 'scroll',

        },
        container: {
            height: '100%',
            minHeight: 'max-content',
            width: width,
            [theme.breakpoints.down("md")]: {
                height: '92vh'
            },

        },
        rowHeader: {
            background: '#fff',
            width: "100%",
            height: 'max-content'
        },
        cellHeader: {
            color: '#3e3e3c',
            fontWeight: 600
        },
        containerHeader: {
            height: '15%',
            [theme.breakpoints.down("md")]: {
                maxHeight: '10%'
            },
            display: 'grid',
            placeItems: 'center',
            width: '100%'
        },

        header: {
            padding: '10px',
            height: '60%',
            width: '100%',
            borderRadius: 5,
        },
        paginationTable: {
            color: '#6E6893',
            height: 35,
        },
        footer: {
            height: 35
        },
    }));

const AlumnosBecadosActivos = () => {

    const classes = useStyles();

    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new InformesApi().alumnosDadosDeBaja())

    const {
        filterTable,
        searchText,
        setSearchText,
        setFilter } = useSearchTable((filter, searchText) => new InformesApi().alumnosDadosDeBajaConFiltros(filter, searchText))


    const columns = [
        { title: "ID-Persona", name: 'idPersona', width: '10%' },
        { title: "Nombre", name: 'nombre', width: '30%' },
        { title: "ID-Cuenta", name: 'idCuenta', width: '10%' },
        { title: "Cuenta", name: 'Cuenta', width: '30%' },
        { title: "Saldo", name: 'saldo', width: '20%' },
    ]

    const filtrosSearchBar = [
        {
            key: 'nombre',
            label: 'Nombre'
        },
        {
            key: 'descripcion',
            label: 'Cuenta'
        }
    ]

    const actions = [
        {
            icon: "null",
            action: () => { }
        },
    ]

    return (<LayoutTest
        header={false}
        appBar={false}
        searchBar={
            <SearchBar
                filtros={filtrosSearchBar}
                searchText={searchText}
                setSearchText={setSearchText}
                setFilter={setFilter}
            />
        }
        customHeader={
            <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Typography className={classes.tile} variant="h4">{`Alumnos dados de baja`}</Typography>
                </Grid>
            </>
        }
    >
        <Table
            actions={actions}
            tableData={filterTable.length ? filterTable : dataTable}
            filterTable={filterTable.length}
            columns={columns}
            modalTable={true}
        />
    </LayoutTest>
    );
}

export default AlumnosBecadosActivos
