import AxiosIntance from '../axios/axiosInstace';

export class BarridasApi {

    getAllBarridas() {
        return AxiosIntance.get('barridas')
    }

    addBarridas(newBarrida) {
        return AxiosIntance.post(`barridas/nuevaBarrida`, newBarrida)
    }

    getAllGruposBarridas() {
        return AxiosIntance.get(`barridas`)
    }

    getListaGruposBarrida() {
        return AxiosIntance.get(`barridas/gruposbarridas`)
    }

    createFileBank(idBarrida) {
        return AxiosIntance.get(`barridas/crearArchivoBanco/${idBarrida}`)
    }

    updateBarrida(newBarrida) {
        const {id} = newBarrida
        return AxiosIntance.put(`barridas/actualizarBarrida/${id}`, newBarrida)   
    }

    subirArchivo(idBarrida,archivoBanco) {
        return AxiosIntance.post(`barridas/procesarArchivo/${idBarrida}`,archivoBanco,{
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        })
    }

    getAcreditacionesBarrida(idBarrida) {
        return AxiosIntance.get(`barridas/acreditacionesBarrida/${idBarrida}`)
    }

    getListadoDeRechazos(idBarrida) {
        return AxiosIntance.get(`barridas/informeRechazos/${idBarrida}`)
    }


    pagaronMesActualyNoAnterior(idBarrida) {
        return AxiosIntance.get(`barridas/pagaronMesActualyNoAnterior/${idBarrida}`)
    }

    pagaronMesAnterioryNoActual(idBarrida) {
        return AxiosIntance.get(`barridas/pagaronMesAnterioryNoActual/${idBarrida}`)
    }
    
    getListadoBarridasWithFilter(filter,searchText){
        return AxiosIntance.get(`barridas/search/${filter}/${searchText}`)
    }


}