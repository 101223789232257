
import { green } from '@material-ui/core/colors';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';


const Icons = (props) => {
    const { type = "", color = 'action', size = 'small' } = props

    const objIcon = {
        null: <EditIcon style={{fontSize: "16px", padding:'0px', color: 'transparent' }} />,
        recibo: <ReceiptIcon style={{fontSize: "16px", padding:'0px' }} />,
        quitar: <HighlightOffIcon style={{fontSize: "16px", padding:'0px' }} />,
        imprimir: <PrintIcon style={{fontSize: "16px", padding:'0px' }} />,
        borrar: <DeleteIcon style={{fontSize: "16px", padding:'0px' }} />,
        nuevo: <PersonAddIcon style={{fontSize: "16px", padding:'0px' }} />,
        filter: <FilterListIcon style={{fontSize: "16px", padding:'0px' }} />,
        close: <CloseIcon style={{fontSize: "16px", padding:'0px' }} />,
        user: <AccountCircleIcon style={{fontSize: "16px", padding:'0px' }} />,
        logout: <PowerSettingsNewIcon style={{fontSize: "16px", padding:'0px' }} />,
        editar: <EditIcon style={{fontSize: "16px", padding:'0px' }} />,
        editarCustom: <EditIcon style={{fontSize: "16px", padding:'0px' }} />,
        agregar: <AddIcon style={{fontSize: "16px", padding:'0px' }} />,
        ctacte: <AccountBalanceWalletIcon style={{fontSize: "16px", padding:'0px' }} />,
        cerrar: <HighlightOffIcon style={{fontSize: "16px", padding:'0px' }} />,
        file: <AttachFileIcon style={{fontSize: "16px", padding:'0px' }} />,
        view: <VisibilityIcon style={{fontSize: "16px", padding:'0px' }} />,
        bank: <AccountBalanceIcon style={{fontSize: "16px", padding:'0px' }} />,
        true: <CheckIcon style={{  color: green[500] }}  />,
        false: <NotInterestedIcon style={{ color: '#f48fb1'}} />,
        dobleFlecha: <DoubleArrowIcon style={{fontSize: "16px", padding:'0px' }} />,
        payment: <PaymentIcon style={{fontSize: "16px", padding:'0px' }} />,
        anotaciones: <AssignmentIcon style={{fontSize: "16px", padding:'0px' }} />,
        link: <LinkIcon style={{fontSize: "16px", padding:'0px' }} />,
        buscar: <SearchIcon style={{fontSize: "16px", padding:'0px' }} />,
        enviar: <SendIcon style={{fontSize: "16px", padding:'0px' }} />,
        email: <MailIcon style={{fontSize: "16px", padding:'0px' }} />,
        remove: <PersonRemoveIcon style={{fontSize: "16px", padding:'0px' }}/>
    }

    return <> {objIcon[type]} </>
}


export default Icons