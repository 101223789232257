import { useState, useCallback } from "react"
import { useSnackbar } from 'notistack';

const useEnviarDatos = (config) => {

  const {
    action = () => { },
    dataSubmit = '',
    recargarTabla = () => { },
    textSucces = "Listo!",
    textError = "Ocurrio un problema",
    funcSuccess = () => { },
    funcError = () => { },
    snackBar = true,
  } = config

  //const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const sendData = (data = null) => {
    setLoading(true);
    (dataSubmit ? action(dataSubmit) : data ? action(data) : action())
      .then(resp => {
        recargarTabla()
        funcSuccess(resp.data)
        setData(resp.data)
        // si la opcion pdf es true se guarda la data de la respuesta en localstorage para 
        // recupera los datos en la nueva pestaña donde esta el pdf
        setLoading(false)
        snackBar && enqueueSnackbar(textSucces, { variant: 'success' })
      })

      .catch(err => {
        funcError()
        snackBar && enqueueSnackbar(err.response?.data?.error || textError, { variant: 'error' })
        setLoading(false)
        console.log("ERROR ENVIAR DATOS DEL FORMULARIO ", err.response)
      })

  }

  return {
    sendData,
    loading,
    data
  }
}
export default useEnviarDatos