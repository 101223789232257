import { Grid, Divider, Typography } from '@material-ui/core';
import Controls from '../../componentes/Forms/Controls'
import { useForm } from '../../hooks/useForm'
import FormContainer from '../../componentes/Forms/FormContainer';
import useSubmit from '../../hooks/useSubmit';
//acciones
import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator';
import { ArancelesApi } from '../../api/ArancelesApi'

const initialFValues = {
    id:'',
    idcuenta: '',
    cuota: '0',
    monto: '0',
    bonificacion: '0',
    recargo: '0',
    biblioteca: '0',
    mes: '',
    anio: '',
    descripcion: '',
    actual: false,
    debitado: false,
    debita: false,
    abierta: false
}

const FormAranceles = ({ selectList, accionCancelar, recargarTabla }) => {
    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
        formEdit
    } = useForm(initialFValues);

    const { formSubmit } = useSubmit(
        formEdit ? new ArancelesApi().actualizarArancel : new ArancelesApi().addAranceles,
        values,
        recargarTabla,
        formEdit ? 'Datos Actualizados' : 'Arancel Creado'
    )

    return (
        <FormContainer
            accionCancelar={accionCancelar}
            LabelButtonSubmit={formEdit ? "Actualizar Arancel" : "Crear Nuevo Arancel"}
            title="Formulario de Aranceles"
            width="45vw"
            onSubmit={formSubmit}
            resetForm={resetForm}
        >
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Controls.AutocompleteSelect
                        name="idcuenta"
                        label="Cuenta"
                        valorSalida="id"
                        promSelectList={getCuentasListSelect()}
                        valueautocomplete={values.idcuenta}
                        filtro={"descripcion"}
                        onChange={handleChangeForm}
                    />
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    label="Cuota"
                    name="cuota"
                    value={values.cuota}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid container item alignItems="center" xs={12}>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Monto"
                        name="monto"
                        value={values.monto}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12}>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Bonificacion"
                        name="bonificacion"
                        value={values.bonificacion}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12}>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Recargo"
                        name="recargo"
                        value={values.recargo}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12}>
                <Grid item xs={12}>
                    <Controls.Input
                        label="Biblioteca"
                        name="biblioteca"
                        value={values.biblioteca}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Controls.Input
                    label="Descripcion"
                    name="descripcion"
                    value={values.descripcion}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid container item xs={6}>
                <Controls.Input
                    label="Mes"
                    name="mes"
                    value={values.mes}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid container item xs={6}>
                <Controls.Input
                    label="Año"
                    name="anio"
                    value={values.anio}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Controls.Checkbox
                        name="actual"
                        label="Actual"
                        value={values.actual}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Checkbox
                        name="debitado"
                        label="Debitado"
                        value={values.debitado}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Checkbox
                        name="debita"
                        label="Debita"
                        value={values.debita}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    )
}
export default FormAranceles