import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Divider } from '@material-ui/core'
import Controls from '../../componentes/Forms/Controls'
import FormContainer from '../../componentes/Forms/FormContainer';

import ModalDialog from '../../componentes/Modals/ModalDialog'

import { useSnackbar } from 'notistack';


import CuentasVinculadas from './CuentasVinculadas';

//hooks
import { useForm } from '../../hooks/useForm'
import useEnviarDatos from '../../hooks/useEnviarDatos'
import useCargarTabla from '../../hooks/useCargarTabla'

//api
import { PersonaApi } from '../../api/PersonaApi';
import { AlumnosSaoApi } from '../../api/AlumnosSao';

import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator'

//accion
import { nuevaPersonaBySao } from '../../Redux/actions/personas/ActionCreatorPersona';
import { buscarPersonaByDniAlumnoSao, buscarCuentasPorDniSao } from '../../Redux/actions/AlumnosSao/AlumnosSaoActionCreator';

const initialFValues = {
    idPersona: '',
    apellido: '',
    carreras: '',
    celular: '',
    domicilio: '',
    email: '',
    email1: '',
    fechanac: '',
    idalumno: '',
    idsexo: '',
    nombre: '',
    idsede:'',
    idplanEstudio:'',
    nrodoc: '',
    tipodoc: '',
    idcuenta: '',
    cbu: '0'
}
const FormAlumnoSao = () => {

    const { enqueueSnackbar } = useSnackbar();


    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    const { detallesData } = useSelector((state) => state.GlobalState)
    const [personaExiste, setPersonaExiste] = useState()
    const [cuentasAlumnoSao, setCuentasAlumnoSao] = useState([])
    const [hiddenButton, setHiddenButoon] = useState(false)
    const [loading, setLoading] = useState(true)

    const [modalActDatosPersona, setModalActDatosPersona] = useState(false)


    // consulto si la persona seleccionada ya tiene una persona creada
    // si no tiene una persona creada, se debera crear una persona para vincular con una cuenta  

    //comprobar cuentas vinculadas 

    const { dataTable, recargarTabla } = useCargarTabla(() => new AlumnosSaoApi().buscarCuentasPorDniSao(detallesData))

    console.log("DETALLES DATA DNI SAO", detallesData)
    const cargarDatos = () => {
        setLoading(true)
        buscarPersonaByDniAlumnoSao(detallesData)
            .then(resp => {
                console.log("DETALLES DATOS ",detallesData)
                setPersonaExiste(!!resp.length)
                setLoading(false)
                resp.length && setValues({
                    ...values,
                    ...detallesData,
                    id: resp[0].id
                })
                if (!resp.length) setHiddenButoon(true)
            })
            .catch(err => console.log("ERROR", err))
            .finally();

        buscarCuentasPorDniSao(detallesData)
            .then(resp => setCuentasAlumnoSao(resp))
            .catch(err => {
                console.log("ERROR AL CARGAR CUENTAS POR DNI DE ALUMNO ", err)
            })
    }
//vincular cuenta
    const { sendData } = useEnviarDatos({
        action: new PersonaApi().nuevaVinculacionCuentaPersona,
        recargarTabla,
        dataSubmit: values,
        textSucces: 'Cuenta Vinculada',
    })

    //actualizar datos de la persona
    const { sendData :actualizarPersonaBySAO, loading:loadingActualizarPersonaBySAO } = useEnviarDatos({
        action: new PersonaApi().actualizarPersonaBySAO,
        textSucces: 'Datos Actualizados',
        dataSubmit:values,
        funcSuccess: () => setModalActDatosPersona(!modalActDatosPersona),
        funcError: () => {}
      })

    useEffect(() => {
        detallesData.nrodoc && cargarDatos()
    }, [])

    // funcion para crear una persona y mostrar botones para vincular una cuenta

    const nuevaPersona = () => {
        nuevaPersonaBySao(detallesData)
            .then(resp => {
                //si la persona es creada con exito, actualizo el estado que ocultan los botones y seteo el id persona recien creado en obj values
                if (resp) {
                    setPersonaExiste(true)
                    setHiddenButoon(false)
                    setValues({ ...values, id: resp.insertId })
                }
                enqueueSnackbar("Persona creada ", { variant: 'success' })

            })
            .catch(err => {
                console.log("ERROR AL CREAR UNA NUEVA PERSONA", err)
                enqueueSnackbar(err.response?.data?.error || "Error al crear persona", { variant: 'error' })
            })
    }


    console.log("valor de la cuenta, ", !!values.idcuenta);

    return (
        <>
            <ModalDialog
                title="Actualizar Datos"
                closeModal={() => setModalActDatosPersona(!modalActDatosPersona)}
                active={modalActDatosPersona}
                loading={loadingActualizarPersonaBySAO}
                labelButtonSubmit="Si, Actualizar Datos"
                accionAcpetar={actualizarPersonaBySAO}
            >
                <Typography variant="h6">
                    Esta seguro que desea actualizaran los datos de persona del sistema de aranceles con los datos actuales del SAO ?
                </Typography>
            </ModalDialog>
            {!loading
                ? <FormContainer
                    hidenButton={hiddenButton  || !!cuentasAlumnoSao.find(cuenta => cuenta.idCuenta == values.idcuenta) || !values.idcuenta}
                    onSubmit={sendData}
                    width="48vw"
                    LabelButtonSubmit="Vincular Cuenta"
                    resetForm={resetForm}
                    title=" Vinculacion de Alumno con una Cuenta">
                    <Grid item xs={6}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="Apellido"
                            name="apellido"
                            variant="outlined"
                            value={values.apellido}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="Nombre"
                            name="nombre"
                            variant="outlined"
                            value={values.nombre}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="DNI"
                            name="nrodoc"
                            variant="outlined"
                            value={values.nrodoc}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="Domicilio"
                            name="domicilio"
                            variant="outlined"
                            value={values.domicilio}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="Email"
                            name="email"
                            variant="outlined"
                            value={values.email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="Email institucional"
                            name="email1"
                            variant="outlined"
                            value={values.email1}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        {/* se agrega parche para diferenciar alumnos a distancia en PES */}
                        <Controls.Input
                            size="small"
                            onChange={handleChangeForm}
                            label="Carrera"
                            name="carreras"
                            variant="outlined"
                            value={(values.idsede === "17" || values.idsede === "18") ? '(EAD) ' + values.carreras  : values.carreras}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            onChange={handleChangeForm}
                            label="IdAlumno"
                            name="idalumno"
                            variant="outlined"
                            value={values.idalumno}
                        />
                    </Grid>
                    {
                        personaExiste
                            ? <Grid item xs={6}>
                                <Controls.Button
                                    text={"Actualizar datos de la persona"}
                                    onClick={() => setModalActDatosPersona(!modalActDatosPersona)}
                                    variant="primary"
                                />
                            </Grid>
                            : null
                    }

                    {(!loading && !personaExiste)
                        ? <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 300 }}>
                                        {`Para vincular una cuenta, tendra que crear una persona para ${values.nombre} ${values.apellido}`}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <Grid>
                                        <Controls.Button
                                            onClick={nuevaPersona}
                                            variant="primary"
                                            text="Crear Persona"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                        : <>
                            <Grid item xs={12}>
                                <Typography variant={'h5'} style={{ fontWeight: 300 }}>
                                    Seleccionar cuenta para vincular
                                </Typography >
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.AutocompleteSelect
                                    name="idcuenta"
                                    onChange={handleChangeForm}
                                    label="Cuenta"
                                    valorSalida="id"
                                    promSelectList={getCuentasListSelect()}
                                    valueautocomplete={values.idcuenta}
                                    filtro={"descripcion"}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                {
                                    !!dataTable.find(cuenta => cuenta.idCuenta == values.idcuenta)
                                        ? <Typography style={{ color: 'red' }} variant="subtitle"> La Cuenta ya se encuentra vinculada </Typography>
                                        : null
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <CuentasVinculadas cuentasVinculadas={dataTable} idCuenta={values.idcuenta} />
                            </Grid>
                        </>
                    }
                </FormContainer >
                : null
            }
        </>
    )
}
export default FormAlumnoSao