import AxiosIntance from '../axios/axiosInstace';

export class TransferirCajaCtaCteApi {
     BaseUrl = 'transferirCajaCtaCte'

     getCajas(){
        return AxiosIntance.get(`transferirCajaCtaCte/cajas`)
    }

    transferirCajaCtaCte(datosTransferirCaja) {
        return AxiosIntance.post(`transferirCajaCtaCte`,datosTransferirCaja)
    }
}