import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center',
        borderTopColor:borderColor,
        borderTopWidth:1,
    },
    text: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 9.5,
    },
    concepto: {
        width: '70%',
        textAlign:'center'
    },
    numero: {
        width: '10%',
        textAlign:'right'
    },
});

const PDFTableFooterCtaCte = ({ items }) => {
    return (
        <View style={styles.row}>
            <Text style={{ ...styles.concepto, ...styles.text }}>------------------TOTAL------------------</Text>
            <Text style={{ ...styles.numero, ...styles.text }}>{items.debe}</Text>
            <Text style={{ ...styles.numero, ...styles.text }}>{items.haber}</Text>
            <Text style={{ ...styles.numero, ...styles.text }}>{items.saldo}</Text>
        </View>
    )
};

export default PDFTableFooterCtaCte