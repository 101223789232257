import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell } from '../../Cell';
import Icons from '../../Icons';


//acciones
import { getListadoDeRechazos } from '../../../Redux/actions/barridas/BarridasActionCreator';

//hooks
import useCargarDatos from '../../../hooks/useCargarDatos';


const margin = 10

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 'max-content',
            borderRadius: 5,
            border: 'solid 1px lightGray',
            overflow: 'scroll',
            height: '90%',
            margin: margin
        },
        container: {
            height: '100vh',
            marginTop: '4vh',
            overflowY: 'scroll',
            minHeight: 'max-content',
            [theme.breakpoints.down("md")]: {
                height: '92 vh'
            }
        },
        containerHeader: {
            height: 'max-content',
            marginLeft: margin
        },
        footer: {
            marginLeft: margin
        }
    }));

const InformeRechazosBarrida = ({ idBarrida }) => {

    const { dataTable } = useCargarDatos(getListadoDeRechazos(idBarrida))

    const classes = useStyles();

    const columns = [
        { title: "Nombre", name: "nombre" },
        { title: "Motivo de Rechazo", name: "motivorechazo" },
        { title: "Monto" },
        { title: "Carrera", name: 'descripcion' }
    ]

    return <>
        <Grid container className={classes.container} >
            {dataTable && dataTable.length
                ? <>
                    <Grid container direction="column" className={classes.containerHeader}>
                        <Typography variant="h5">Informe de Rechazos Barrida N° {idBarrida}</Typography>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">
                                    {`Total: $${dataTable && dataTable.reduce((acc, curr) => acc + parseFloat(curr.monto), 0)}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">
                                    {`Cantidad: ${dataTable.length}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.table} >
                        <Grid item xs={12}>
                            <Table >
                                <TableHead >
                                    <TableRow>
                                        {
                                            columns.length && columns.map((col, i) =>

                                                <Cell
                                                    align={col.align}
                                                    variant="head"
                                                    width={col.width}
                                                    key={i}>
                                                    {col.title}
                                                </Cell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataTable && dataTable.length
                                            ? dataTable.map((data, index) =>
                                                <TableRow key={index}>
                                                    {
                                                        columns.map((key, index) => <Cell
                                                            width={key.width}
                                                            key={index}
                                                        >
                                                            {
                                                                key.type === "boolean"
                                                                    ? <Icons type={key.name
                                                                        ? !!data[key.name] || !!data[key.name.toLowerCase()]
                                                                        : !!data[key.title.toLowerCase()]} />
                                                                    : key.name
                                                                        ? data[key.name] || data[key.name.toLowerCase()]
                                                                        : data[key.title.toLowerCase()]
                                                            }
                                                        </Cell>)
                                                    }

                                                </TableRow>
                                            )
                                            : null
                                    }
                                </TableBody>
                            </Table>
                        </Grid>

                    </Grid>
                </>
                : <Typography style={{ height: "100%", width: "100%", display: "grid", justifyContent: "center" }} variant="h2">{`No se encontraron rechazos para la barrida  ${idBarrida}`}</Typography>}
        </Grid>
    </>
}

export default InformeRechazosBarrida