import { useEffect, useState } from "react";

const useCargarTabla = (promise) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [recargar, setRecargar] = useState(false)

    const cargarDatos = async () => {

        setLoading(true)
        promise()
            .then(resp => {
                setData(resp.data)
                setLoading(false)
            })
            .catch(err => console.log("ERROR SET DATA HOOK CARGAR DATOS", err))

    }
    useEffect(() => {
        if (!data.length) {
            cargarDatos()
        }
    }, [recargar])

    const recargarTabla = () => {
        setData([])
        setRecargar(!recargar)
        cargarDatos()
    }

    return {
        dataTable: data,
        loading,
        recargarTabla,

    }
}

export default useCargarTabla