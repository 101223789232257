import Controls from '../../componentes/Forms/Controls'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator'
import { getAllBeneficios } from '../../Redux/actions/beneficios.Action'




const useStyles = makeStyles(() =>
    createStyles({

        button: {
            color: "#3e3e3c",
            padding: 5,
            border: "none",
        },
        container: {
            width: '500px',
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
            borderRadius: '5px',
        }

    }))

const FiltroAlumnosActivos = ({ handleChangeFilter, recargarTabla, filtro }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1} className={classes.container}>
            <Grid container spacing={2} item xs={11}>
                <Grid xs={4} item>
                    <Controls.AutocompleteSelect
                        label="Beneficio"
                        name="idbeneficio"
                        valueautocomplete={filtro.idbeneficio}
                        filtro={"descripcion"}
                        promSelectList={getAllBeneficios()}
                        valorSalida="id"
                        onChange={handleChangeFilter}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Controls.AutocompleteSelect
                        name="idcuenta"
                        onChange={handleChangeFilter}
                        label="Cuenta"
                        valorSalida="id"
                        promSelectList={getCuentasListSelect()}
                        valueautocomplete={filtro.idcuenta}
                        filtro={"descripcion"}
                    />
                </Grid>
            </Grid>
            <Grid item xs={1} container alignItems="center">
                <Grid item xs={12}>
                    <IconButton
                        onClick={recargarTabla}
                        className={classes.button}>
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FiltroAlumnosActivos