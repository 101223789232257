import AxiosIntance from '../axios/axiosInstace';

export class AlumnosSaoApi {
    getNuevosAlumnosSao() {
        return AxiosIntance.get('sao_personas')
    }

    buscarPersonaByDniAlumnoSao(alumnosSao) {
        return AxiosIntance.post('sao_personas', alumnosSao)
    }

    getAlumnosSaoWithFilter(filter, searchText) {
        return AxiosIntance.get(`sao_personas/search/${filter}/${searchText}`)
    }

    buscarCuentasPorDniSao(dniAlumnosSao) {
        return AxiosIntance.post('sao_personas/cuentas',dniAlumnosSao)
    }

}