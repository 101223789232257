import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Modals/Modal'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Controls from '../../Forms/Controls';
import { TableCell } from '@material-ui/core';
import { Cell, CellAction } from '../../Cell';
import ActionMenu from '../../Menu/ActionMenu';
import ModalDialog from '../../Modals/ModalDialog'

//tablas 
import DetallesPLanPagos from './DetallesPLanPagos';

//acciones
import { selectDataAction } from '../../../Redux/actions/globalActions/ActionCreatorGlobal';
import { PlanesPagoApi } from '../../../api/PlanesPagoApi'

//hooks
import useEnviarDatos from '../../../hooks/useEnviarDatos';
import useCargarTabla from '../../../hooks/useCargarTabla'

//formulario
import FormPlanesPagos from '../../Formularios/FormPlanesPago'

const margin = 10

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 'max-content',
            borderRadius: 10,
            border: 'solid 1px lightGray',
            overflow: 'scroll',
            height: '78%',
            margin: margin
        },
        container: {
            height: '100vh',
            marginTop: '4vh',
            overflowY: 'scroll',
            minHeight: 'max-content',
            [theme.breakpoints.down("md")]: {
                height: '92 vh'
            }
        },
        containerHeader: {
            height: 'max-content',
            marginLeft: margin
        },
        footer: {
            marginLeft: margin
        }
    }));

const PlanesPagos = ({ idCuentaPersona, detallesPersona }) => {
    const dispatch = useDispatch()
    const { detallesData } = useSelector((state) => state.GlobalState)

    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new PlanesPagoApi().getPlanesPagosByIdCtaPersona(idCuentaPersona))

    const [modalNuevoPlan, setModalNuevoPlan] = useState(false)
    const [modalEliminarPlanPago, setModalEliminarPlanPago] = useState(false)
    const [modalDetallesPlan, setModalDetallesPlan] = useState(false)
    const [detallesPlan, setDetallesPlan] = useState({})

    console.log("datos planes de pago ", dataTable)

    const classes = useStyles();

    const columns = [
        { title: "Monto Total", name: "montototal" },
        { title: "Cantidad de Cuotas", name: "cantidadcuotas" },
        { title: "Tipo de Plan", name: "tipoplan" },
        { title: "Fecha de Inicio", name: "fechainicio" }
    ]

    // funcion para eliminar plan de pago
    const { sendData:eliminarPlan, loading:loadingEliminarPlan } = useEnviarDatos({
        action: ()=> new PlanesPagoApi().eliminarPlanPago(detallesData['id']),
        textSucces : 'Plan Eliminado',
        recargarTabla,
        funcSuccess:() => setModalEliminarPlanPago(!modalEliminarPlanPago)

      })

    const opcionesMenu = [
        {
            title: 'Ver Detalles Plan de Pago',
            action: (data) => {
                setModalDetallesPlan(!modalDetallesPlan)
                setDetallesPlan(data)
            },
            icon: "view",
        },
        {
            title: 'Editar Plan de pago',
            action: () => setModalDetallesPlan(!modalDetallesPlan),
            icon: "editar",
        },
        {
            title: 'Eliminar Plan de pago',
            action: () => setModalEliminarPlanPago(!modalEliminarPlanPago),
            icon: "borrar",
        }
    ]

    return <>
        {
            modalNuevoPlan && <Modal
                width="50vw "
                active={modalNuevoPlan}
                fondo="none"
                closeModal={() => setModalNuevoPlan(!modalNuevoPlan)}
            >
                <FormPlanesPagos
                    recargarTabla={recargarTabla}
                    idCtaPersona={detallesPersona.idcuentapersona} />
            </Modal>
        }

        {
            modalDetallesPlan && <Modal
                width="50vw"
                active={modalDetallesPlan}
                fondo="none"
                closeModal={() => setModalDetallesPlan(!modalDetallesPlan)}
            >
                <DetallesPLanPagos
                    detallesPlan={detallesPlan}
                />
            </Modal>
        }

        <ModalDialog
            width="50vw"
            labelButtonSubmit="Si, Eliminar Plan"
            accionAcpetar={eliminarPlan}
            title="Eliminar Plan de Pago"
            labelButtonCancelar="Cerrar"
            active={modalEliminarPlanPago}
            closeModal={() => setModalEliminarPlanPago(!modalEliminarPlanPago)}

        >
            <Typography
                variant="h6">
                {`¿Esta Seguro que quiere eliminar el plan de pago ?`}
            </Typography>

        </ModalDialog>


        <Grid container className={classes.container} >
            <Grid container direction="column" className={classes.containerHeader}>
                <Typography variant="h5">{detallesPersona.nombre}</Typography>
                <Typography variant="h6">Planes de pago:</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.table} >
                <Grid item xs={12}>
                    <Table >
                        <TableHead >
                            <TableRow >
                                {
                                    columns.length && columns.map((col, i) =>

                                        <Cell
                                            align={col.align}
                                            variant="head"
                                            width={col.width}
                                            key={i}>
                                            {col.title}
                                        </Cell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dataTable && dataTable.length
                                    ? dataTable.map((data, index) =>
                                        <TableRow key={index}>
                                            {
                                                columns.map((key, index) => <Cell
                                                    width={key.width}
                                                    key={index}
                                                >
                                                    {key.name
                                                        ? data[key.name] || data[key.name.toLowerCase()]
                                                        : data[key.title.toLowerCase()]}
                                                </Cell>)
                                            }

                                            <CellAction align='right'>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <ActionMenu
                                                        data={data}
                                                        onClick={() => dispatch(selectDataAction(data))}
                                                        options={opcionesMenu} />
                                                </div>
                                            </CellAction>

                                        </TableRow>
                                    )
                                    : <TableRow >
                                        <TableCell colSpan={6}>
                                            <Typography
                                                style={{ height: "100%", width: "%", display: "grid", justifyContent: "center" }}
                                                variant="h6">{`No se encontraron Planes de pago para ${detallesPersona.nombre}`}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>

                    </Table>
                </Grid>

            </Grid>
            <Grid container item alignItems="center" className={classes.footer}>
                <Controls.Button
                    variant="primary"
                    text="Nuevo Plan"
                    onClick={() => setModalNuevoPlan(!modalNuevoPlan)} />
            </Grid>
        </Grid>
    </>
}

export default PlanesPagos