import { Grid } from '@material-ui/core';

// Import Componentes
import Controls from '../../componentes/Forms/Controls'

//hooks 
import useEnviarDatos from '../../hooks/useEnviarDatos';
import FormContainer from '../../componentes/Forms/FormContainer';
import { CtacteApi } from '../../api/CtacteApi';
import { useForm } from '../../hooks/useForm'


const FormImprimirCtaCte = ({ recargarTabla,tablactacte, cerrarModal, abrirPdf, fechas = { desde: '', hasta: '' }, filtrarTabla=()=>{} }) => {

    const initialFValues = {
        fechaDesde: fechas.desde,
        fechaHasta: fechas.hasta
    }

    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
    } = useForm(initialFValues);

    return (
        <FormContainer
            width="100%"
            resetForm={resetForm}
            labelButtonCancelar="Cerrar"
            LabelButtonSubmit="Imprimir PDF CtaCte"
            title={''}
            accionCancelar={cerrarModal}
            onSubmit={()=>{
                filtrarTabla(tablactacte,values.fechaDesde, values.fechaHasta)
                abrirPdf()
                cerrarModal()
                }}>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechaDesde"
                    value={values.fechaDesde}
                    onChange={handleChangeForm}
                    title="Fecha Desde"
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fechaHasta"
                    value={values.fechaHasta}
                    onChange={handleChangeForm}
                    title="Fecha Hasta"
                />
            </Grid>
        </FormContainer>
    )
}
export default FormImprimirCtaCte