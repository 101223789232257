
import jsPDF from 'jspdf'

import faker from 'faker'

require('jspdf-autotable')

/* const BuilPDF = () => {

  let dataPdf = {
    ciudad: 'Concepción del Uruguay',
    fecha: '15/09/2021',
    nombre: 'AGUILAR , Antonela Magali',
    cuil: '0',
    carrera: 'CONTADOR PUBLICO',
    tipoPago: 'DEPOSITO BANCARIO',
    montoLetras: 'Veintiun mil seiscientos setenta y ocho con 00/100',
    concepto: 'PAGO DEB.SANTANDER RIO SEP/2021',
    descripcion: [
      { concepto: 'ARANCEL', monto: '21.678,00' },
      { concepto: 'BONIFIC', monto: '0,00' },
      { concepto: 'INTERESES', monto: '0,00' },
      { concepto: 'BIBLIOTEC', monto: '0,00' },
      { concepto: 'BECA', monto: '0,00' },
    ],
    total: '21.678,00'
  }

  var doc = new jsPDF('p', 'pt');

  // fomarto de texto 
  doc.setFontSize(10);
  doc.setFont("times")


  doc.text('Concepción del Uruguay', 410, 65)
  doc.text('15/09/2021', 410, 85)

  doc.text('AGUILAR , Antonela Magali', 80, 130)
  doc.text('0', 450, 130)

  doc.text('CONTADOR PUBLICO', 330, 140)

  doc.text('DEPOSITO BANCARIO', 120, 160)

  doc.text('Veintiun mil seiscientos setenta y ocho con 00/100', 125, 195)

  doc.text('PAGO DEB.SANTANDER RIO SEP/2021', 190, 210)

  doc.text('ARANCEL', 50, 240)
  doc.text('21.678,00', 180, 240)

  doc.text('BONIFIC', 50, 255)
  doc.text('0,00', 180, 255, { align: 'right' })

  doc.text('INTERESES', 50, 270)
  doc.text('0,00', 180, 270, { align: 'right' })

  doc.text('BIBLIOTEC', 50, 285)
  doc.text('0,00', 180, 285, { align: 'right' })

  doc.text('BECA', 50, 300)
  doc.text('0,00', 180, 300, { align: 'right' })

  doc.text('21.678,00', 120, 400)


  doc.save('demo.pdf')
} */

const BuilPDF = () => {
  let doc = new jsPDF('p', 'pt')

  let body = [
    //IMPORTANTE 12 columnas
    [{
      content: " ",
      rowSpan: 1,
      colSpan: 12,
      styles: { lineColor: [0, 0, 0], minCellHeight: 0.5 }
    }],
    [
      {
        content: `
        ASOCIACION EDUCACIONISTA LA FRATERNIDAD
        `,
        rowSpan: 2,
        colSpan: 12,
        styles: { valign: 'middle', halign: 'center', fontSize: 18, lineColor: [0, 0, 0] }
      },
      {
        content: "X",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center', fontSize: 18, }
      },
      {
        content: "Recibo",
        rowSpan: 1,
        colSpan: 5,
        styles: { valign: 'middle', halign: 'center', fontSize: 18, }
      },
    ],
    //========================================================================
    [
      {
        content: "Documento no valido como factura",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center' }
      },
      {
        content: `
          \nDocumento no valido como factura
          \nDocumento no valido como factura
          \nDocumento no valido como factura
        `,
        rowSpan: 5,
        colSpan: 5,
        styles: { valign: 'middle', halign: 'left' }
      }
    ],

    //========================================================================
    [],
    //========================================================================
    [
      {
        content: "Razon Social:",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center' }
      },
      {
        content: "ASOCIACION EDUCACIONISTA LA FRATERNIDAD",
        rowSpan: 1,
        colSpan: 4,
        styles: { valign: 'middle' }
      }],
    //========================================================================
    [
      {
        content: "Domicilio Comercial:",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center' }
      },
      {
        content: "8 de Junio 522 - Concepción del Uruguay, Entre Ríos",
        rowSpan: 1,
        colSpan: 4,

      }
    ],
    //========================================================================

    [
      {
        content: "Condicion frente al IVA:",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center' }
      },
      {
        content: "IVA Sujeto Exento",
        rowSpan: 1,
        colSpan: 4
      }
    ],
    //========================================================================
    [],
    //========================================================================
    [
      {
        content: "CUIT:",
        rowSpan: 1,
        colSpan: 3,
        styles: { valign: 'middle', halign: 'center' }
      },
      {
        content: "20058773310", //setear CUIT con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      },
      {
        content: "Apellido y Nombre / Razón Social:", //setear CUIT con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      },
      {
        content: "Gonzalez Vicente Salvador", //setear NOMBRE con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      }
    ],
    //========================================================================
    [
      {
        content: "Condicion de Venta:", //setear NOMBRE con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      },
      {
        content: "BCO SANTANDER RIO 5479/3", //setear CONDICION DE VENTA con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      },
      {
        content: "Domicilio:", //setear CONDICION DE VENTA con parametros de la funcion
        rowSpan: 1,
        colSpan: 3
      },
      {
        content: 'Caseros 4683', //setear DOMICILIO con parametros de la funcion
        colSpan: 3,
        rowSpan: 1,
      }
    ],
    //========================================================================
    [],
    //========================================================================
    [
      {
        content: 'Recibimos la suma de pesos:',
        colSpan: 3,
        rowSpan: 1,
      },
      {
        content: 'Ochocientos noventa mil quinientos setenta y tres con 99/100', //setear DOMICILIO con parametros de la funcion
        colSpan: 9,
        rowSpan: 1,
      },
    ],
    //========================================================================
    [],
    //========================================================================
    [
      {
        content: 'En concepto de:',
        colSpan: 3,
        rowSpan: 1,
      },
      {
        content: 'Cancelacion Fact 311 ND 36 y a cta',
        colSpan: 9,
        rowSpan: 1,
      },
    ],
    [],
    [],
    [],
    [],
    [],
    [],
    [" ", " ", " ", " ", " ", " ",
      {
        content: 'Cancelacion Fact 311 ND 36 y a cta',
        colSpan: 9,
        rowSpan: 1,
      },
      {
        content: 'Cancelacion Fact 311 ND 36 y a cta',
        colSpan: 9,
        rowSpan: 1,
      }
    ],
    [

    ]
    //========================================================================
  ]

  doc.autoTable({
    styles: {
      fontSize: 10,
      font: "helvetica", // helvetica, times, courier
      lineColor: [57, 85, 150], //lineColor: [255, 255, 255]
      fontStyle: 'normal', // normal, bold, italic, bolditalic
      fillColor: false, // false for transparent or a color as described below
      textColor: 20,
      halign: 'left', // left, center, right
      valign: 'middle', // top, middle, bottom
    }, // 'auto', 'wrap' or a number
    body: body,
    theme: 'grid',
  })

  console.log("BODY ", body)

  doc.save('demo.pdf')

  return doc
}

function headRows() {
  return [
    { id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' },
  ]
}

function footRows() {
  return [
    { id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' },
  ]
}


export default BuilPDF