import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell } from '../../Cell';
//acciones
import { getAcreditacionesBarrida } from '../../../Redux/actions/barridas/BarridasActionCreator';

//hooks
import useCargarDatos from '../../../hooks/useCargarDatos';


const margin = 10

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 'max-content',
            borderRadius: 10,
            border: 'solid 1px lightGray',
            overflow:'scroll',
            height: '90%',
            margin: margin
        },
        container: {
            height: '100vh',
            marginTop: '4vh',
            overflowY: 'scroll',
            minHeight: 'max-content',
            [theme.breakpoints.down("md")]: {
                height: '92 vh'
            }
        },
        containerHeader: {
            height: 'max-content',
            marginLeft: margin
        },
        footer: {
            marginLeft: margin
        }
    }));
const AcreditacionesBarrida = ({ idBarrida }) => {

    const { dataTable } = useCargarDatos(getAcreditacionesBarrida(idBarrida))

    console.log("Datos de las acreditaciones barrida ", dataTable)

    const { acreditaciones, detallesBarrida } = dataTable 

    const classes = useStyles();

    const columns = [
        { title: "CodAlu", name: "idalumno", width: '5%' },
        { title: "Nombre Alumno", name: "nombre", width: '30%' },
        { title: "Monto",  width: '5%'},
        { title: "Carreras", width: '30%' }
    ]

    return (
        <Grid container className={classes.container} >
            {acreditaciones && acreditaciones.length 
                ? <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Typography variant="h5">Acreditaciones de la barrida N° {detallesBarrida.idBarrida}</Typography>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                {`Total: $${acreditaciones && acreditaciones.reduce((acc,curr)=> acc + parseFloat(curr.monto),0)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                {`Cantidad: ${acreditaciones.length}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                {`Fecha: ${detallesBarrida.fecha.substring(0,10).split("-").reverse().join("/")}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                {`idBarrida ${detallesBarrida.idBarrida}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.table} >
                    <Grid item xs={12}>
                        <Table >
                            <TableHead >
                                <TableRow>
                                    {
                                        columns.length && columns.map((col, i) =>

                                            <Cell
                                                align={col.align}
                                                variant="head"
                                                width={col.width}
                                                key={i}>
                                                {col.title}
                                            </Cell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    acreditaciones && acreditaciones.length
                                        ? acreditaciones.map((data, index) =>
                                            <TableRow key={index}>
                                                {
                                                    columns.map((key, index) => <Cell
                                                        width={key.width}
                                                        key={index}
                                                    >
                                                        {key.name
                                                            ? data[key.name] || data[key.name.toLowerCase()]
                                                            : data[key.title.toLowerCase()]}
                                                    </Cell>)
                                                }

                                            </TableRow>
                                        )
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            </>
            : <Typography style={{height:"100%", width:"100%", display:"grid", justifyContent:"center"}} variant="h2">{`No se encontraron Acreditaciones para la Barrida ${idBarrida}`}</Typography>}
        </Grid>
    );
}

export default AcreditacionesBarrida