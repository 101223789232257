import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 9.5,
    },
    codigo: {
        width: '10%',
        textAlign: 'center',
        fontSize: 11,

    },
    descripcion:{
        width:'60%',
        textAlign:'center',
        fontSize:11
    },
    debeHaber: {
        width: '15%',
        textAlign: 'right'
    },
});




const PDFTableFooterIngresosFyC = ({ items }) => {

    const calcularTotales = (items=[]) => {
        return {
            totalHaber: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalDebe"], 0),
            totalDebe: items.reduce((acc, curr) => acc + curr[curr.length - 1]["subTotalHaber"], 0)
        }
    }

    const total = calcularTotales((items))
    return (
        <View style={styles.row}>
            <Text style={{...styles.codigo,...styles.text}}>{""}</Text>
            <Text style={{...styles.descripcion,...styles.text}}>------------------TOTAL------------------</Text>
            <Text style={{...styles.debeHaber,...styles.text}}>{(total.totalHaber).toFixed(2)}</Text>
            <Text style={{...styles.debeHaber,...styles.text}}>{(total.totalDebe).toFixed(2)}</Text>
        </View>
    )
};

export default PDFTableFooterIngresosFyC