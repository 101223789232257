import AxiosIntance from '../axios/axiosInstace';

export class PersonaApi {
     BaseUrl = 'persona'

    getAllPersonas(){
        return AxiosIntance.get('persona')
    }

    nuevaPersonaBySao(persona){
        return AxiosIntance.post('persona/nuevaPersonaBySao', persona)
    }

    nuevaPersona(persona){
        return AxiosIntance.post('persona', persona)
    }

    getCuentasByPersonaId(personaId) {
        return personaId ? AxiosIntance.get(`persona/cuentas/${personaId.toString()}`) : null
    }

    getAllGeneros(){
        return AxiosIntance.get(`persona/generos/all`)
    }

    getPersonasWithFilter(filter, searchText) {
        return AxiosIntance.get(`persona/search/${filter}/${searchText}`)
    }

    actualizarPersona(persona) {
        return AxiosIntance.put('persona/actualizarPersona',persona)
    }

    actualizarPersonaBySAO(newPersona) {
        return AxiosIntance.put('persona/actualizarPersonaBySAO',newPersona)
    }

    nuevaVinculacionCuentaPersona(ctaPersona) {
        return AxiosIntance.post('cuentaspersonas',ctaPersona)
    }

    actualizarLibreDeuda(datosCtaPersonas) {
        return AxiosIntance.put(`cuentaspersonas/updateCtaPersona`,datosCtaPersonas)
    }

    pasarAlumnosNoActivo(datosPersona) {
        return AxiosIntance.put(`cuentaspersonas/pasarAlumnosNoActivo`, datosPersona)
    }



 /*    deletePerson(personaList: IPersona[]): Promise<AxiosResponse> {
        return AxiosIntance.delete(this.BaseUrl, personaList)
    } */


}