import React, { Fragment, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import PDFContainer from './PDFContainer'

const PdfRecibosBarrida = ({pdfData,pdfDataConfig,height, width}) => {
   
    return (
        pdfData && pdfData.length
        ? <PDFViewer width={width} height={height} className="app" >
            <PDFContainer pdfData={pdfData} pdfDataConfig={pdfDataConfig} />
        </PDFViewer>
        : null
    );
}

export default PdfRecibosBarrida;