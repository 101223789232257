import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Controls from '../Forms/Controls';
import LinearProgress from '@mui/material/LinearProgress';


export default function ModalDialog({ 
  title, 
  active, 
  closeModal, 
  accionAcpetar=()=>{}, 
  children, 
  loading=false,
  labelButtonSubmit = "Aceptar",
  labelButtonCancelar="Cancelar",
  hiddenButton = false }) {
  return (
    <Dialog
      open={active}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography variant="h5" id="alert-dialog-title">{title}</Typography>
      </DialogTitle>
      <DialogContent
        style={{ width: "600px" }}
      >
        {loading && <LinearProgress/>}
        {children}
      </DialogContent>
      {!hiddenButton &&
        <DialogActions>
          <Controls.Button
            text={labelButtonCancelar}
            onClick={closeModal} 
            variant="secondary" />
          <Controls.Button
            text={labelButtonSubmit}
            onClick={accionAcpetar}
            variant="primary" />
        </DialogActions>}
    </Dialog>
  );
}
