import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        borderBottomColor:borderColor,
        borderBottomWidth:1
    },
    concepto: {
        width: '60%',
    },
    numeros:{
        width: '10%',
        textAlign: 'right'
    },
    fecha:{
        width: '10%',
    },

    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    }
});


const PDFTableRowSaldo = ({ items }) => {
    let rows = []
    items.forEach((fila, indx) => {
        if(indx == items.length-1) return
        rows.push(
            <View style={styles.row}>
                <Text style={{ ...styles.fecha, ...styles.text }}>{fila.fecmov}</Text>
                <Text style={{ ...styles.concepto, ...styles.text }}>{fila.concepto}</Text>
                <Text style={{ ...styles.numeros, ...styles.text }}>{fila.debe}</Text>
                <Text style={{ ...styles.numeros, ...styles.text }}>{fila.haber}</Text>
                <Text style={{ ...styles.numeros, ...styles.text }}>{fila.saldo}</Text>
            </View>)
    })

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRowSaldo