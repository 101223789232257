import { Grid } from '@material-ui/core';

//Import Componentes
import Controls from '../../componentes/Forms/Controls'

//Api
import { PersonaApi } from '../../api/PersonaApi';

//Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import FormContainer from '../../componentes/Forms/FormContainer';

//Actions
import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator';

const initialFValues = {
    nombre:'',
    idcuenta:'',
    id:'',
    codcar:'',
    idalumno:0,
    cbu:'',
    acreditabanco:false
}

const FormVincularCuenta = ({recargarTabla}) => {
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
        formEdit
    } = useForm(initialFValues);

    const { formSubmit } = useSubmit(
        new PersonaApi().nuevaVinculacionCuentaPersona,
        values,
        recargarTabla,
        'Nueva Vinculacion Agregada'
    )

    return (
        <FormContainer
            width="45vw"
            title={`Vincular una cuenta para ${values.nombre}`}
            resetForm={resetForm}
            LabelButtonSubmit={'Vincular Cuenta'}
            onSubmit={formSubmit}>
            <Grid item xs={12}>
                <Controls.AutocompleteSelect
                    name="idcuenta"
                    label="Cuenta"
                    valorSalida="id"
                    promSelectList={getCuentasListSelect()}
                    valueautocomplete={values.idcuenta}
                    filtro={"descripcion"}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="CodCar"
                    name="codcar"
                    value={values.codcar}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="CBU"
                    name="cbu"
                    required={values.acreditabanco}
                    value={values.cbu}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="idAlumno"
                    name="idalumno"
                    value={values.idalumno}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Checkbox
                    label="Acredita Banco"
                    name="acreditabanco"
                    value={values.acreditabanco}
                    onChange={handleChangeForm}
                />
            </Grid> 
        </FormContainer>
    )
}
export default FormVincularCuenta