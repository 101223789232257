import { TransferirCajaCtaCteApi } from '../../../api/TransferirCajaCtaCteApi'

export const ActionTypes = {
    GET_ALL_CAJAS:"GET_ALL_CAJAS"
}

export const getAllCajas = () => {
    return new TransferirCajaCtaCteApi()
        .getCajas()
        .then(resp=>resp.data)
        .catch(err=>console.log("EROR TRANSFERIR CAJA CTACTE", err))
}

export const transferirCajaCtaCte = (datosTransferirCaja) => {
    return new TransferirCajaCtaCteApi()
        .transferirCajaCtaCte(datosTransferirCaja)
        .then(resp=>console.log("RESPUESTA CAJA TRANSFERIDAD A CTA CTE", resp.data))
        .catch(err=>console.log("ERROR EN TRANSFERIR CAJA ", err))
}