import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        fontFamily: 'Times-Roman',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        textAlign: 'center',
        fontStyle: 'light',
        flexGrow: 1,
    },

    textHeader:{
        fontSize:10,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    carrera: {
        width: '50%',
    },
    cantidad: {
        width: '30%',
    },
    saldo:{
        width: '20%',
    },
  });

  const PDFTableHeaderSaldo = () => (
    <View style={styles.container} fixed>
        <Text style={{...styles.carrera, ...styles.textHeader}}>CARRERA</Text>
        <Text style={{...styles.cantidad, ...styles.textHeader}}>CANTIDAD</Text>
        <Text style={{...styles.saldo, ...styles.textHeader}}>SALDO</Text>
    </View>
  );
  
  export default PDFTableHeaderSaldo