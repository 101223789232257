import React from 'react'
import { useSelector } from 'react-redux';
import { Grid, Typography, InputAdornment } from '@material-ui/core';

// Import Componentes
import Controls from '../Forms/Controls'
import FormContainer from '../Forms/FormContainer';

// Custom hooks
import { useForm } from '../../hooks/useForm'
import { getTiposComprobantes, getAllModosPagos } from '../../Redux/actions/caja/CajasActionCreator';
import useSubmit from '../../hooks/useSubmit'

//Api
import { CajaApi } from '../../api/CajaApi';

const ReciboX = () => {

    const initialFValues = {
        fechaemision: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        cuit: '',
        inicioactividades: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        nombre: '',
        situaciontributaria: '',
        tipoDocumento: 80, //96 DNI  //80 CUIT  //86 CUIL
        condicionventa: 2,
        tipoComprobante: 4, //(15)RECIBO C,  (11)FACTURA C 
        domicilio: '',
        recibo: '',
        concepto: '',
        codigoAfip:'',
        total: '0.00'
    }

    /*  ESTADOS DESCRIPCIONES DESCRIPCIONES */
    const { dataCollapseTable } = useSelector(state => state.GlobalState)

    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useForm({ ...initialFValues, 
        idcuentapersona:dataCollapseTable.idcuentapersona, 
        nrocomprobante: dataCollapseTable.nroComprobanteX, 
        recibo:dataCollapseTable.recibo });


    const { formSubmit } = useSubmit(new CajaApi().addMovimientoCajaReciboX,{...values, total:parseFloat(values.total)})

    console.log("VALORES ",values)

    return (
        <FormContainer
            onSubmit={formSubmit}
            width="60vw"
            LabelButtonSubmit="Registrar Mov."
            resetForm={resetForm}
            title="Registrar Mov. Arrendatario">
            <Grid style={{ padding: '10px', height: '100% !important' }} container spacing={3}>
                {/*============================================== fila ============================================== */}
                <Grid item xs={12}>
                    <Controls.Input
                        label="Nombre"
                        name="nombre"
                        value={values.nombre}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="cuit"
                        label="CUIT"
                        value={values.cuit}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Controls.Input
                        label="Condicion Frente al IVA"
                        name="situaciontributaria"
                        value={values.situaciontributaria}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.DateField
                        title="Fecha de Emision"
                        name="fechaemision"
                        value={values.fechaemision}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.AutocompleteSelect
                        promSelectList={getTiposComprobantes([4])}
                        name="tipoComprobante"
                        label="Tipo de Comprobante"
                        filtro='descripcion'
                        valorSalida="id"
                        valueautocomplete={values.tipoComprobante}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.AutocompleteSelect
                        promSelectList={getAllModosPagos()}
                        label="Condicion de Venta"
                        filtro='descripcion'
                        valorSalida="id"
                        name="condicionventa"
                        valueautocomplete={values.condicionventa}
                        onChange={handleChangeForm}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Controls.Input
                        label="Domicilio"
                        name="domicilio"
                        value={values.domicilio}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controls.Input
                        label="Concepto"
                        multiline
                        name="concepto"
                        value={values.concepto}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>

            {/* =========================================================== TOTAL =========================================================== */}
            <Grid container item justifyContent="space-between" alignItems="center">
                <Grid item xs={4} >
                    <Typography variant="h5">Total</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'end' }} xs={6}>
                    <Grid item xs={12} >
                        <Controls.Input
                            label="Total"
                            name="total"
                            value={values.total}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                )
                            }}
                            onChange={handleChangeForm}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </FormContainer >
    )
}
export default ReciboX