import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        borderBottomColor: borderColor,
        borderBottomWidth: 1
    },
    nombre: {
        width: '76%',
    },
    libredeuda: {
        width: '14%',
        textAlign: 'center'
    },


    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    },
    id: {
        width: '10%',
        textAlign: 'center'
    },
    cuenta: {
        width: '100%',
        textAlign: 'center'
    }
});


const PDFTableRowAlumnosActivos = ({ items }) => {
    let rows = []
    let nombreCuenta = ''
    items.forEach((lista, indx) => {

        lista.forEach((item, index) => {
            if (!nombreCuenta) {
                nombreCuenta = item.cuenta
                rows.push(
                    <View style={styles.row}>
                        <Text style={{ ...styles.cuenta, ...styles.text }}>{item.cuenta}</Text>
                    </View>)
                rows.push(
                    <View style={styles.row}>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{item.nombre}</Text>
                        <Text style={{ ...styles.libredeuda, ...styles.text }}>{item.libredeuda}</Text>
                        <Text style={{ ...styles.id, ...styles.text }}>{index + 1}</Text>
                    </View>)
                return
            }
            if (nombreCuenta) {

                if (nombreCuenta == item.cuenta) {
                    rows.push(
                        <View style={styles.row}>
                            <Text style={{ ...styles.nombre, ...styles.text }}>{item.nombre}</Text>
                            <Text style={{ ...styles.libredeuda, ...styles.text }}>{item.libredeuda}</Text>
                            <Text style={{ ...styles.id, ...styles.text }}>{index + 1}</Text>
                        </View>)
                } else {
                    nombreCuenta = item.cuenta
                    rows.push(
                        <View style={styles.row}>
                            <Text style={{ ...styles.nombre, ...styles.text }}>{" "}</Text>
                            <Text style={{ ...styles.libredeuda, ...styles.text }}>{" "}</Text>
                            <Text style={{ ...styles.id, ...styles.text }}>{" "}</Text>
                        </View>)
                    rows.push(
                        <View style={styles.row}>
                            <Text style={{ ...styles.cuenta, ...styles.text }}>{item.cuenta}</Text>
                        </View>)
                    rows.push(
                        <View style={styles.row}>
                            <Text style={{ ...styles.nombre, ...styles.text }}>{item.nombre}</Text>
                            <Text style={{ ...styles.libredeuda, ...styles.text }}>{item.libredeuda}</Text>
                            <Text style={{ ...styles.id, ...styles.text }}>{index + 1}</Text>
                        </View>)
                }
            }
        })
    })

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRowAlumnosActivos