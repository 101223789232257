import { Grid } from '@material-ui/core';
// Import Componentes
import Controls from '../../componentes/Forms/Controls'

import { PersonaApi } from '../../api/PersonaApi';

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import FormContainer from '../../componentes/Forms/FormContainer';

//Actions
import { nuevaPersona, getAllGeneros } from '../../Redux/actions/personas/ActionCreatorPersona';

const initialFValues = {
    id: '',
    codigo: '',
    nombre: '',
    email: '',
    telefono: '',
    domicilio:'',
    idgenero:0,
    ndoc: '',
    cuit: '0',
    tdoc: '',
    situaciontributaria: '',
    fecnac: ''
}

const FormPersonas = ({recargarTabla, accionCancelar}) => {
    const {
        values,
        //setValues,
        handleChangeForm,
        resetForm,
        formEdit
    } = useForm(initialFValues);

    console.log("VALORES FORMULARIO PERSONAS ", values)

    const { formSubmit } = useSubmit(
        formEdit ? new PersonaApi().actualizarPersona : new PersonaApi().nuevaPersona,
        values,
        recargarTabla, 
        formEdit ? 'Datos Actualizados' : 'Persona Creada'
        )

    return (
        <FormContainer
            accionCancelar={accionCancelar}
            width="45vw"
            resetForm={resetForm}
            LabelButtonSubmit={formEdit ? "Actualizar Datos " : "Crear Nueva Persona"}
            title={formEdit ? "Actualizar datos de la persona" : "Nueva Persona"}
            onSubmit={formSubmit}>
            <Grid item container xs={12}>
                <Controls.Input
                    name="codigo"
                    label="Codigo"
                    value={values.codigo}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Nombre"
                    name="nombre"
                    value={values.nombre}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.DateField
                    name="fecnac"
                    value={values.fecnac}
                    required={false}
                    onChange={handleChangeForm}
                    title="Fecha de Nacimiento"
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Telefono"
                    name="telefono"
                    value={values.telefono}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Domicilio"
                    name="domicilio"
                    value={values.domicilio}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Grid item xs={12}>
                    <Controls.AutocompleteSelect
                        promSelectList={getAllGeneros()}
                        filtro={'descripcion'}
                        valueautocomplete={values.idgenero}
                        valorSalida="id"
                        name="idgenero"
                        label="Genero"
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Tipo Documento"
                    name="tdoc"
                    value={values.tdoc}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Nro Doc"
                    name="ndoc"
                    value={values.ndoc}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Cuit"
                    name="cuit"
                    value={values.cuit}
                    onChange={handleChangeForm}
                />
            </Grid>
            <Grid xs={12} container item>
                <Controls.Input
                    label="Situacion Tributaria"
                    name="situaciontributaria"
                    value={values.situaciontributaria}
                    onChange={handleChangeForm}
                />
            </Grid>
        </FormContainer>
    )
}
export default FormPersonas