import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        fontSize: 9.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },

    nombre: {
        width: '80%',
    },
    saldo: {
        width: '20%',
        textAlign:'right'
    },

    negrita: {
        borderColor: borderColor,
        borderWidth: 1,
    }
});


const PDFTableRowIngresosFyC = ({ items }) => {
    let rows = []

    items.forEach((item, index) => {

        item.forEach((fila, indx) => {

            if (indx == 0) {
                rows.push(
                    <View style={{ ...styles.row, ...styles.negrita }} >
                        <Text style={{ width: '100%', ...styles.text, textAlign:'center' }}>
                            {`${item[0].detalle} ${item[0].facultad}`}
                        </Text>
                    </View>,
                )
            }

            if (item.length - 1 == indx) {
                rows.push(
                    //agrego fila que contiene los subtotales de los grupos
                    <View style={{ ...styles.row, ...styles.negrita }} >
                        <Text style={{ ...styles.nombre, ...styles.text }}>{"Cantidad:  " + fila['Cantidad']}</Text>
                        <Text style={{ ...styles.saldo, ...styles.text }}>{"Sub Total:  " + (fila['saldo']).toFixed(2)}</Text>
                    </View>,
                    //agrego fila en balnco 
                    <View style={{ ...styles.row, borderBottomColor: borderColor, borderBottomWidth: 1 }}>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{" "}</Text>
                        <Text style={{ ...styles.saldo, ...styles.text }}>{" "}</Text>
                    </View>
                )
            } else {
                rows.push(
                    <View style={styles.row}>
                        <Text style={{ ...styles.nombre, ...styles.text }}>{fila.nombre}</Text>
                        <Text style={{ ...styles.saldo, ...styles.text }}>{fila.saldo}</Text>
                    </View>)
            }
        })

    }
    )

    return (<Fragment>{rows}</Fragment>)
};

export default PDFTableRowIngresosFyC