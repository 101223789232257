import AxiosIntance from '../axios/axiosInstace';

export class Anotaciones_cpApi {

    getAnotaciones(idCtaPersona) {
        return AxiosIntance.get(`anotaciones/${idCtaPersona}`)
    }

    updateNotaCtaPersona(nuevaNota) {
        return AxiosIntance.put(`anotaciones`,nuevaNota)
    }

    nuevaNotaCtaPersona(nuevaNota) {
        return AxiosIntance.post(`anotaciones`,nuevaNota)
    }

    borrarNota(idNota){
        return AxiosIntance.delete(`anotaciones/${idNota}`)
    }


}