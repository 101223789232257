import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import ButtonIcon from "../Buttons/ButtonIcon"
import { TableHead, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import ActionMenu from '../Menu/ActionMenu';
import Icons from '../Icons';
import { IconButton } from '@material-ui/core';
import { Row } from '../Row';
import { Cell, CellAction } from '../Cell'
import Tooltip from '@mui/material/Tooltip';

// acciones 
import { setDataCollapseTable } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

    collapseTable: {
        overflow: 'hidden',
        background: '#FFF'
    },

    borderOpen: {
        borderLeft: 'solid 5px #8cbaff ',
        background: '#f9bc06'
    },

    borderClose: {
        borderLeft: 'solid 5px transparent ',
    },

    button: {
        display: 'inline-block',
        margin: 2,
        padding: 5,
        minHeight: 0,
        minWidth: 0,
    }

});

const CollapseTable = (props) => {
    const {
        columns = [],
        menuAction = [],
        actions = [],
        dataRow = [],
        asyncGetData } = props

    const [dataTable, setDataTable] = useState([])
    const dispatch = useDispatch()

    const cargarDatos = () => {
        asyncGetData
            .then(resp => setDataTable(resp))
            .catch(err => console.log("ERROR CARGAR DATOS DE TABLA COLAPSABLE ", err))
    }
    useEffect(() => {
        cargarDatos()
    }, [])

    const classes = useRowStyles();

    /* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/
    return (<Table className={classes.collapseTable} size="small" aria-label="purchases">
        <TableHead>
            <TableRow>
                {columns.map((col, i) => <Cell key={i} variant={"head"}>{col.title}</Cell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {

                dataTable && dataTable.length
                    ? dataTable.map((data, i) => {
                        return (
                            <Row key={i}>
                                {
                                    columns.map((key, j) => <Cell width={key.width} key={j}>
                                        {
                                            key.type === "boolean"
                                                ? <Icons type={key.name
                                                    ? !!data[key.name] || !!data[key.name.toLowerCase()]
                                                    : !!data[key.title.toLowerCase()]} />
                                                : key.name
                                                    ? data[key.name] || data[key.name.toLowerCase()]
                                                    : data[key.title.toLowerCase()]
                                        } </Cell>)

                                }
                                <CellAction align='right' width='100px' >
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {menuAction.length ? <ActionMenu
                                            onClick={() => {
                                                dispatch(setDataCollapseTable(data))
                                            }}
                                            options={menuAction}
                                            data={data} /> : null}
                                        {actions.map((accion, index) =>
                                            <Tooltip title={accion.tooltip ? accion.tooltip : ''}>
                                                <IconButton
                                                    className={classes.button}
                                                    key={index}
                                                    onClick={() => accion.action(data)}>
                                                    <Icons type={accion.icon} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                </CellAction>
                            </Row>)
                    })
                    : null}
        </TableBody>
    </Table>)
}

export default CollapseTable