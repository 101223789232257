import AxiosIntance from '../axios/axiosInstace';
export class CajaApi {

    getAllMovimientos(fechas) {
        return AxiosIntance.post(`caja/movimientos`, fechas)
    }

    getModosPago() {
        return AxiosIntance.get(`recibos/modospago`)
    }

    addMovimientoCaja() {
        return AxiosIntance.post(`caja`)
    }

    setDataReciboGeneral(idCuentaPerosna) {
        return AxiosIntance.get(`recibos/setDataRecibo/${idCuentaPerosna}`)
    }

    grabarReciboEnCaja(datosRecibo) {
        return AxiosIntance.post(`recibos/grabarReciboEnCaja`, datosRecibo)
    }

    getConfigCajaByIdCaja(idCaja) {
        return AxiosIntance.get(`caja`)
    }

    getMovimientosCajaWithFilter(filter, searchText) {
        return AxiosIntance.get(`caja/movimientos/search/${filter}/${searchText}`)
    }

    getTiposComprobantes(idComprobantes) {
        return AxiosIntance.post(`recibos/tipoComprobantes`, idComprobantes)
    }

    getMovCajaSinFacturar() {
        return AxiosIntance.get(`/caja/mov_rechazados`)
    }

    addMovimientoCajaReciboX(datosRecibo) {
        return AxiosIntance.post('/recibos/grabarReciboX', datosRecibo)
    }

    enviarFacturaAfip(datosRecibo) {
        return AxiosIntance.post('recibos/enviarFacturaAfip', datosRecibo)
    }

    verDetallesDelMov(idMov) {
        return AxiosIntance.get(`caja/detallesMovimiento/${idMov}`)
    }

    getArqueoDeCaja(fecha) {
        return AxiosIntance.post('caja/arqueoDeCaja', fecha)
    }

    movimientosCajaByIdBarrida(idBarrida) {
        return AxiosIntance.get(`caja/movimientosBarrida/${idBarrida}`)
    }

    getMovCajaByID(idMov) {
        return AxiosIntance.get(`caja/movimiento/${idMov}`)
    }

    updateMovCaja(movCaja) {
        return AxiosIntance.put(`caja/actualizar`, movCaja)
    }

    anular_desanularRecibo(idMov) {
        return AxiosIntance.put(`caja/anular_desanularRecibo`, idMov)
    }

    enviarPdfRecibo(dataPdf) {
        return AxiosIntance.post('caja/enviarPdf', dataPdf, {
        })
    }

    getMovFacturaC(idMov){
        return AxiosIntance.get(`caja/getMovFacturaC/${idMov}`)
    }

    enviarNotasAfip(datosRecibo){
        return AxiosIntance.post('recibos/enviarNotasAfip',datosRecibo)
    }

}