import { makeStyles, createStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker} from '@material-ui/pickers';
import useIsMounted from '../../hooks/useIsMounted';

import moment from 'moment'

const useStyles = makeStyles((theme) =>
    createStyles({
        marginNone: {
            margin: 'none'
        }
    }));


const DateField = (props) => {
    useIsMounted()
    const { name, title, value =null, onChange, simple = false, onBlur, required=false, onKeyPress='' } = props

    return (
        <KeyboardDatePicker
            required={required}
            inputVariant="outlined"
            margin="dense"
            autoOk
            onBlur={onBlur}
            onKeyPress={!onKeyPress ? (e) => { e.key === 'Enter' && e.preventDefault(); }: onKeyPress}
            label={title}
            openTo="date"
            inputValue={value || null}
            name={name}
            disableToolbar={false}
            onChange={(date, value) => {
                onChange({ target: { value: date && date._isValid && moment(date).format('DD/MM/YYYY'), name } })
                console.log()
            }}
            value={value || null}
            format={'DD/MM/YYYY'}
            placeholder="10/10/2010"
            style={{ margin: 0 }}
        />
    )
}

export default DateField