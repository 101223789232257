import AxiosIntance from '../axios/axiosInstace';
export class CuentasApi {
     BaseUrl = 'cuentas'

    getAllCuentas() {
        return AxiosIntance.get('cuentas')
    }

    addCuentas(newCuenta) {
        return AxiosIntance.post('cuentas',newCuenta)
    }

    updateCuenta(newCuenta) {
        return AxiosIntance.put(`cuentas/updateCuenta/`,newCuenta)
    }

    getCuentasList()  {
        return AxiosIntance.get(`cuentas/list`)
    }

    getSedesList()  {
        return AxiosIntance.get(`cuentas/sedes`)
    }

    getActividadesExtension(){
        return AxiosIntance.get(`cuentas/actividadesExtencion`)
    }

    getTiposCuenta()  {
        return AxiosIntance.get(`cuentas/tiposcuenta`)
    }

    getCuentasWithFilter(filter='',searchText='') {
        return AxiosIntance.get(`cuentas/search/${filter}/${searchText}`)
    }
}