import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center',
        borderTopColor:borderColor,
        borderTopWidth:1,
    },
    text: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 9.5,
    },
    cantidad: {
        width: '80%',
    },
    saldo: {
        width: '20%',
    },
});




const PDFTableFooterSaldo = ({ items }) => {

    const calcularTotales = (items = []) => {
        return {
            saldoTotal: items.reduce((acc, curr) => acc + curr["saldo"], 0),
        }
    }

    const total = calcularTotales((items))
    return (
        <View style={styles.row}>
            <Text style={{ ...styles.cantidad, ...styles.text }}>------------------TOTAL------------------</Text>
            <Text style={{ ...styles.saldo, ...styles.text }}>{(total.saldoTotal).toFixed(2)}</Text>
        </View>
    )
};

export default PDFTableFooterSaldo