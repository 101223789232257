import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import PDFTableBlankSpace from './PDFTableBlankSpace';
import { formatearNumero } from './funciones';
const { NumerosALetras } = require('numero-a-letras')


const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 30
  },

  row: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    height: 14,
    fontStyle: 'bold',
    fontStyle: 'light',
    alignItems: 'center',
    lineHeight: 0.8
  },
  text: {
    fontSize: 10,
  },

  filaEnBlanco: {
    height: 7,
    width: '100%',
    fontSize: 9.5,
    /*     borderWidth: 1,
        borderColor: '#000', */
  },

  itemDesc: {
    height: 12,
    width: '25%',
    textAlign: 'left',
  },
  itemValor: {
    height: 12,
    textAlign: 'right',
    width: '12%'
  }

});

const PDFTable = ({ data, copia = false }) => {

  return (
    <View style={{ ...styles.tableContainer, marginTop: copia ? 60 : 30 }}>
      {/* FILAS DEL RECIBO  */}

      <View style={{ ...styles.row, display: 'flex', justifyContent: 'flex-end' }}>
        <Text style={{ ...styles.text, width: '22%' }}>Concepción del Uruguay</Text>
      </View>

      {/* ========================== fila en blanco ==================== */}
      <Text style={styles.filaEnBlanco}></Text>
      {/* ============================================================== */}

      <View style={{ ...styles.row, display: 'flex', justifyContent: 'flex-end' }}>
        <Text style={{ ...styles.text, width: '22%' }}>{data.fecha.slice(0, 10).split('-').reverse().join('/')}</Text>
      </View>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={3} />
      {/* ============================================================== */}

      <View style={styles.row}>
        <Text style={{ ...styles.text, width: '50%', textAlign: 'right' }}>{data.nombre}</Text>
        <Text style={{ ...styles.text, width: '48.5%', textAlign: 'right' }}>{data.codalu}</Text>
      </View>

      <Text style={{ ...styles.text, width: '75%', textAlign: 'right' }}>{data.carrera}</Text>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={1} />
      {/* ============================================================== */}

      <Text style={{ ...styles.text, width: '50%', textAlign: 'center' }}>{data.modopago}</Text>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={2} />
      {/* ============================================================== */}

      <Text style={{ ...styles.text, width: '75%', textAlign: 'center' }}>{NumerosALetras(data.monto).replace('Pesos', 'con').replace('M.N.', '').replace('Peso', 'con')}</Text>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={1} />
      {/* ============================================================== */}

      <Text style={{ ...styles.text, width: '100%', textAlign: 'center' }}>{data.descripcion}</Text>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={4} />
      {/* ============================================================== */}

      <View style={styles.row}>
        <Text style={{ ...styles.text, ...styles.itemDesc }}>ARANCEL.</Text>
        <Text style={{ ...styles.text, ...styles.itemValor }}>{formatearNumero(data["ARANCEL"])}</Text>
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.text, ...styles.itemDesc }}>BONIFIC.</Text>
        <Text style={{ ...styles.text, ...styles.itemValor }}>{formatearNumero(data["BONIFIC."])}</Text>
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.text, ...styles.itemDesc }}>INTERESES </Text>
        <Text style={{ ...styles.text, ...styles.itemValor }}>{formatearNumero(data["INTERESES"])}</Text>
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.text, ...styles.itemDesc }}>BIBLIOTEC </Text>
        <Text style={{ ...styles.text, ...styles.itemValor }}>{formatearNumero(data["BIBLIOTEC"])}</Text>
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.text, ...styles.itemDesc }}>BECA</Text>
        <Text style={{ ...styles.text, ...styles.itemValor }}>{formatearNumero(data["BECA"])}</Text>
      </View>

      {/* ========================== fila en blanco ==================== */}
      <PDFTableBlankSpace rowsCount={12} />
      {/* ============================================================== */}
      <Text style={{ ...styles.text, width: '50%', textAlign: 'center' }}>{formatearNumero(data.monto)}</Text>

    </View>

  )
};

export default PDFTable