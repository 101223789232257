import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { calcularTotales, agruparFilasPorId, agruparPorCodigo } from './funciones';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 16,
        fontStyle: 'bold',
        fontStyle: 'light',
        alignItems: 'center'
    },
    text: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 9.5,
    },
    relleno: {
        width: '61%',
        textAlign: 'center',
        fontSize: 11,

    },
    detalles: {
        width: '7%',
        textAlign: 'right'
    },
    otros: {
        width: '4%',
        textAlign: 'right'
    },
});




const PDFTableFooter = ({ items }) => {

    const total = calcularTotales(agruparPorCodigo(agruparFilasPorId(items)))
    return (
        <View style={styles.row}>
            <Text style={{...styles.relleno,...styles.text}}>------------------TOTAL------------------</Text>
            <Text style={{...styles.detalles,...styles.text}}>{(total.totalAranceles).toFixed(2)}</Text>
            <Text style={{...styles.detalles,...styles.text}}>{(total.totalBonificacion).toFixed(2)}</Text>
            <Text style={{...styles.detalles,...styles.text}}>{(total.totalIntereses).toFixed(2)}</Text>
            <Text style={{...styles.detalles,...styles.text}}>{(total.totalBiblioteca).toFixed(2)}</Text>
            <Text style={{...styles.otros,...styles.text}}>{(total.totalMoratoria).toFixed(2)}</Text>
            <Text style={{...styles.detalles,...styles.text}}>{(total.total).toFixed(2)}</Text>
        </View>
    )
};

export default PDFTableFooter