import React, { Fragment } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import PDFContainerSaldo from './PDFContainerSaldo';

const PDFSResumenSaldo = ({pdfData,pdfDataConfig,height, width}) => {
    return (
        <Fragment>
           <PDFViewer width={width} height={height} className="app" >
                <PDFContainerSaldo pdfData={pdfData} pdfDataConfig={pdfDataConfig} />
            </PDFViewer>
        </Fragment>
    );
}

export default PDFSResumenSaldo;